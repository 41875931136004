// import { StyleConstants } from './StyleConstants';
import { ReactModal } from './react-modal';

import { createGlobalStyle } from 'styled-components';

import { CkeditorStyle } from './ckeditor';
import { CodeAppStyle } from './code-app';
import { FullCalendarStyle } from './theme/full-calendar';

export const GlobalStyle = createGlobalStyle`


  /* width */
  ::-webkit-scrollbar {
    width: 6px;
  }

  /* Track */
  ::-webkit-scrollbar-track {
    border-radius: ${({ theme }) => theme.border.radius.sm};
    background: ${p => p.theme.scrollbar.scrollbarTrack};
  }

  /* Handle */
  ::-webkit-scrollbar-thumb {
    border-radius: ${({ theme }) => theme.border.radius.sm};
    background: ${p => p.theme.scrollbar.scrollbarThumb};

  }

  /* Handle on hover */
  ::-webkit-scrollbar-thumb:hover {
    background: ${p => p.theme.background.primary};
    border-radius: ${({ theme }) => theme.border.radius.sm};
  }

  *, *::before, *::after {
    box-sizing: border-box;
  }

  html,
  body {
    height: 100%;
    width: 100%;
    line-height: 1.5;
    margin: 0;
    font-size: 15px;
    color: ${p => p.theme.text.primary};
  }

  body {
    font-family: ${({ theme }) => theme.font.family};
    background-color: ${({ theme }) => theme.background.primary};
  }

  a {
    color: ${p => p.theme.text.primary};
  }

  #root {
    bottom: 0;
    display: flex;
    flex-direction: column;
    left: 0;
    overflow: hidden;
    position: absolute;
    right: 0;
    top: 0;
    height: 100%;
  }

  p,
  label {
    line-height: 1em;
  }

  input, select, button {
    font-family: inherit;
    font-size: inherit;
  }

  .icon {
    width: 1.5rem;
    height: 1.5rem;
  }


  .rc-dropdown {
    border-radius: ${({ theme }) => theme.border.radius.md};
    background-color: ${p => p.theme.background.secondary};
    border: 1px solid ${({ theme }) => theme.border.color.light};
    box-shadow: ${({ theme }) => theme.boxShadow.md};
  }

  .rc-tooltip-inner {
    background: #262626;
    border-radius: ${({ theme }) => theme.border.radius.sm};
    color: #fff;
    border: none;
    font-size: 12px;
    min-width: 70px;
    text-align: center;
    box-shadow: rgba(0, 0, 0, 0.3) 0 2px 10px;
  }

  .rc-tooltip-placement-top .rc-tooltip-arrow,
  .rc-tooltip-placement-topLeft .rc-tooltip-arrow,
  .rc-tooltip-placement-topRight .rc-tooltip-arrow {
    border-top-color: #262626;
    margin-left: -10px;
    border-width: 6px 10px 0;
  }

  .rc-tooltip-placement-bottom .rc-tooltip-arrow,
  .rc-tooltip-placement-bottomLeft .rc-tooltip-arrow,
  .rc-tooltip-placement-bottomRight .rc-tooltip-arrow {
    top: -4px;
    margin-left: -6px;
    border-width: 0 6px 6px;
    border-bottom-color: #262626;
  }

  .custom-tooltip {
    opacity: 1;
    background: transparent;
    .rc-tooltip-inner {
      padding: 0;
      border: none;
      background: transparent;
      text-align: left;
      box-shadow: none;
    }
  }

  ${ReactModal}
  ${CkeditorStyle}
  ${FullCalendarStyle}
  ${CodeAppStyle}

  div.table-story-points__control,
  div.table-priority__control {
    border-width: 0;
    border: none;
    background: transparent;
  }
  span.table-story-points__indicator-separator,
  span.table-priority__indicator-separator {
    display: none;
  }


  .ck-content iframe {
    z-index: 10;
    pointer-events: auto; /* Ensures it can be clicked */
  }


  .htItemWrapper {
    color: black;
  }



  ._RayContainer_1ti3k_1 {
    --gradient-opacity: .85;
    --ray-gradient: radial-gradient(rgba(83, 196, 255, var(--gradient-opacity)) 0%, rgba(43, 166, 255, 0) 100%);
    transition: opacity .25s linear;
    position: fixed;
    inset: 0;
    left: 240px;
    pointer-events: none;
    user-select: none
  }

  ._RayContainer_1ti3k_1 {
    animation: .25s linear 0s 1 _fade-in_1ti3k_1
  }

  ._RayContainer_1ti3k_1[data-theme=light] {
    --gradient-opacity: .5
  }

  ._RayContainer_1ti3k_1[data-theme=light][data-chat-started=true] {
    opacity: .5
  }

  ._RayContainer_1ti3k_1 ._LightRay_1ti3k_23 {
    position: absolute;
    border-radius: 100%;
    background: var(--ray-gradient)
  }

  ._RayContainer_1ti3k_1 ._RayOne_1ti3k_28 {
    width: 480px;
    height: 680px;
    transform: rotate(80deg);
    top: -540px;
    left: 250px;
    filter: blur(110px)
  }

  ._RayContainer_1ti3k_1 ._RayTwo_1ti3k_36 {
    width: 110px;
    height: 400px;
    transform: rotate(-20deg);
    top: -280px;
    left: 350px;
    mix-blend-mode: overlay;
    opacity: .6;
    filter: blur(60px)
  }

  ._RayContainer_1ti3k_1 ._RayThree_1ti3k_46 {
    width: 400px;
    height: 370px;
    top: -350px;
    left: 200px;
    mix-blend-mode: overlay;
    opacity: .6;
    filter: blur(21px)
  }

  ._RayContainer_1ti3k_1 ._RayFour_1ti3k_55 {
    position: absolute;
    width: 330px;
    height: 370px;
    top: -330px;
    left: 50px;
    mix-blend-mode: overlay;
    opacity: .5;
    filter: blur(21px)
  }

  ._RayContainer_1ti3k_1 ._RayFive_1ti3k_65 {
    position: absolute;
    width: 110px;
    height: 400px;
    transform: rotate(-40deg);
    top: -280px;
    left: -10px;
    mix-blend-mode: overlay;
    opacity: .8;
    filter: blur(60px)
  }

`;
