import { CKEditor } from '@ckeditor/ckeditor5-react';
import React, { useState, useCallback, useEffect } from 'react';
import { useTranslation } from 'react-i18next';

import { useQueryParams } from 'src/hooks/useQueryParams';
import { useTask } from 'src/hooks/useTask';

import { isEqual } from 'lodash';
import debounce from 'lodash/debounce';
import Editor from 'manage-ui-ckeditor5';

const TaskDescription = ({ action }) => {
  const query = useQueryParams();
  const taskId = query.get('taskId') as string;
  const { t } = useTranslation();
  const { _id, description } = action;

  const { onUpdateTask } = useTask();

  const [value, setValue] = useState(description);

  // update description when the taskId changes
  useEffect(() => {
    setValue(description);
  }, [taskId]);

  const saveDescription = useCallback(
    debounce((newDescription: string) => {
      const isSameValue = isEqual(description, newDescription);
      if (isSameValue) return;
      onUpdateTask({
        taskId: _id,
        description: newDescription,
      });
    }, 500),
    [_id, description],
  );

  // for preventing the iframe from being clicked
  useEffect(() => {
    const iframes = document.querySelectorAll('.ck-content iframe');
    iframes.forEach(iframe => {
      iframe.addEventListener('click', e => {
        e.stopPropagation();
      });
    });
  }, []);

  return (
    <CKEditor
      config={{
        placeholder: t('task.description.addDescriptionPlaceholder'),
        mediaEmbed: {
          previewsInData: true,
        },
      }}
      editor={Editor}
      data={value}
      onReady={() => {}}
      onChange={(event, editor) => {
        const data = editor.getData();
        const isSameValue = isEqual(data, description);
        if (isSameValue) return;
        saveDescription(data);
      }}
      onBlur={() => {}}
      onFocus={() => {}}
    />
  );
};

export default TaskDescription;
