import React from 'react';
import { useTasksQuery } from 'src/generated';
import TableView from './TableView';
import { NetworkStatus } from '@apollo/client';
import LoadingIndicator from '../Loaders/LoadingIndicator/LoadingIndicator';

const TableViewContainer = ({ query, columns }) => {
  const { data, loading, fetchMore, networkStatus } = useTasksQuery({
    variables: query,
    notifyOnNetworkStatusChange: true,
  });

  const isFetchingMore = networkStatus === NetworkStatus.fetchMore;
  const isSetVariables = networkStatus === NetworkStatus.setVariables;

  if (loading && !isFetchingMore && !isSetVariables) {
    return <LoadingIndicator />;
  }

  return (
    <TableView
      tasks={data?.tasks?.items || []}
      isLoading={loading}
      query={query}
      isFetchingMore={isFetchingMore}
      columns={columns}
      fetchMore={fetchMore}
      hasMore={Boolean(data?.tasks.hasMore)}
    />
  );
};

export default TableViewContainer;
