import React, { useState } from 'react';
import { Button, Modal, Flex, Icon } from 'src/components/design-system';
import * as Styled from './styles';

const FullScreen = ({ customHeader, children }) => {
  const [isFullScreen, setIsFullScreen] = useState(false);

  return (
    <div>
      <Flex $gap={10} margin={[0, 0, 20, 0]}>
        <Flex $flex="1">{customHeader && customHeader}</Flex>
        <div>
          <Button
            size="small"
            $htmlType="text"
            onClick={() => setIsFullScreen(true)}
            icon={<Icon name="full-screen" />}
          />
        </div>
      </Flex>
      {isFullScreen ? (
        <Modal
          isOpen
          noOpenAnimation
          showTopBorder={false}
          showCloseButton={false}
          customHeader={() => (
            <Styled.ModalHeader>
              <Button
                size="small"
                $htmlType="text"
                $padding="0"
                onClick={() => setIsFullScreen(false)}
                icon={<Icon name="close" size={24} color="#fff" />}
              />
            </Styled.ModalHeader>
          )}
          width="100%"
          onRequestClose={() => {
            setIsFullScreen(false);
          }}
          style={{
            overlay: {
              zIndex: 1000,
            },
            content: {
              padding: '0',
              border: 'none',
              borderRadius: 0,
              height: '100%',
              width: '100%',
            },
          }}
        >
          {children}
        </Modal>
      ) : (
        <div>{children}</div>
      )}
    </div>
  );
};

export default FullScreen;
