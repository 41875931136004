import React from 'react';
import { useParams, useSearchParams } from 'react-router-dom';
import { Divider, Text, Button, Box } from 'src/components/design-system';
import { useUser } from 'src/store';
import SelectFilter from 'src/widgets/Drawers/ProjectFilterDrawer/SelectFilter';
import LabelsFilter from 'src/widgets/Drawers/ProjectFilterDrawer/LabelsFilter';
import StatusesFilter from 'src/widgets/Drawers/ProjectFilterDrawer/StatusesFilter';
import CheckboxFilter from 'src/widgets/Drawers/ProjectFilterDrawer/CheckboxFilter';
import { useFilterItems } from 'src/widgets/Drawers/ProjectFilterDrawer/helpers';
import { useUpdateUserFilterMutation } from 'src/generated';
import { DisplayEnum, FiltersEnum, FilterViewLayout } from './types';
import * as Styled from './styles';
import { useUserFilter } from 'src/hooks/custom/useUserFilter';
import { DEFAULT_FILTERS } from './constants';

const component = {
  [DisplayEnum.Columns]: props => <SelectFilter {...props} />,
  [FiltersEnum.Priorities]: props => <SelectFilter {...props} />,

  [FiltersEnum.Statuses]: props => <StatusesFilter {...props} />,
  [FiltersEnum.Labels]: props => <LabelsFilter {...props} />,
  [FiltersEnum.DueDate]: props => <div {...props}>DueDate</div>,
  [FiltersEnum.HideCompleted]: props => <CheckboxFilter {...props} />,
};

const ProjectFilter = () => {
  const user = useUser();
  const { workspaceId } = user;
  const { id: projectId } = useParams() as any;
  const [searchParams] = useSearchParams();
  const viewType = searchParams.get('view');
  const { isFilterActive } = useUserFilter();

  const [updateUserFilter] = useUpdateUserFilterMutation({
    update(cache, { data }) {
      if (!data) return;
      const { updateUserFilter } = data;
      cache.modify({
        fields: {
          userFilter(existingUserFilter = null) {
            return updateUserFilter ?? existingUserFilter;
          },
        },
      });
    },
  });

  const handleFilter = value => {
    updateUserFilter({
      variables: {
        filter: {
          workspaceId,
          projectId,
          layout: viewType,
          ...value,
        },
      },
    });
  };

  const { filterItems, displayItems } = useFilterItems(
    viewType as FilterViewLayout,
  );

  return (
    <Box $padding={20}>
      <Styled.Header
        $alignItems="center"
        $justifyContent="space-between"
        $padding={[0, 0, 10, 0]}
        margin={[0, 0, 10, 0]}
      >
        <Styled.Title>{'Filters panel'}</Styled.Title>
        {isFilterActive && (
          <Button
            size="small"
            $htmlType="link"
            color="#3F51B5"
            underline={false}
            onClick={() => handleFilter({ ...DEFAULT_FILTERS })}
          >
            {'Reset'}
          </Button>
        )}
      </Styled.Header>

      <div>
        {displayItems.length > 0 && (
          <Box $padding={[10, 0, 0, 0]}>
            <Text as="h4">{'Display'}</Text>
            <Divider />
            {displayItems.map(({ id, ...rest }) => {
              const Component = component[id] || (() => null);
              return (
                <Box key={id} $padding={[10, 0, 20, 0]}>
                  <Component
                    {...rest}
                    onChange={value => {
                      handleFilter({ [id]: value });
                    }}
                  />
                </Box>
              );
            })}
          </Box>
        )}

        {filterItems.length > 0 && (
          <Box $padding={[10, 0, 0, 0]}>
            <Text as="h4">{'Filters'}</Text>
            <Divider />
            {filterItems.map(({ id, ...rest }) => {
              const Component = component[id] || (() => null);
              return (
                <Box key={id} $padding={[10, 0, 20, 0]}>
                  <Component
                    {...rest}
                    onChange={value => {
                      handleFilter({ [id]: value });
                    }}
                  />
                </Box>
              );
            })}
          </Box>
        )}
      </div>
    </Box>
  );
};

export default ProjectFilter;
