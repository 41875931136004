import React, { useMemo } from 'react';
import { DateHelpers } from 'src/common/helpers';
import * as Styled from './styles';
import { useUser } from 'src/store';

const Greetings = () => {
  const user = useUser();
  const today = new Date();
  const hours = today.getHours();

  const activeGreeting = useMemo(() => {
    const greetings = [
      {
        greet: "🌙 Wow! You're still awake. Working Late?",
        isActive: hours >= 0 && hours <= 4,
      },
      { greet: '🌜 Good Night!', isActive: hours >= 21 || hours < 5 },
      { greet: '🌆 Good Evening!', isActive: hours >= 18 && hours <= 20 },
      { greet: '🌞 Good Afternoon!', isActive: hours >= 12 && hours <= 17 },
      {
        greet: '☀️ Hello! Good Morning! Have a nice day.',
        isActive: hours >= 5 && hours <= 11,
      },
    ];

    return greetings.find(({ isActive }) => isActive)?.greet || '👋 Hello!';
  }, [hours]);

  return (
    <Styled.Container>
      <p>{DateHelpers.formatDate(today)}</p>
      <h2>{`${activeGreeting}, ${user.fullName}`}</h2>
    </Styled.Container>
  );
};

export default Greetings;
