import React from 'react';
import { components, ControlProps } from 'react-select';

import * as Styled from './styles';
import { SelectView } from 'src/components/design-system';

const StatusesControl = ({ children, ...props }: ControlProps) => {
  // @ts-ignore
  const { color, selectView } = props.selectProps?.value || {};

  return (
    <components.Control {...props}>
      {selectView !== SelectView.Table && (
        <Styled.StatusesColor color={color} />
      )}
      {children}
    </components.Control>
  );
};

export default StatusesControl;
