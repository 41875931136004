import React from 'react';
import { Box, Text } from 'src/components/design-system';
import LabelsSelect from 'src/components/design-system/Select/LabelsSelect';

const LabelsFilter = props => {
  const { label, value, onChange } = props;
  return (
    <Box>
      <Text weight="bold">{label}</Text>
      <LabelsSelect
        value={value}
        onChange={labels => {
          const ids = labels.map(({ _id }) => _id);
          onChange(ids);
        }}
      />
    </Box>
  );
};

export default LabelsFilter;
