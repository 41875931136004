import React, { CSSProperties, useMemo } from 'react';
import {
  useSortable,
  SortableContext,
  horizontalListSortingStrategy,
} from '@dnd-kit/sortable';
import { CSS } from '@dnd-kit/utilities';
import TableCell from './TableCell';
import { useIsTaskCompleted } from 'src/helpers/task';
import * as Styled from './styles';

const TableRow = ({ row, virtualRow, columnOrder, table }) => {
  const { transform, transition, setNodeRef, isDragging } = useSortable({
    id: row.id,
  });

  const isCompleted = useIsTaskCompleted(row.original);

  const isRowSelected = useMemo(() => {
    return Boolean(table.getSelectedRowModel().rowsById[row.id]);
  }, [row.id, table.getSelectedRowModel()]);

  const style: CSSProperties = useMemo(() => {
    return {
      display: 'flex',
      alignItems: 'center',
      height: `${virtualRow.size}px`,
      transform: CSS.Transform.toString(transform),
      transition,
      zIndex: isDragging ? 1 : 0,
      position: 'relative',
      boxShadow: isDragging ? '0 0 10px 0 rgba(0, 0, 0, 0.1)' : 'none',
    };
  }, [virtualRow.size, transform, transition, isDragging]);

  return (
    <Styled.TableRow
      ref={setNodeRef}
      style={style}
      $isCompleted={isCompleted}
      $isSelected={isRowSelected}
      $priority={row.original.priority}
    >
      {row.getVisibleCells().map(cell => (
        <SortableContext
          key={cell.id}
          items={columnOrder}
          strategy={horizontalListSortingStrategy}
        >
          <TableCell key={cell.id} cell={cell} />
        </SortableContext>
      ))}
    </Styled.TableRow>
  );
};

export default React.memo(TableRow);
