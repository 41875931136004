import React, { useMemo, CSSProperties } from 'react';
import { flexRender } from '@tanstack/react-table';
import { useSortable } from '@dnd-kit/sortable';
import { CSS } from '@dnd-kit/utilities';

const TableCell = ({ cell }) => {
  const { isDragging, setNodeRef, transform } = useSortable({
    id: cell.column.id,
  });

  const style: CSSProperties = useMemo(
    () => ({
      position: 'relative',
      transform: CSS.Translate.toString(transform), // Use CSS.Translate for better handling
      transition: 'width transform 0.2s ease-in-out',
      width: cell.column.getSize(),
      zIndex: isDragging ? 1 : 0,
    }),
    [cell.column, isDragging, transform],
  );

  return (
    <td style={style} ref={setNodeRef}>
      {flexRender(cell.column.columnDef.cell, cell.getContext())}
    </td>
  );
};
export default React.memo(TableCell);
