export enum FilterViewLayout {
  Kanban = 'kanban',
  Calendar = 'calendar',
  Table = 'table',
}

export enum FiltersEnum {
  Statuses = 'statuses',
  DueDate = 'dueDate',
  Priorities = 'priorities',
  HideCompleted = 'hideCompleted',
  Labels = 'labels',
}

export enum DisplayEnum {
  Columns = 'columns',
}
