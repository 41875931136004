import styled, { css } from 'styled-components';

type Draggable = {
  $isDragging?: boolean;
  $isDraggingOver?: boolean;
  $isEmpty?: boolean;
};

type TaskPreviewContainerProps = {
  top: number;
};

export const Container = styled.div<Draggable>`
  display: flex;
  flex-direction: column;
  height: 100%;
  border-radius: ${({ theme }) => theme.border.radius.sm};
  box-shadow: ${p =>
    p.$isDragging ? '0 3px 6px rgba(140, 149, 159, 0.35)' : null};
`;

export const TaskPreviewContainer = styled.li<TaskPreviewContainerProps>`
  position: absolute;
  opacity: 0.5;
  top: ${p => (p ? `${p.top}px` : null)};
  width: 250px;
`;

export const Head = styled.div`
  padding: 5px 10px;
`;

export const ColumnList = styled.ul<Draggable>`
  position: relative;
  display: flex;
  flex-direction: column;
  min-height: 20px;
  list-style: none;
  overflow-x: hidden;
  overflow-y: auto;
  height: 100%;
  width: 270px;
  margin: 0;
  padding: 10px;
  border-radius: ${({ theme }) => theme.border.radius.sm};
  transition: background 0.3s ease-in-out;

  li {
    width: 252px;
  }

  &:not(:hover) {
    ::-webkit-scrollbar-track {
      background: transparent;
    }

    ::-webkit-scrollbar-thumb {
      background: transparent;
    }
  }

  ${({ $isDraggingOver }) =>
    $isDraggingOver &&
    css`
      background: #eff1f3;
    `}
`;

export const EmptyText = styled.p`
  text-align: center;
  color: #ccc;
  margin: 0;
  font-style: italic;
  padding-top: 10px;
`;
