import React from 'react';
import { Helmet } from 'react-helmet-async';
import { Flex, Editor } from 'src/components/design-system';
import DashboardTasksByPeriod from 'src/widgets/DashboardTasksByPeriod/DashboardTasksByPeriod';
import MainLayout from 'src/layouts/MainLayout/MainLayout';
import MyTasks from 'src/pages/Home/MyTasks/MyTasks';
import Greetings from 'src/pages/Home/Greetings/Greetings';

const Home = () => {
  return (
    <>
      <Helmet>
        <title>{'Home'}</title>
        <meta
          name="app descriptions"
          content="A React Boilerplate application homepage"
        />
      </Helmet>

      <MainLayout>
        <Flex $gap={10} $padding={20} direction="column">
          <Greetings />
          <MyTasks />
          <DashboardTasksByPeriod />
          <Editor />
        </Flex>
      </MainLayout>
    </>
  );
};

export default Home;
