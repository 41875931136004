import React, { CSSProperties } from 'react';
import { flexRender, Header } from '@tanstack/react-table';
import { useSortable } from '@dnd-kit/sortable';
import { CSS } from '@dnd-kit/utilities';
import { TableTask } from 'src/components/design-system/TableView/columns';
import { Flex, Button, Icon } from 'src/components/design-system';

type TableHeaderProps = {
  header: Header<TableTask, unknown>;
};

const TableHeader = ({ header }: TableHeaderProps) => {
  const isAllowToMove = ![
    'drag-handle',
    'count',
    'selection',
    'title',
  ].includes(header.column.id);

  const { attributes, isDragging, listeners, setNodeRef, transform } =
    useSortable({
      id: header.column.id,
      disabled: !isAllowToMove,
    });

  const style: CSSProperties = {
    display: 'flex',
    opacity: isDragging ? 0.1 : 1,
    position: 'relative',
    transform: CSS.Translate.toString(transform),
    transition: 'width transform 0.2s ease-in-out',
    whiteSpace: 'nowrap',
    width: header.column.getSize(),
    zIndex: isDragging ? 1 : 0,
    height: '50px',
  };

  return (
    <th colSpan={header.colSpan} ref={setNodeRef} style={style}>
      <Flex $justifyContent="space-between" $alignItems="center" width="100%">
        {header.isPlaceholder
          ? null
          : flexRender(header.column.columnDef.header, header.getContext())}

        {isAllowToMove && (
          <>
            <Button $htmlType="link" {...attributes} {...listeners}>
              <Icon name="drag-horizontal" />
            </Button>
          </>
        )}
      </Flex>
    </th>
  );
};

export default TableHeader;
