import React from 'react';
import TaskModalLeft from './TaskModalLeft/TaskModalLeft';
import TaskModalRight from './TaskModalRight/TaskModalRight';

import { Container, Header, Row } from './styles';
import { Icon, Tooltip, Button } from 'src/components/design-system';
import { useTranslation } from 'react-i18next';
import { useUser } from 'src/hooks/useUser';
import { Action } from 'src/generated';

type TaskModalProps = {
  action: Action;
  onClose: () => void;
};

const TaskModal = ({ action, onClose }: TaskModalProps) => {
  const { t } = useTranslation();
  const { onUpdateUserMinimizedTasks } = useUser();

  return (
    <Container>
      <Header $padding={4} $gap={2} bg={action?.projectId?.color || 'navy'}>
        <Tooltip overlay={t('general.close')} placement="top">
          <Button
            size="small"
            $htmlType="text"
            $padding="0"
            onClick={onClose}
            icon={<Icon name="close" size={24} color="#fff" />}
          />
        </Tooltip>
        <Tooltip overlay={t('general.minimize')} placement="top">
          <Button
            size="small"
            $htmlType="text"
            onClick={() => {
              onClose();
              onUpdateUserMinimizedTasks({
                addMinimizedTaskId: action._id,
              });
            }}
            icon={<Icon name="minimize" size={14} color="#fff" />}
          />
        </Tooltip>
      </Header>
      <Row>
        <TaskModalLeft action={action} onClose={onClose} />
        <TaskModalRight action={action} />
      </Row>
    </Container>
  );
};

export default TaskModal;
