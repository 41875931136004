import { borderLight } from './border';
import { boxShadow } from './box-shadow';
import { colors, grayScale } from './colors';
import { fontLight } from './font';

export const lightTheme = {
  background: {
    primary: '#F6F8FA',
    secondary: '#fff',
    tertiary: grayScale.gray15,
    selected: 'rgba(144,157,171,0.08)',
    hover: 'rgba(144,157,171,0.12)',
    dark: 'rgb(32, 32, 32)',
  },
  header: {
    primary: '#F6F8FA',
    text: '#1F2328',
  },
  text: {
    primary: '#474747',
    primaryHover: '#1F2328',
    active: '#474747',
    secondary: '#768390',
    link: '#0366d6',
    white: '#F6F8FA',
  },
  taskPreview: {
    primary: '#eff2f4',
    border: '#d0d7de',
  },
  task: {
    primary: '#ffffff',
    secondary: '#f5f2f2',
    completed: '#D6EFE3',
    selected: '#9ad5ef',
    urgent: '#ffd5d5',
    high: '#ffedd5',
  },
  layout: {
    background: '#F6F8FA',
  },
  sidebar: {
    primary: '#22272e',
  },
  switch: {
    primary: '#F6F8FA',
    secondary: '#b4b4bb',
  },
  scrollbar: {
    scrollbarTrack: '#F6F8FA',
    scrollbarThumb: '#c0c2c5',
  },
  button: {
    active: {
      background: '#079aaa',
      color: '#fff',
    },
  },

  color: colors,
  grayScale: grayScale,
  border: borderLight,
  font: fontLight,
  boxShadow: boxShadow,
  spacing: (...args: number[]) =>
    args.map(multiplicator => `${multiplicator * 4}px`).join(' '),
  snowflake: '#b4d1f1',
};
