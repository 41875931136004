import React, { useMemo } from 'react';
import Select, {
  StylesConfig,
  Props as SelectProps,
  SingleValue,
  MultiValue,
} from 'react-select';
import { useTheme } from 'styled-components';

type CustomSelectProps<T> = SelectProps<any, boolean> & {
  styles?: StylesConfig<T, boolean>;
  onChange?: (value: SingleValue<any> | MultiValue<any>) => void;
};

const CustomSelect = <T extends { label: string; value: any }>({
  styles: customStyles = {},
  onChange,
  ...props
}: CustomSelectProps<T>) => {
  const theme = useTheme(); // Get theme from styled-components

  // Memoized default styles
  const defaultStyles = useMemo<StylesConfig>(
    () => ({
      control: (base, state) => ({
        ...base,
        borderRadius: '4px',
        background: theme.background.primary,
        border: `1px solid ${
          state.isFocused
            ? theme.border.color.primary
            : theme.border.color.secondary
        }`,
        boxShadow: state.isFocused
          ? `0px 0px 5px ${theme.border.color.primary}`
          : 'none',
        transition: 'border 0.2s ease, box-shadow 0.2s ease',
        '&:hover': {
          borderColor: theme.border.color.primary,
        },
      }),
      singleValue: base => ({
        ...base,
        color: theme.text.primary,
      }),
      multiValue: base => ({
        ...base,
      }),
      multiValueLabel: base => ({
        ...base,
      }),
      multiValueRemove: base => ({
        ...base,
      }),
      valueContainer: base => ({
        ...base,
        padding: '4px 12px',
      }),
      option: (provided, { isFocused }) => ({
        ...provided,
        backgroundColor: isFocused
          ? theme.background.hover
          : theme.background.primary,
        color: isFocused ? 'rgb(43,166,255)' : theme.text.primary,
        cursor: 'pointer',
      }),
      menu: base => ({
        ...base,
        margin: 0,
        borderRadius: '4px',
        overflow: 'hidden',
        boxShadow: '0px 4px 6px rgba(0, 0, 0, 0.1)',
        background: theme.background.primary,
        color: theme.text.primary,
      }),
      menuList: base => ({
        ...base,
        padding: 0,
        '::-webkit-scrollbar': {
          width: '4px',
        },
        '::-webkit-scrollbar-track': {
          background: theme.background.hover,
        },
        '::-webkit-scrollbar-thumb': {
          background: theme.background.selected,
          borderRadius: '4px',
        },
        '::-webkit-scrollbar-thumb:hover': {
          background: '#555',
        },
      }),
    }),
    [theme], // Recalculate only if theme changes
  );

  // Merge custom styles with default styles using useMemo
  const mergedStyles = useMemo<StylesConfig<T, boolean>>(
    () => ({
      control: (base, props) => ({
        ...defaultStyles.control?.(base, props as any),
        ...customStyles.control?.(base, props),
      }),
      singleValue: (base, props) => ({
        ...defaultStyles.singleValue?.(base, props as any),
        ...customStyles.singleValue?.(base, props),
      }),
      valueContainer: (base, props) => ({
        ...defaultStyles.valueContainer?.(base, props as any),
        ...customStyles.valueContainer?.(base, props),
      }),
      menu: (base, props) => ({
        ...defaultStyles.menu?.(base, props as any),
        ...customStyles.menu?.(base, props),
      }),
      menuList: (base, props) => ({
        ...defaultStyles.menuList?.(base, props as any),
        ...customStyles.menuList?.(base, props),
      }),
      multiValueLabel: (base, props) => ({
        ...defaultStyles.multiValueLabel?.(base, props as any),
        ...customStyles.multiValueLabel?.(base, props),
      }),
      multiValue: (base, props) => ({
        ...defaultStyles.multiValue?.(base, props as any),
        ...customStyles.multiValue?.(base, props),
      }),
      multiValueRemove: (base, props) => ({
        ...defaultStyles.multiValueRemove?.(base, props as any),
        ...customStyles.multiValueRemove?.(base, props),
      }),
      option: (base, props) => ({
        ...defaultStyles.option?.(base, props as any),
        ...customStyles.option?.(base, props),
      }),
    }),
    [customStyles, defaultStyles],
  );

  return (
    <Select
      {...props}
      styles={mergedStyles}
      onChange={selected => {
        if (onChange) onChange(selected);
      }}
    />
  );
};

export default CustomSelect;
