import React, { useMemo } from 'react';
import { useLabelsQuery, Label } from 'src/generated';
import { Select, SelectView } from 'src/components/design-system';
import { useUserWorkspace } from 'src/store';
import { useProjectId } from 'src/hooks/custom';

type LabelsSelectProps = {
  onChange: (labels: Label[]) => void;
  value: string[];
  selectView?: SelectView;
};

type LabelOption = {
  _id: string;
  value: string;
  label: string;
  title: string;
  color: string;
};

const LabelsSelect = ({
  value = [],
  onChange,
  selectView,
}: LabelsSelectProps) => {
  const workspaceId = useUserWorkspace();
  const projectId = useProjectId();

  const { data, loading } = useLabelsQuery({
    variables: {
      workspaceId,
      projectId,
    },
  });

  const selectedLabels = useMemo(() => {
    if (!data) return [];

    return data.labels.reduce<LabelOption[]>((acc, label) => {
      if (value.includes(label._id)) {
        acc.push({
          _id: label._id,
          value: label._id,
          label: label.title,
          title: label.title,
          color: label.color,
        });
      }
      return acc;
    }, []);
  }, [data, value]);

  const additionalProps = useMemo(() => {
    if (selectView === SelectView.Table) {
      return {
        menuPortalTarget: document.body,
        styles: {
          control: base => ({
            ...base,
            background: 'transparent',
            border: 'none',
            minHeight: '48px',
            height: '48px',
            display: 'flex',
            flexWrap: 'nowrap',
            overflowX: 'auto',
          }),
          valueContainer: base => ({
            ...base,
            display: 'flex',
            flexWrap: 'nowrap',
            overflowX: 'auto',
            scrollbarWidth: 'thin',
            '::-webkit-scrollbar': {
              height: '4px',
            },
          }),
          multiValue: (styles, { data }) => {
            return {
              ...styles,
              flexShrink: 0,
              backgroundColor: data.color,
            };
          },
          multiValueRemove: (styles, { data }) => ({
            ...styles,
            color: 'white',
            ':hover': {
              backgroundColor: data.color,
              color: 'white',
            },
          }),
          multiValueLabel: base => ({
            ...base,
            whiteSpace: 'nowrap',
            overflow: 'hidden',
            textOverflow: 'ellipsis',
            borderRadius: '4px',
            fontWeight: 'bold',
            color: '#fff',
          }),
        },
        components: {
          ClearIndicator: undefined,
          DropdownIndicator: undefined,
        },
      };
    }
    return {};
  }, [selectView]);

  return (
    <Select
      {...additionalProps}
      isMulti
      value={selectedLabels}
      closeMenuOnSelect={false}
      isSearchable={false}
      isLoading={loading}
      onChange={onChange}
      options={data?.labels.map(label => ({
        _id: label._id,
        value: label._id,
        label: label.title,
        title: label.title,
        color: label.color,
      }))}
    />
  );
};

export default LabelsSelect;
