import React, { useMemo } from 'react';
import { useStatusesQuery } from 'src/generated';
import { Select, SelectView } from 'src/components/design-system';
import StatusesControl from 'src/components/design-system/Select/components/statuses/StatusesControl';
import StatusesOption from 'src/components/design-system/Select/components/statuses/StatusesOption';
import { getContrastTextColor } from 'src/helpers/colors';
import { useProjectId } from 'src/hooks/custom';

type StatusesSelectProps = {
  isMulti?: boolean;
  value: string | string[];
  onChange: ({ value }: any) => void;
  selectView?: SelectView;
};

type LabelOption = {
  _id: string;
  value: string;
  label: string;
  title: string;
  color: string;
};

const StatusesSelect = ({
  value,
  onChange,
  selectView,
  isMulti = false,
}: StatusesSelectProps) => {
  const projectId = useProjectId();

  const { data, loading } = useStatusesQuery({
    variables: {
      projectId,
    },
  });

  const currentValue = useMemo(() => {
    if (isMulti) {
      return data?.statuses.reduce<LabelOption[]>((acc, status) => {
        if (value.includes(status._id)) {
          acc.push({
            _id: status._id,
            value: status._id,
            label: status.title,
            title: status.title,
            color: status.color,
          });
        }
        return acc;
      }, []);
    }

    const status = data?.statuses?.find(({ _id }) => _id === value);
    return status
      ? {
          _id: status._id,
          value: status._id,
          label: status.title,
          color: status.color,
          selectView,
        }
      : null;
  }, [data?.statuses, isMulti, selectView, value]);

  const additionalProps = useMemo(() => {
    if (isMulti) {
      return {};
    }

    if (selectView === SelectView.Table) {
      const status = currentValue as unknown as LabelOption;
      const color = getContrastTextColor({
        backgroundColor: status?.color,
        themeMode: 'themeMode',
      });

      return {
        menuPortalTarget: document.body,
        styles: {
          control: base => ({
            ...base,
            background: status?.color,
            borderRadius: 'none',
            border: `1px solid ${status?.color}`,
            boxShadow: `0 0 0 1px ${status?.color}`,
            minHeight: '48px',
            height: '48px',
            '&:hover': {
              borderColor: status?.color,
            },
          }),
          singleValue: base => ({
            ...base,
            color,
          }),
        },
        components: {
          Control: StatusesControl as any,
          Option: StatusesOption as any,
          ClearIndicator: undefined,
          DropdownIndicator: undefined,
        },
      };
    }
    return {
      components: {
        Control: StatusesControl as any,
        Option: StatusesOption as any,
      },
    };
  }, [currentValue, isMulti, selectView]);

  return (
    <Select
      {...additionalProps}
      isMulti={isMulti}
      isLoading={loading}
      isSearchable={false}
      value={currentValue}
      onChange={onChange}
      options={data?.statuses.map(status => ({
        _id: status._id,
        value: status._id,
        label: status.title,
        color: status.color,
      }))}
    />
  );
};

export default StatusesSelect;
