import React, { useMemo } from 'react';
import Select from 'react-select';
import PriorityControl from 'src/components/design-system/Select/components/priority/PriorityControl';
import PriorityOption from 'src/components/design-system/Select/components/priority/PriorityOption';
import { priorities } from 'src/common/constants';

type StatusesSelectProps = {
  taskPriority: string;
  onChange: ({ value }: any) => void;
};

const PrioritiesSelect = ({ taskPriority, onChange }: StatusesSelectProps) => {
  const priority = useMemo(() => {
    return (
      priorities.find(({ value }) => value === taskPriority) ?? {
        value: null,
        label: 'None',
      }
    );
  }, [taskPriority]);

  return (
    <Select
      menuPortalTarget={document.body}
      isSearchable={false}
      options={priorities}
      value={priority}
      styles={{
        control: base => ({
          ...base,
          background: 'transparent',
          border: 'none',
          minHeight: '48px',
          height: '48px',
        }),
      }}
      onChange={onChange}
      components={{
        Control: PriorityControl as any,
        Option: PriorityOption as any,
        ClearIndicator: undefined,
        DropdownIndicator: undefined,
      }}
    />
  );
};

export default PrioritiesSelect;
