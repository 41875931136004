import React from 'react';
import { useTranslation } from 'react-i18next';

import { useReactiveVar } from '@apollo/client';

import { MessageEditor } from 'src/components/design-system';

import { useComments } from 'src/hooks/useComments';

import Comment from './Comment/Comment';
import { editingCommentVar } from 'src/graphql/vars';

const Comments = ({ taskId, projectIdIdForComment }) => {
  const { t } = useTranslation();
  const { comments, loading, onDeleteComment } = useComments();
  const editingComment = useReactiveVar(editingCommentVar);

  if (loading) return <p>{'Loading...'}</p>;
  if (!comments) return <p>{'Error :('}</p>;

  return (
    <>
      {comments.map(comment => (
        <Comment
          {...comment}
          key={comment._id}
          onDeleteComment={onDeleteComment}
        />
      ))}
      {/* TODO Hide add comment editor because issue with ref */}
      {!editingComment && (
        <MessageEditor
          taskId={taskId}
          projectId={projectIdIdForComment}
          placeholder={t('task.comments.addCommentPlaceholder')}
        />
      )}
    </>
  );
};

export default Comments;
