import React, { useState, useRef } from 'react';
import { useMutation } from '@apollo/client';

import { Icon, Menu } from 'src/components/design-system';

import Dropdown from 'rc-dropdown';
import { ADD_ATTACHMENTS_TO_TASK } from 'src/graphql/actions';
import { formatBytes } from 'src/helpers/files';

import {
  AttachmentsList,
  AttachmentItem,
  AttachmentItemImg,
  AttachmentItemBody,
  AttachmentItemInfo,
  AttachmentItemTitle,
  AttachmentItemInfoText,
  OpenAttachmentsDropDown,
} from './styles';
import { DRAWER_IDS, useDrawerStore } from 'src/store';
import { useProjectId } from 'src/hooks/custom';

export enum ADD_ATTACHMENTS_MENU {
  UPLOAD_FILES = 'Upload files',
  UNSPLASH = 'Unsplash',
}

export const addAttachmentsMenu = [
  {
    id: ADD_ATTACHMENTS_MENU.UPLOAD_FILES,
    title: 'task.addAttachmentsMenu.uploadFiles',
    icon: 'save',
  },
  {
    id: ADD_ATTACHMENTS_MENU.UNSPLASH,
    title: 'task.addAttachmentsMenu.unsplash',
    icon: 'save',
  },
];

const AddAttachments = ({ id }) => {
  const { openDrawer } = useDrawerStore(DRAWER_IDS.UNSPLASH);
  const inputRef = useRef<HTMLInputElement>(null);
  const projectId = useProjectId();

  const [uploadFiles, setUploadFiles] = useState([]);

  const [addAttachments] = useMutation(ADD_ATTACHMENTS_TO_TASK, {
    onCompleted: () => {
      setUploadFiles([]);
    },
  });

  const handleUploadFile = event => {
    const { files } = event.target;

    if (!files) return;

    const uploadFiles = Array.from(files).map((file: any) => {
      return {
        fileName: file.name,
        size: file.size,
        type: file.type,
        url: URL.createObjectURL(file),
      };
    });

    // @ts-ignore
    setUploadFiles(uploadFiles);

    try {
      addAttachments({
        variables: {
          taskId: id,
          projectId,
          attachments: Array.from(files),
        },
      });
    } catch (e) {
      console.log(e, 'e');
    }
  };

  const handleMenuClick = (key: ADD_ATTACHMENTS_MENU) => {
    switch (key) {
      case ADD_ATTACHMENTS_MENU.UPLOAD_FILES: {
        if (inputRef.current) {
          inputRef.current.click();
        }
        break;
      }
      case ADD_ATTACHMENTS_MENU.UNSPLASH: {
        openDrawer();
        break;
      }
      default:
        break;
    }
  };

  return (
    <>
      <Dropdown
        trigger={['click']}
        overlay={() => (
          <Menu
            options={addAttachmentsMenu}
            handleMenuClick={handleMenuClick}
          />
        )}
      >
        <OpenAttachmentsDropDown>
          <Icon name="plus-square-fill" color="#5591ea" size={20} />
        </OpenAttachmentsDropDown>
      </Dropdown>

      <input
        hidden
        type="file"
        multiple
        onChange={handleUploadFile}
        ref={inputRef}
      />

      <AttachmentsList>
        {uploadFiles.map(({ fileName, url, size }, index) => (
          <AttachmentItem key={index}>
            <AttachmentItemImg>
              <img src={url} alt={fileName} />
            </AttachmentItemImg>
            <AttachmentItemBody>
              <AttachmentItemTitle>{fileName}</AttachmentItemTitle>
              <AttachmentItemInfo>
                <AttachmentItemInfoText>
                  <div>{formatBytes(size)}</div>
                </AttachmentItemInfoText>
              </AttachmentItemInfo>
            </AttachmentItemBody>
          </AttachmentItem>
        ))}
      </AttachmentsList>
    </>
  );
};

export default AddAttachments;
