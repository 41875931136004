import React, { useState, useCallback } from 'react';
import {
  Flex,
  Modal,
  Input,
  Icon,
  LoadingIndicator,
} from 'src/components/design-system';
import { useTheme } from 'styled-components';
import TaskPreview from 'src/pages/Project/components/Board/Action/TaskPreview/TaskPreview';
import { useTasksLazyQuery } from 'src/generated';
import { useHotkeys } from 'react-hotkeys-hook';
import debounce from 'lodash/debounce';
import { useUserWorkspace } from 'src/store';

import * as Styled from './styles';

const Search: React.FC = () => {
  const workspaceId = useUserWorkspace();
  const theme = useTheme();
  const [value, setValue] = useState('');
  const [visible, setVisible] = useState(false);

  const [getTasks, { data, loading }] = useTasksLazyQuery();

  useHotkeys('meta+k', () => setVisible(true));

  const fetchTasks = useCallback(
    debounce((searchTerm: string) => {
      if (searchTerm.length < 2) return;

      getTasks({
        variables: {
          filter: {
            workspaceId,
            search: searchTerm,
          },
        },
      });
    }, 400),
    [getTasks],
  );

  const handleInputChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    setValue(e.target.value);
    fetchTasks(e.target.value.trim());
  };

  return (
    <>
      <Styled.SearchButton
        icon={<Icon size={14} name="search" color={theme.color.grey600} />}
        onClick={() => setVisible(!visible)}
      >
        Search...
        <Styled.SearchButtonKey>⌘ + K</Styled.SearchButtonKey>
      </Styled.SearchButton>

      <Modal
        isOpen={visible}
        width={1000}
        showTopBorder={false}
        showCloseButton={false}
        onRequestClose={() => setVisible(false)}
        style={{
          content: {
            padding: 0,
            borderRadius: '12px',
          },
        }}
      >
        <Styled.InputWrapper>
          <Input
            style={{
              border: 'none',
            }}
            value={value}
            type="text"
            placeholder="Search, run a command, or ask a question…"
            onChange={handleInputChange}
            autoFocus
            suffix={
              loading && (
                <Styled.LoadingWrapper>
                  <LoadingIndicator />
                </Styled.LoadingWrapper>
              )
            }
          />
        </Styled.InputWrapper>

        <Styled.SearchResults>
          {!loading && data?.tasks.items.length === 0 && (
            <Flex
              $alignItems="center"
              $justifyContent="center"
              direction="column"
              $height="100%"
            >
              <strong>No results found</strong>
              Try using different keywords
            </Flex>
          )}
          <Styled.GridContainer>
            {data?.tasks.items.map(task => (
              <TaskPreview key={task._id} task={task} />
            ))}
          </Styled.GridContainer>
        </Styled.SearchResults>
      </Modal>
    </>
  );
};

export default Search;
