import { DisplayEnum, FiltersEnum, FilterViewLayout } from './types';
import {
  DEFAULT_FILTERS,
  FILTER_CONFIG,
  TABLE_COLUMNS,
  COLUMN_LABELS,
} from './constants';
import { useUserFilter } from 'src/hooks/custom/useUserFilter';
import { priorities } from 'src/common/constants';

interface FilterOption {
  value: string;
  label: string;
}

interface FilterItem {
  id: string;
  label: string;
  options?: FilterOption[];
  value?: string | FilterOption[] | boolean | string[];
}

export const useFilterItems = (
  viewType: FilterViewLayout,
): {
  filterItems: FilterItem[];
  displayItems: FilterItem[];
} => {
  const { userFilter } = useUserFilter();
  const { filters, display } = FILTER_CONFIG[viewType] || [
    { filters: [], display: [] },
  ];
  const filterItems: FilterItem[] = [];
  const displayItems: FilterItem[] = [];

  display.forEach(displayType => {
    switch (displayType) {
      case DisplayEnum.Columns:
        displayItems.push({
          id: DisplayEnum.Columns,
          label: 'Columns',
          options: TABLE_COLUMNS.map(column => ({
            value: column,
            label: COLUMN_LABELS[column],
          })),
          value: (userFilter?.columns ?? DEFAULT_FILTERS.columns).map(
            column => ({
              value: column,
              label: COLUMN_LABELS[column],
            }),
          ),
        });
        break;
    }
  });

  filters.forEach(filterType => {
    switch (filterType) {
      case FiltersEnum.Priorities:
        filterItems.push({
          id: FiltersEnum.Priorities,
          label: 'Priorities',
          options: priorities,
          value: (userFilter?.priorities || []).map(column => ({
            value: column,
            label: column,
          })),
        });
        break;

      case FiltersEnum.Statuses:
        filterItems.push({
          id: FiltersEnum.Statuses,
          label: 'Statuses',
          value: userFilter?.statuses || [],
        });
        break;

      case FiltersEnum.Labels:
        filterItems.push({
          id: FiltersEnum.Labels,
          label: 'Labels',
          value: userFilter?.labels || [],
        });
        break;

      case FiltersEnum.HideCompleted:
        filterItems.push({
          id: FiltersEnum.HideCompleted,
          label: 'Hide completed',
          value: userFilter?.hideCompleted,
        });
        break;
    }
  });

  return {
    displayItems,
    filterItems,
  };
};
