import { borderDark } from './border';
import { boxShadow } from './box-shadow';
import { colors, grayScale } from './colors';
import { fontDark } from './font';

export const darkTheme = {
  background: {
    primary: '#1e1f21',
    secondary: '#252628',
    tertiary: grayScale.gray75,
    selected: 'rgba(144,157,171,0.08)',
    hover: 'rgba(144,157,171,0.12)',
    dark: 'rgb(42, 42, 42)',
  },
  header: {
    primary: '#1e1f21',
    text: '#cdd9e5',
  },
  text: {
    primary: '#f1f1f1',
    primaryHover: 'rgb(170, 170, 170)',
    active: 'rgb(170, 170, 170)',
    secondary: '#768390',
    link: '#0366d6',
    white: '#F6F8FA',
  },
  taskPreview: {
    primary: '#2a2b2d',
    border: '#444c56',
  },
  task: {
    primary: '#2a2b2d',
    secondary: '#28282a',
    completed: 'rgba(8,55,33,0.35)',
    selected: '#4992b0',
    urgent: '#972e2e',
    high: '#c3832e',
  },
  sidebar: {
    primary: '#2e2e30',
  },
  layout: {
    background: '#1e1f21',
  },
  switch: {
    primary: '#1e1f21',
    secondary: '#b4b4bb',
  },
  scrollbar: {
    scrollbarTrack: '#1e1f21',
    scrollbarThumb: '#2e2e30',
  },
  button: {
    active: {
      background: '#079aaa',
      color: '#fff',
    },
  },

  color: colors,
  grayScale: grayScale,
  border: borderDark,
  font: fontDark,
  boxShadow,
  spacing: (...args: number[]) =>
    args.map(multiplicator => `${multiplicator * 4}px`).join(' '),
  snowflake: '#fff',
};
