import { useMemo } from 'react';
import { useUserFilterQuery } from 'src/generated';
import { useUser } from 'src/store';
import { useProjectId } from 'src/hooks/custom/useProjectId';
import isEqual from 'lodash/isEqual';
import pick from 'lodash/pick';
import { DEFAULT_FILTERS } from 'src/widgets/Drawers/ProjectFilterDrawer/constants';
import { useSearchParams } from 'react-router-dom';

export const useUserFilter = () => {
  const user = useUser();
  const { _id: userId, workspaceId } = user;
  const projectId = useProjectId();
  const [searchParams] = useSearchParams();
  const layout = searchParams.get('view')!;

  const { data } = useUserFilterQuery({
    variables: {
      workspaceId,
      projectId,
      userId,
      layout,
    },
  });

  const isFilterActive = useMemo(() => {
    return (
      Boolean(data?.userFilter) &&
      !isEqual(
        pick(data?.userFilter, Object.keys(DEFAULT_FILTERS)),
        DEFAULT_FILTERS,
      )
    );
  }, [data?.userFilter]);

  return {
    userFilter: data?.userFilter,
    isFilterActive,
  };
};
