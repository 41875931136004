import React from 'react';
import { Props } from 'react-modal';
import { Swiper, SwiperSlide } from 'swiper/react';

import { Button, Modal, Icon } from 'src/components/design-system';

import { DateHelpers, DateFormats } from 'src/common/helpers';

import { Pagination, Navigation } from 'swiper';
// Import Swiper styles
import 'swiper/css';
import 'swiper/css/effect-fade';
import 'swiper/css/navigation';
import 'swiper/css/pagination';

import { FilesQuery } from 'src/generated';

import {
  Container,
  Header,
  HeaderRight,
  Body,
  ImageBlock,
  Image,
  FileInfo,
  FileInfoDate,
} from './styles';

interface FileModalProps extends Props {
  files: FilesQuery['files'];
  initialSlide: number;
  setInitialSlide: (index: number) => void;
  onRequestClose: () => void;
}

const FileModal = ({
  files,
  initialSlide,
  setInitialSlide,
  ...rest
}: FileModalProps) => {
  return (
    <Modal
      {...rest}
      width="100%"
      noOpenAnimation
      showTopBorder={false}
      overlayClassName="modal-overlay-blur"
      className="modal-body-transparent"
      showCloseButton={false}
    >
      <Container
        onClick={e => {
          e.stopPropagation();

          //@ts-expect-error
          if (e.target.classList.contains('image-overlay')) {
            rest.onRequestClose();
          }
        }}
      >
        <Header>
          {files[initialSlide] && (
            <FileInfo>
              <div>{files[initialSlide].fileName}</div>
              <FileInfoDate>
                {DateHelpers.formatDate(
                  files[initialSlide].createdAt,
                  DateFormats.DayLongMonthYear,
                )}
              </FileInfoDate>
            </FileInfo>
          )}
          <HeaderRight>
            <Button $htmlType="text" onClick={rest.onRequestClose}>
              <Icon name="close" color="white" size={22} />
            </Button>
          </HeaderRight>
        </Header>
        <Body>
          <Swiper
            initialSlide={initialSlide}
            slidesPerView={1}
            pagination={{
              clickable: true,
            }}
            navigation={true}
            modules={[Pagination, Navigation]}
            onSlideChange={e => {
              setInitialSlide(e.activeIndex);
            }}
            className="files-modal-swiper"
          >
            {files.map(({ _id, urls }) => (
              <SwiperSlide key={_id}>
                <ImageBlock className="image-overlay">
                  <Image src={urls?.original} alt="" />
                </ImageBlock>
              </SwiperSlide>
            ))}
          </Swiper>
        </Body>
      </Container>
    </Modal>
  );
};

export default FileModal;
