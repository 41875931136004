import { FiltersEnum, DisplayEnum, FilterViewLayout } from './types';

export enum TABLE_COLUMN {
  DragHandle = 'drag-handle',
  Count = 'count',
  Selection = 'selection',
  Title = 'title',
  Priority = 'priority',
  Labels = 'labels',
  StoryPoints = 'storyPoints',
  DueDate = 'dueDate',
  Status = 'statusId',
  Assignee = 'assignee',
  CreatedAt = 'createdAt',
  UpdatedAt = 'updatedAt',
  CompletedAt = 'completedAt',
}

export const COLUMN_LABELS = {
  [TABLE_COLUMN.DragHandle]: 'Drag Handle',
  [TABLE_COLUMN.Count]: 'Count',
  [TABLE_COLUMN.Selection]: 'Selection',
  [TABLE_COLUMN.Title]: 'Title',
  [TABLE_COLUMN.Priority]: 'Priority',
  [TABLE_COLUMN.Labels]: 'Labels',
  [TABLE_COLUMN.StoryPoints]: 'Story Points',
  [TABLE_COLUMN.DueDate]: 'Due Date',
  [TABLE_COLUMN.Status]: 'Status',
  [TABLE_COLUMN.Assignee]: 'Assignee',
  [TABLE_COLUMN.CreatedAt]: 'Created At',
  [TABLE_COLUMN.UpdatedAt]: 'Updated At',
  [TABLE_COLUMN.CompletedAt]: 'Completed At',
};

export const NOT_EDITABLE_COLUMNS = [
  TABLE_COLUMN.DragHandle,
  TABLE_COLUMN.Count,
  TABLE_COLUMN.Selection,
  TABLE_COLUMN.Title,
];

export const TABLE_COLUMNS = [
  TABLE_COLUMN.Priority,
  TABLE_COLUMN.Labels,
  TABLE_COLUMN.DueDate,
  TABLE_COLUMN.StoryPoints,
  TABLE_COLUMN.Status,
  TABLE_COLUMN.Assignee,
  TABLE_COLUMN.CreatedAt,
  TABLE_COLUMN.UpdatedAt,
  TABLE_COLUMN.CompletedAt,
];

export const DEFAULT_FILTERS = {
  columns: [
    TABLE_COLUMN.Status,
    TABLE_COLUMN.Labels,
    TABLE_COLUMN.DueDate,
    TABLE_COLUMN.Priority,
  ],
  priorities: [],
  labels: [],
  statuses: [],
  hideCompleted: false,
};

export const FILTER_CONFIG = {
  [FilterViewLayout.Kanban]: {
    display: [],
    filters: [FiltersEnum.Statuses],
  },
  [FilterViewLayout.Table]: {
    display: [DisplayEnum.Columns],
    filters: [
      FiltersEnum.Statuses,
      FiltersEnum.Priorities,
      FiltersEnum.Labels,
      FiltersEnum.HideCompleted,
    ],
  },
  [FilterViewLayout.Calendar]: {
    display: [],
    filters: [FiltersEnum.DueDate],
  },
};

export const dueDateOptions = [
  {
    label: 'All',
    value: null,
  },
  {
    label: 'Today',
    value: 'today',
  },
  {
    label: 'Tomorrow',
    value: 'tomorrow',
  },
  {
    label: 'This week',
    value: 'thisWeek',
  },
  {
    label: 'This month',
    value: 'thisMonth',
  },
  {
    label: 'This year',
    value: 'thisYear',
  },
  {
    label: 'Overdue',
    value: 'overdue',
  },
];
