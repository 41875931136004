import type React from 'react';
import styled, { css } from 'styled-components';

// Define the props for our Text component
type TextProps = {
  as?: React.ElementType;
  color?: string;
  align?: 'left' | 'center' | 'right' | 'justify';
  weight?: 'normal' | 'medium' | 'semibold' | 'bold';
  italic?: boolean;
  underline?: boolean;
  truncate?: boolean;
  className?: string;
  children: React.ReactNode;
  // Margin and padding props
  m?: string | number;
  mt?: string | number;
  mr?: string | number;
  mb?: string | number;
  ml?: string | number;
  mx?: string | number;
  my?: string | number;
  p?: string | number;
  pt?: string | number;
  pr?: string | number;
  pb?: string | number;
  pl?: string | number;
  px?: string | number;
  py?: string | number;
} & React.HTMLAttributes<HTMLElement>;

// Helper function to convert number to pixel value
const toPx = (value: string | number | undefined): string | undefined => {
  if (value === undefined) return undefined;
  return typeof value === 'number' ? `${value}px` : value;
};

// Define the styles for each element type
const elementStyles = {
  h1: css`
    font-size: 2rem;
    font-weight: 700;
    line-height: 1.2;
    @media (max-width: 768px) {
      font-size: 2rem;
    }
  `,
  h2: css`
    font-size: 1.5rem;
    font-weight: 700;
    line-height: 1.2;
    @media (max-width: 768px) {
      font-size: 1.75rem;
    }
  `,
  h3: css`
    font-size: 1.3rem;
    font-weight: 600;
    line-height: 1.3;
    @media (max-width: 768px) {
      font-size: 1.5rem;
    }
  `,
  h4: css`
    font-size: 1.2rem;
    font-weight: 600;
    line-height: 1.3;
    @media (max-width: 768px) {
      font-size: 1.25rem;
    }
  `,
  h5: css`
    font-size: 1.05rem;
    font-weight: 600;
    line-height: 1.4;
    @media (max-width: 768px) {
      font-size: 1.1rem;
    }
  `,
  h6: css`
    font-size: 1rem;
    font-weight: 600;
    line-height: 1.4;
    @media (max-width: 768px) {
      font-size: 1rem;
    }
  `,
  p: css`
    font-size: 1rem;
    font-weight: 400;
    line-height: 1.5;
  `,
  span: css`
    font-size: 1rem;
    font-weight: 400;
    line-height: 1.5;
  `,
  small: css`
    font-size: 0.875rem;
    font-weight: 400;
    line-height: 1.5;
  `,
  strong: css`
    font-size: 1rem;
    font-weight: 700;
    line-height: 1.5;
  `,
  em: css`
    font-size: 1rem;
    font-weight: 400;
    font-style: italic;
    line-height: 1.5;
  `,
  label: css`
    font-size: 0.875rem;
    font-weight: 500;
    line-height: 1.5;
  `,
  div: css`
    font-size: 1rem;
    font-weight: 400;
    line-height: 1.5;
  `,
};

// Create the styled component
const StyledText = styled.span<TextProps>`
  margin: 0;

  /* Apply element-specific styles */
  ${({ as = 'span' }) => {
    const element = typeof as === 'string' ? as : 'span';
    return (
      elementStyles[element as keyof typeof elementStyles] || elementStyles.span
    );
  }}

  /* Apply custom styles */
  ${({ color }) => color && `color: ${color};`}
  ${({ align }) => align && `text-align: ${align};`}
  ${({ weight }) =>
    weight &&
    css`
      font-weight: ${weight === 'normal'
        ? 400
        : weight === 'medium'
          ? 500
          : weight === 'semibold'
            ? 600
            : 700};
    `}
  ${({ italic }) => italic && `font-style: italic;`}
  ${({ underline }) => underline && `text-decoration: underline;`}
  ${({ truncate }) =>
    truncate &&
    css`
      white-space: nowrap;
      overflow: hidden;
      text-overflow: ellipsis;
      max-width: 100%;
    `}

  /* Margin styles */
  ${({ m }) => m !== undefined && `margin: ${toPx(m)};`}
  ${({ mt }) => mt !== undefined && `margin-top: ${toPx(mt)};`}
  ${({ mr }) => mr !== undefined && `margin-right: ${toPx(mr)};`}
  ${({ mb }) => mb !== undefined && `margin-bottom: ${toPx(mb)};`}
  ${({ ml }) => ml !== undefined && `margin-left: ${toPx(ml)};`}
  ${({ mx }) =>
    mx !== undefined && `margin-left: ${toPx(mx)}; margin-right: ${toPx(mx)};`}
  ${({ my }) =>
    my !== undefined && `margin-top: ${toPx(my)}; margin-bottom: ${toPx(my)};`}

  /* Padding styles */
  ${({ p }) => p !== undefined && `padding: ${toPx(p)};`}
  ${({ pt }) => pt !== undefined && `padding-top: ${toPx(pt)};`}
  ${({ pr }) => pr !== undefined && `padding-right: ${toPx(pr)};`}
  ${({ pb }) => pb !== undefined && `padding-bottom: ${toPx(pb)};`}
  ${({ pl }) => pl !== undefined && `padding-left: ${toPx(pl)};`}
  ${({ px }) =>
    px !== undefined &&
    `padding-left: ${toPx(px)}; padding-right: ${toPx(px)};`}
  ${({ py }) =>
    py !== undefined &&
    `padding-top: ${toPx(py)}; padding-bottom: ${toPx(py)};`}
`;

// The Text component
const Text = ({ as = 'span', ...props }: TextProps) => {
  return <StyledText as={as} {...props} />;
};

export default Text;
