import React from 'react';
import { createColumnHelper } from '@tanstack/react-table';
import { Action } from 'src/generated';
import { DateFormats, DateHelpers } from 'src/common/helpers';
import DueDate from 'src/pages/Project/components/Board/Action/DueDate/DueDate';
import { Flex, SelectView } from 'src/components/design-system';
import PrioritiesSelect from 'src/components/design-system/Select/PrioritiesSelect';
import StatusesSelect from 'src/components/design-system/Select/StatusesSelect';
import RowDragHandleCell from 'src/components/design-system/TableView/RowDragHandleCell';
import TableTaskNameCell from 'src/components/design-system/TableView/TableTaskNameCell/TableTaskNameCell';
import TaskAssignee from 'src/pages/Project/components/Task/TaskAssignee/TaskAssignee';
import { TableMeta } from '@tanstack/react-table';
import StoryPointsSelect from 'src/components/design-system/Select/StoryPointsSelect';
import LabelsSelect from 'src/components/design-system/Select/LabelsSelect';
import differenceBy from 'lodash/differenceBy';

interface CustomTableMeta {
  onUpdateTask: (params: {
    taskId: string;
    statusId?: string;
    storyPoints?: string;
    priority?: string;
    addLabelIds?: string[];
    removeLabelIds?: string[];
  }) => void;
  optimisticUpdate: (rowIndex: number, cellId: string, value: any) => void;
}

type TableMetaWithCustom = TableMeta<TableTask> & CustomTableMeta;

export interface TableTask extends Action {
  selection: string;
  'drag-handle': string;
  count: string;
  subtasks: Action[];
}

const columnHelper = createColumnHelper<TableTask>();

const getIds = items => items.map(({ _id }) => _id);

const renderDate = (date: Date) =>
  DateHelpers.formatDate(date, DateFormats.DayLongMonthYear);

export const taskColumns = [
  columnHelper.accessor('drag-handle', {
    header: '',
    cell: ({ row }) => (row.depth > 0 ? null : <RowDragHandleCell row={row} />),
    size: 40,
    minSize: 40,
  }),
  columnHelper.accessor('count', {
    header: '',
    cell: ({ row }) =>
      row.depth > 0 ? null : (
        <Flex $alignItems="center" $justifyContent="center" $height="100%">
          {row.index + 1}
        </Flex>
      ),
    size: 40,
    minSize: 40,
  }),
  columnHelper.accessor('selection', {
    header: ({ table }) => (
      <Flex
        $alignItems="center"
        $justifyContent="center"
        width="100%"
        $height="100%"
      >
        <input
          type="checkbox"
          checked={table.getIsAllRowsSelected()}
          onChange={() => table.toggleAllRowsSelected()}
        />
      </Flex>
    ),
    cell: ({ row }) => (
      <Flex $alignItems="center" $justifyContent="center" $height="100%">
        <input
          type="checkbox"
          checked={row.getIsSelected()}
          onChange={() => row.toggleSelected()}
        />
      </Flex>
    ),
    size: 40,
    minSize: 40,
  }),
  columnHelper.accessor('title', {
    header: () => <span>Name</span>,
    cell: props => <TableTaskNameCell {...props} />,
    size: 450,
    minSize: 450,
  }),
  columnHelper.accessor('statusId', {
    header: 'Status',
    cell: ({ table, row, cell }) => {
      const { onUpdateTask, optimisticUpdate } = table.options
        .meta as TableMetaWithCustom;
      return (
        <StatusesSelect
          selectView={SelectView.Table}
          value={row.original.statusId}
          onChange={({ value }) => {
            onUpdateTask({ taskId: row.original._id, statusId: value });
            optimisticUpdate(row.index, cell.id, { statusId: value });
          }}
        />
      );
    },
    size: 200,
  }),
  columnHelper.accessor('assignee', {
    header: 'Assignee',
    cell: ({ table, row, cell }) => {
      const { optimisticUpdate } = table.options.meta as TableMetaWithCustom;
      return (
        <Flex $alignItems="center" $padding={10} $height="100%">
          <TaskAssignee
            taskId={row.original._id}
            assignee={row.original.assignee}
            onChange={assignee => {
              optimisticUpdate(row.index, cell.id, { assignee });
            }}
          />
        </Flex>
      );
    },
    size: 200,
  }),
  columnHelper.accessor('labels', {
    header: 'Labels',
    cell: ({ table, row, cell }) => {
      const { onUpdateTask, optimisticUpdate } = table.options
        .meta as TableMetaWithCustom;
      return (
        <LabelsSelect
          selectView={SelectView.Table}
          value={row.original.labels.map(({ _id }) => _id)}
          onChange={labels => {
            const { labels: originalLabels, _id } = row.original;

            const addLabelIds = getIds(
              differenceBy(labels, originalLabels, '_id'),
            );
            const removeLabelIds = getIds(
              differenceBy(originalLabels, labels, '_id'),
            );

            onUpdateTask({
              taskId: _id,
              addLabelIds: addLabelIds?.length ? addLabelIds : undefined,
              removeLabelIds: removeLabelIds?.length
                ? removeLabelIds
                : undefined,
            });
            optimisticUpdate(row.index, cell.id, { labels });
          }}
        />
      );
    },
    size: 200,
    minSize: 200,
    maxSize: 200,
  }),
  columnHelper.accessor('dueDate', {
    header: () => 'Due Date',
    cell: ({ table, row, cell }) => {
      const { optimisticUpdate } = table.options.meta as TableMetaWithCustom;

      return (
        <Flex $alignItems="center" $padding={10} $height="100%">
          <DueDate
            task={row.original}
            onChange={({ startDate, dueDate }) => {
              optimisticUpdate(row.index, cell.id, { startDate, dueDate });
            }}
          />
        </Flex>
      );
    },
    size: 250,
  }),
  columnHelper.accessor('priority', {
    header: () => 'Priority',
    cell: ({ table, row, cell }) => {
      const { onUpdateTask, optimisticUpdate } = table.options
        .meta as TableMetaWithCustom;
      return (
        <PrioritiesSelect
          taskPriority={row.original.priority!}
          onChange={({ value }) => {
            onUpdateTask({ taskId: row.original._id, priority: value });
            optimisticUpdate(row.index, cell.id, { priority: value });
          }}
        />
      );
    },
    size: 200,
  }),
  columnHelper.accessor('storyPoints', {
    header: () => 'Story Points',
    cell: ({ table, row, cell }) => {
      const { onUpdateTask, optimisticUpdate } = table.options
        .meta as TableMetaWithCustom;

      return (
        <StoryPointsSelect
          storyPoints={row.original.storyPoints}
          onChange={({ value }) => {
            if (value === null) return;
            onUpdateTask({ taskId: row.original._id, storyPoints: value });
            optimisticUpdate(row.index, cell.id, { storyPoints: value });
          }}
        />
      );
    },
    size: 200,
  }),

  columnHelper.accessor('createdAt', {
    header: () => 'Created On',
    cell: ({ renderValue }) => (
      <Flex $alignItems="center" $height="100%" $padding={10}>
        {renderDate(renderValue() as unknown as Date)}
      </Flex>
    ),
    size: 200,
  }),
  columnHelper.accessor('updatedAt', {
    header: () => 'Updated At',
    cell: ({ renderValue }) => (
      <Flex $alignItems="center" $height="100%" $padding={10}>
        {renderDate(renderValue() as unknown as Date)}
      </Flex>
    ),
    size: 200,
  }),
  columnHelper.accessor('completedAt', {
    header: () => 'Completed At',
    cell: ({ renderValue }) => (
      <Flex $alignItems="center" $height="100%" $padding={10}>
        {renderDate(renderValue() as unknown as Date)}
      </Flex>
    ),
    size: 200,
  }),
];
