import React, { useMemo } from 'react';
import { useParams } from 'react-router-dom';
import { TableView } from 'src/components/design-system';
import { useUser } from 'src/store';
import { useUserFilter } from 'src/hooks/custom/useUserFilter';
import {
  NOT_EDITABLE_COLUMNS,
  DEFAULT_FILTERS,
} from 'src/widgets/Drawers/ProjectFilterDrawer/constants';

const Table = () => {
  const user = useUser();
  const { workspaceId } = user;
  const { id: projectId } = useParams<{ id: string }>();
  const { userFilter } = useUserFilter();

  const query = useMemo(
    () => ({
      filter: {
        workspaceId,
        projectId,
        hideCompleted: userFilter?.hideCompleted,
        ...(userFilter?.priorities && { priorities: userFilter.priorities }),
        ...(userFilter?.labels &&
          userFilter?.labels?.length > 0 && { labels: userFilter.labels }),
        ...(userFilter?.statuses &&
          userFilter?.statuses?.length > 0 && {
            statuses: userFilter.statuses,
          }),
      },
    }),
    [
      workspaceId,
      projectId,
      userFilter?.hideCompleted,
      userFilter?.priorities,
      userFilter?.labels,
      userFilter?.statuses,
    ],
  );

  const tableColumns = useMemo(() => {
    return userFilter?.columns
      ? [...userFilter.columns, ...NOT_EDITABLE_COLUMNS]
      : [...DEFAULT_FILTERS.columns, ...NOT_EDITABLE_COLUMNS];
  }, [userFilter?.columns]);

  return <TableView columns={tableColumns} query={query} />;
};

export default Table;
