import { gql } from '@apollo/client';

import { TASK_FRAGMENT } from '../fragments';

export const GET_TASK_DASHBOARD = gql`
  query TaskCounts($projectId: ID!, $workspaceId: ID!) {
    taskCounts(projectId: $projectId) {
      incompleteTasks
      completedTasks
    }
    taskByStatusWithCount(projectId: $projectId) {
      title
      tasksCount
    }
    dashboardTasksByPeriod(projectId: $projectId, workspaceId: $workspaceId) {
      day
      count
      tasks {
        _id
        title
      }
    }
  }
`;

export const GET_PROJECT_TASKS_COUNT = gql`
  query ProjectTasksCount($projectId: ID!) {
    taskCounts(projectId: $projectId) {
      incompleteTasks
      completedTasks
      deletedTasks
      archivedTasks
    }
  }
`;

gql`
  query Tasks(
    $filter: TaskFilter
    $orderBy: TaskOrderBy
    $pagination: PaginationInput
  ) {
    tasks(filter: $filter, orderBy: $orderBy, pagination: $pagination) {
      items {
        ...TaskFragment
      }
      totalCount
      hasMore
    }
  }
  ${TASK_FRAGMENT}
`;

gql`
  query TrashBinTasks($workspaceId: ID!) {
    trashBinTasks(workspaceId: $workspaceId) {
      _id
      title
      deletedAt
      deletedBy {
        _id
        fullName
        profilePicture
      }
      projectId {
        _id
        title
        color
      }
    }
  }
`;

export const GET_PROJECT_TASKS_FOR_BOARD_VIEW = gql`
  query KanbanTasks(
    $projectId: ID!
    $priority: String
    $labels: [ID!]
    $statusIds: [ID!]
    $dueDate: String
    $assignees: [ID!]
  ) {
    kanbanTasks(
      projectId: $projectId
      priority: $priority
      labels: $labels
      statusIds: $statusIds
      dueDate: $dueDate
      assignees: $assignees
    ) {
      _id
      title
      tasksCount
      color
      actions {
        ...TaskFragment
      }
    }
  }
  ${TASK_FRAGMENT}
`;

export const GET_ACTION = gql`
  query GetAction($id: ID!) {
    action(id: $id) {
      ...TaskFragment
    }
  }
  ${TASK_FRAGMENT}
`;

export const TASK_HISTORY = gql`
  query TaskHistory($taskId: ID!) {
    taskHistory(taskId: $taskId) {
      _id
      body
      createdAt
      taskId
    }
  }
`;

gql`
  subscription onCreateTaskHistory {
    onCreateTaskHistory {
      _id
      body
      taskId
      createdAt
    }
  }
`;
