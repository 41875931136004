import React, { useMemo } from 'react';
import { Select } from 'src/components/design-system';
import StoryPointsSingleValue from 'src/components/design-system/Select/components/storyPoints/StoryPointsSingleValue';
import StoryPointsOption from 'src/components/design-system/Select/components/storyPoints/StoryPointsOption';
import { storyPointsList } from 'src/pages/Project/components/Board/Action/constants';

type StatusesSelectProps = {
  storyPoints?: number | null;
  onChange: (value: any) => void;
};

const StoryPointsSelect = ({ storyPoints, onChange }: StatusesSelectProps) => {
  const storyPoint = useMemo(() => {
    const storyPoint = storyPointsList.find(
      ({ value }) => value && value === storyPoints,
    );
    return storyPoint
      ? {
          _id: storyPoint.value,
          value: storyPoint.value,
          label: storyPoint.label,
        }
      : null;
  }, [storyPoints]);

  return (
    <div>
      <Select
        menuPortalTarget={document.body}
        isSearchable={false}
        options={storyPointsList}
        value={storyPoint?.value !== null ? storyPoint : null}
        styles={{
          control: base => ({
            ...base,
            background: 'transparent',
            border: 'none',
            minHeight: '48px',
            height: '48px',
          }),
        }}
        onChange={onChange}
        components={{
          SingleValue: StoryPointsSingleValue,
          Option: StoryPointsOption,
          ClearIndicator: undefined,
          DropdownIndicator: undefined,
        }}
      />
    </div>
  );
};

export default StoryPointsSelect;
