import styled from 'styled-components';

export const UserPreviewContainer = styled.div`
  width: 300px;
  overflow: hidden;
  color: ${({ theme }) => theme.text.primary};
  background: ${({ theme }) => theme.background.primary};
  border: 1px solid ${({ theme }) => theme.border.color.primary};
`;
export const UserPreviewBlock = styled.div`
  padding: 10px;
`;

export const UserPreviewImage = styled.div`
  height: 300px;
  background: #58a182;

  img {
    width: 100%;
    height: 100%;
    max-width: 100%;
    max-height: 100%;
    object-fit: cover;
  }
`;

export const UserPreviewFooter = styled(UserPreviewBlock)`
  border-top: 1px solid ${({ theme }) => theme.border.color.primary};
`;
