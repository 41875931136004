import styled from 'styled-components';
import { Button } from 'src/components/design-system';

export const SearchButton = styled(Button)`
  width: 100%;
  max-width: 500px;
  padding: 10px;
  justify-content: flex-start;
  position: relative;
  &:hover {
    background: ${({ theme }) => theme.background.hover};
  }
`;
export const SearchButtonKey = styled.div`
  position: absolute;
  right: 5px;
  top: 5px;
  padding: 5px;
  border-radius: ${({ theme }) => theme.border.radius.sm};
  background: ${({ theme }) => theme.background.hover};
  color: ${({ theme }) => theme.color.grey600};
`;

export const InputWrapper = styled.div`
  padding: 10px;
  border-radius: 12px;
  input {
    font-weight: 500;
    font-size: 17px;
  }
`;

export const SearchResults = styled.div`
  border-top: 1px solid ${({ theme }) => theme.border.color.primary};
  overflow-y: auto;
  height: 500px;
  padding: 12px;
`;

export const GridContainer = styled.div`
  display: grid;
  grid-template-columns: repeat(4, 1fr);
  gap: 10px;
`;

export const LoadingWrapper = styled.div`
  width: 50px;
  height: 20px;
`;
