import { ApolloClient, InMemoryCache, split } from '@apollo/client';
import { ApolloLink } from '@apollo/client';
import { setContext } from '@apollo/client/link/context';
import { onError } from '@apollo/client/link/error';
import { GraphQLWsLink } from '@apollo/client/link/subscriptions';
import { getMainDefinition } from '@apollo/client/utilities';
import { nullable } from './helpers/apollo';
import { createUploadLink } from 'apollo-upload-client';
import Auth from './helpers/auth';
import customFetch from './helpers/customFetch';
import { createClient } from 'graphql-ws';
import { useAppStore } from 'src/store';
import { DevHelpers } from 'src/common/helpers';

const errorLink = onError(({ graphQLErrors, networkError }) => {
  if (graphQLErrors) {
    graphQLErrors.forEach(({ message, locations, path, extensions }) => {
      console.log(
        `[GraphQL error]: Message: ${message}, Location: ${locations}, Path: ${path}`,
      );
      if (extensions.code === 'UNAUTHENTICATED' && Auth.getToken()) {
        Auth.logout();
        window.location.href = '/sign-in';
      }
    });
  }
  if (networkError) {
    console.log(`[Network Error]: ${networkError}`);
  }
});

const createWsLink = () =>
  new GraphQLWsLink(
    createClient({
      url: process.env.REACT_APP_WS_URL!,
      retryAttempts: Infinity,
      lazy: true,
      lazyCloseTimeout: 30000,
      keepAlive: 10000,
      shouldRetry: () => true,
      retryWait: async () => {
        await new Promise(resolve => setTimeout(resolve, 1000));
      },
      connectionParams: async () => {
        const token = localStorage.getItem('token');
        return {
          Authorization: `Bearer ${token}`,
          reconnect: true,
        };
      },
      on: {
        connected: (socket, payload, wasRetry) => {
          useAppStore
            .getState()
            .addLog(
              `Successfully connected to the server ${wasRetry ? 'after a retry' : ''} in ${new Date().toLocaleTimeString()} ${payload ? `with payload ${payload}` : ''}`,
            );
        },
        closed: options => {
          useAppStore
            .getState()
            .addLog(
              `Connection closed in ${new Date().toLocaleTimeString()} with error: ${JSON.stringify(options, null, 2)}`,
            );
        },
      },
    }),
  );

let wsLink = createWsLink();

const link = createUploadLink({
  uri: process.env.REACT_APP_API_URL,
  fetch: customFetch,
});

const authLink = setContext(
  (_: any, { headers }: { headers: Record<string, string> }) => {
    const token = localStorage.getItem('token');

    return {
      headers: {
        ...headers,
        authorization: token ? `Bearer ${token}` : '',
      },
    };
  },
);

const splitLink = split(
  ({ query }) => {
    const definition = getMainDefinition(query);
    return (
      definition.kind === 'OperationDefinition' &&
      definition.operation === 'subscription'
    );
  },
  wsLink,
  authLink.concat(link),
);

// const logLink = new ApolloLink((operation, forward) => {
//   if (!DevHelpers.isDev()) {
//     return forward(operation);
//   }
//
//   const startTime = new Date().toISOString();
//   console.groupCollapsed(
//     `%c[GraphQL Request]: ${operation.operationName} | ${startTime}`,
//     'color: #4CAF50; font-weight: bold;',
//   );
//   console.log('%c📤 Variables:', 'color: #03A9F4;', operation.variables);
//   return forward(operation).map(response => {
//     const endTime = new Date().toISOString();
//     console.log('%c✅ Response:', 'color: #4CAF50;', response, endTime);
//     console.groupEnd(); // Close the group after logging the response
//     return response;
//   });
// });

const client = new ApolloClient({
  link: ApolloLink.from([errorLink, splitLink]),
  connectToDevTools: DevHelpers.isDev(),
  cache: new InMemoryCache({
    typePolicies: {
      Status: {
        fields: {
          title: nullable(),
          color: nullable(),
          taskOrder: nullable(),
          actions: nullable(),
        },
      },
      Action: {
        fields: {
          title: nullable(),
          description: nullable(),
          createdAt: nullable(),
          dueDate: nullable(),
          assignee: nullable(),
          startDate: nullable(),
          attachments: nullable(),
          priority: nullable(),
          storyPoints: nullable(),
          parentId: nullable(),
          totalSubtasksCount: nullable(),
          updatedAt: nullable(),
          completedAt: nullable(),
          isUrgent: nullable(),
          isArchived: nullable(),
          labels: nullable(),
          statusId: nullable(),
          rank: nullable(),
          taskAdvancedFields: nullable(),
        },
      },
    },
  }),
});

// Detect app visibility changes
document.addEventListener('visibilitychange', () => {
  if (!document.hidden) {
    wsLink.client.terminate();
    useAppStore
      .getState()
      .addLog(
        `App is visible, reconnecting to the server in ${new Date().toLocaleTimeString()}`,
      );

    wsLink = createWsLink();
  }
});

export default client;
