import React from 'react';
import { Box, Text } from 'src/components/design-system';
import StatusesSelect from 'src/components/design-system/Select/StatusesSelect';

const StatusesFilter = props => {
  const { label, value, onChange } = props;
  return (
    <Box>
      <Text weight="bold">{label}</Text>
      <StatusesSelect
        isMulti
        value={value}
        onChange={statuses => {
          const ids = statuses.map(({ _id }) => _id);
          onChange(ids);
        }}
      />
    </Box>
  );
};

export default StatusesFilter;
