import { gql } from '@apollo/client';
import * as Apollo from '@apollo/client';
export type Maybe<T> = T | null;
export type InputMaybe<T> = Maybe<T>;
export type Exact<T extends { [key: string]: unknown }> = { [K in keyof T]: T[K] };
export type MakeOptional<T, K extends keyof T> = Omit<T, K> & { [SubKey in K]?: Maybe<T[SubKey]> };
export type MakeMaybe<T, K extends keyof T> = Omit<T, K> & { [SubKey in K]: Maybe<T[SubKey]> };
const defaultOptions = {} as const;
/** All built-in and custom scalars, mapped to their actual values */
export type Scalars = {
  ID: string;
  String: string;
  Boolean: boolean;
  Int: number;
  Float: number;
  Date: any;
  Upload: any;
};

export type Action = {
  __typename?: 'Action';
  _id: Scalars['ID'];
  assignee?: Maybe<User>;
  attachments?: Maybe<Array<File>>;
  completedAt?: Maybe<Scalars['Date']>;
  createdAt: Scalars['Date'];
  deletedAt?: Maybe<Scalars['Date']>;
  deletedBy?: Maybe<User>;
  description?: Maybe<Scalars['String']>;
  dueDate?: Maybe<Scalars['Date']>;
  isArchived: Scalars['Boolean'];
  isDeleted: Scalars['Boolean'];
  isUrgent?: Maybe<Scalars['Boolean']>;
  labels: Array<Label>;
  parentId?: Maybe<TaskParent>;
  priority?: Maybe<Scalars['String']>;
  projectId?: Maybe<Project>;
  rank: Scalars['Float'];
  startDate?: Maybe<Scalars['Date']>;
  statusId: Scalars['String'];
  storyPoints?: Maybe<Scalars['Float']>;
  subtasks?: Maybe<Array<Action>>;
  taskAdvancedFields?: Maybe<Array<TaskAdvancedField>>;
  title: Scalars['String'];
  totalSubtasksCount?: Maybe<Scalars['Float']>;
  totalTrackingTime?: Maybe<Scalars['Float']>;
  updatedAt: Scalars['Date'];
  workspaceId: Scalars['ID'];
};

export type AddAttachmentsToTaskInput = {
  attachments?: InputMaybe<Array<Scalars['Upload']>>;
  projectId: Scalars['ID'];
  taskId: Scalars['ID'];
};

export type AddCustomFieldToProjectInput = {
  customFieldId: Scalars['ID'];
  isChecked: Scalars['Boolean'];
  projectId: Scalars['ID'];
};

export type AuthResponse = {
  __typename?: 'AuthResponse';
  token: Scalars['String'];
  user: User;
};

export type Book = {
  __typename?: 'Book';
  _id: Scalars['ID'];
  chapters: Array<Chapter>;
  createdAt: Scalars['Date'];
  poster: Scalars['String'];
  title: Scalars['String'];
  updatedAt: Scalars['Date'];
  workspaceId: Scalars['ID'];
};

export type CanvasBoard = {
  __typename?: 'CanvasBoard';
  _id: Scalars['ID'];
  createdAt: Scalars['Date'];
  createdBy?: Maybe<User>;
  isDeleted: Scalars['Boolean'];
  previewImage?: Maybe<Scalars['String']>;
  title: Scalars['String'];
  updatedAt: Scalars['Date'];
  workspaceId: Scalars['ID'];
};

export type Chapter = {
  __typename?: 'Chapter';
  id: Scalars['ID'];
  time: Scalars['Float'];
  title: Scalars['String'];
  url: Scalars['String'];
};

export type Comment = {
  __typename?: 'Comment';
  _id: Scalars['ID'];
  createdAt: Scalars['Date'];
  isEdited?: Maybe<Scalars['Boolean']>;
  reactions: Array<Reaction>;
  sticker?: Maybe<Scalars['String']>;
  taskId: Scalars['String'];
  text?: Maybe<Scalars['String']>;
  type: CommentTypeEnum;
  updatedAt: Scalars['Date'];
  user: User;
};

/** Comment type enum */
export enum CommentTypeEnum {
  AnimatedSticker = 'AnimatedSticker',
  Sticker = 'Sticker',
  Text = 'Text'
}

export type CreateCommentInput = {
  projectId: Scalars['ID'];
  sticker?: InputMaybe<Scalars['String']>;
  taskId: Scalars['ID'];
  text?: InputMaybe<Scalars['String']>;
  type: CommentTypeEnum;
};

export type CreateLabelInput = {
  color: Scalars['String'];
  projectId: Scalars['ID'];
  title: Scalars['String'];
  workspaceId: Scalars['ID'];
};

export type CreateStatusInput = {
  projectId: Scalars['ID'];
  title: Scalars['String'];
  workspaceId: Scalars['ID'];
};

export type CreateTaskCustomFieldInput = {
  customFieldId: Scalars['ID'];
  projectId: Scalars['ID'];
  taskId: Scalars['ID'];
  value: Scalars['String'];
};

export type CreateTimeTrackingInput = {
  actionId: Scalars['String'];
  operation: TimeTrackingOperation;
  timeTrackingId?: InputMaybe<Scalars['String']>;
};

export type CustomField = {
  __typename?: 'CustomField';
  _id: Scalars['ID'];
  createdAt: Scalars['Date'];
  projectIds: Array<Scalars['ID']>;
  title: Scalars['String'];
  type: Scalars['String'];
  updatedAt: Scalars['Date'];
  workspaceId: Scalars['ID'];
};

/** Dashboard task period enum */
export enum DashboardTaskPeriodEnum {
  HalfYear = 'HalfYear',
  Month = 'Month',
  Quarter = 'Quarter',
  Year = 'Year'
}

export type DashboardTasksOutput = {
  __typename?: 'DashboardTasksOutput';
  count?: Maybe<Scalars['String']>;
  day?: Maybe<Scalars['String']>;
  tasks?: Maybe<Array<Action>>;
};

export type DeleteStatusInput = {
  statusId: Scalars['ID'];
};

export type File = {
  __typename?: 'File';
  _id: Scalars['ID'];
  createdAt: Scalars['Date'];
  fileName: Scalars['String'];
  projectId: Scalars['String'];
  size: Scalars['String'];
  type: Scalars['String'];
  updatedAt: Scalars['Date'];
  urls?: Maybe<FileUrls>;
};

export type FileUrls = {
  __typename?: 'FileUrls';
  original: Scalars['String'];
  thumbnail: Scalars['String'];
  thumbnailPreview?: Maybe<Scalars['String']>;
};

export type KanbanTasks = {
  __typename?: 'KanbanTasks';
  _id: Scalars['String'];
  actions: Array<Action>;
  color?: Maybe<Scalars['String']>;
  tasksCount: Scalars['Int'];
  title: Scalars['String'];
};

export type Label = {
  __typename?: 'Label';
  _id: Scalars['ID'];
  color: Scalars['String'];
  createdAt: Scalars['Date'];
  createdBy: User;
  projectId: Scalars['ID'];
  title: Scalars['String'];
  updatedAt: Scalars['Date'];
  workspaceId: Scalars['ID'];
};

export type LoginInput = {
  email: Scalars['String'];
  password: Scalars['String'];
};

export type Mutation = {
  __typename?: 'Mutation';
  acceptInviteToProject: AuthResponse;
  addAttachmentsToTask: Action;
  addReaction: Comment;
  applyTaskTemplate: Action;
  createCanvasBoard: CanvasBoard;
  createComment: Comment;
  createCustomField: CustomField;
  createLabel: Label;
  createProject: Project;
  createStatus: Status;
  createTask: Action;
  createTaskAdvancedField: TaskAdvancedField;
  createTaskCustomField: TaskCustomField;
  createTaskTemplate: TaskTemplate;
  createUserFilter: UserFilter;
  createWorkspace: Workspace;
  deleteCanvasBoard: CanvasBoard;
  deleteComment: Comment;
  deleteLabel: Label;
  deleteProject: Scalars['Boolean'];
  deleteStatus: Status;
  deleteTaskAdvancedField: TaskAdvancedField;
  deleteTaskTemplate: TaskTemplate;
  deleteTasks: Array<Action>;
  duplicateTask: Action;
  googleLogin: AuthResponse;
  inviteProjectMember: Scalars['Boolean'];
  /** Login user with email and password */
  login: AuthResponse;
  readAllNotification: Scalars['Boolean'];
  readNotification: Notification;
  removeReaction: Comment;
  /** Create User and login user */
  signup: AuthResponse;
  toggleCustomFieldToProject: CustomField;
  updateCanvasBoard: CanvasBoard;
  updateComment: Comment;
  updateLabel: Label;
  updateProject: Project;
  updateStatus: Status;
  updateTask: Action;
  updateTaskAdvancedField: TaskAdvancedField;
  updateTimeTracking: TimeTracking;
  updateUser: User;
  updateUserFilter: UserFilter;
  updateUserListening: User;
  updateUserMinimizedTasks: User;
  updateUserProfile: User;
  updateWord: Word;
  uploadBook: Book;
  uploadFile: Scalars['String'];
};


export type MutationAcceptInviteToProjectArgs = {
  firstName: Scalars['String'];
  inviteToken: Scalars['String'];
  lastName: Scalars['String'];
  password: Scalars['String'];
};


export type MutationAddAttachmentsToTaskArgs = {
  input: AddAttachmentsToTaskInput;
};


export type MutationAddReactionArgs = {
  input: ReactionInput;
};


export type MutationApplyTaskTemplateArgs = {
  taskId: Scalars['ID'];
  templateId: Scalars['ID'];
};


export type MutationCreateCanvasBoardArgs = {
  previewImage?: InputMaybe<Scalars['String']>;
  title: Scalars['String'];
  workspaceId: Scalars['ID'];
};


export type MutationCreateCommentArgs = {
  input: CreateCommentInput;
};


export type MutationCreateCustomFieldArgs = {
  title: Scalars['String'];
  type: Scalars['String'];
  workspaceId: Scalars['ID'];
};


export type MutationCreateLabelArgs = {
  input: CreateLabelInput;
};


export type MutationCreateProjectArgs = {
  input: ProjectInput;
};


export type MutationCreateStatusArgs = {
  input: CreateStatusInput;
};


export type MutationCreateTaskArgs = {
  dueDate?: InputMaybe<Scalars['Date']>;
  parentId?: InputMaybe<Scalars['ID']>;
  projectId: Scalars['ID'];
  rank?: InputMaybe<Scalars['Float']>;
  statusId?: InputMaybe<Scalars['ID']>;
  title: Scalars['String'];
  workspaceId: Scalars['ID'];
};


export type MutationCreateTaskAdvancedFieldArgs = {
  taskId: Scalars['ID'];
  title: Scalars['String'];
  type: TaskAdvancedFieldTypeEnum;
};


export type MutationCreateTaskCustomFieldArgs = {
  input: CreateTaskCustomFieldInput;
};


export type MutationCreateTaskTemplateArgs = {
  assignee?: InputMaybe<Scalars['String']>;
  description?: InputMaybe<Scalars['String']>;
  dueDate?: InputMaybe<Scalars['Date']>;
  isUrgent?: InputMaybe<Scalars['Boolean']>;
  priority?: InputMaybe<Scalars['String']>;
  startDate?: InputMaybe<Scalars['Date']>;
  storyPoints?: InputMaybe<Scalars['Float']>;
  title?: InputMaybe<Scalars['String']>;
  workspaceId?: InputMaybe<Scalars['ID']>;
};


export type MutationCreateUserFilterArgs = {
  filter?: InputMaybe<UserFilterInput>;
};


export type MutationDeleteCanvasBoardArgs = {
  boardId: Scalars['ID'];
  workspaceId: Scalars['ID'];
};


export type MutationDeleteCommentArgs = {
  id: Scalars['ID'];
};


export type MutationDeleteLabelArgs = {
  id: Scalars['ID'];
};


export type MutationDeleteProjectArgs = {
  projectId: Scalars['ID'];
};


export type MutationDeleteStatusArgs = {
  input: DeleteStatusInput;
};


export type MutationDeleteTaskAdvancedFieldArgs = {
  fieldId: Scalars['ID'];
};


export type MutationDeleteTaskTemplateArgs = {
  templateId: Scalars['ID'];
};


export type MutationDeleteTasksArgs = {
  projectId: Scalars['ID'];
  taskIds: Array<Scalars['ID']>;
};


export type MutationDuplicateTaskArgs = {
  taskId: Scalars['ID'];
};


export type MutationGoogleLoginArgs = {
  token: Scalars['String'];
};


export type MutationInviteProjectMemberArgs = {
  email: Scalars['String'];
  projectId: Scalars['ID'];
  workspaceId: Scalars['ID'];
};


export type MutationLoginArgs = {
  user: LoginInput;
};


export type MutationReadNotificationArgs = {
  notificationId: Scalars['ID'];
};


export type MutationRemoveReactionArgs = {
  input: ReactionInput;
};


export type MutationSignupArgs = {
  input: SignUpInput;
};


export type MutationToggleCustomFieldToProjectArgs = {
  input: AddCustomFieldToProjectInput;
};


export type MutationUpdateCanvasBoardArgs = {
  boardId: Scalars['ID'];
  previewImage?: InputMaybe<Scalars['String']>;
  title?: InputMaybe<Scalars['String']>;
};


export type MutationUpdateCommentArgs = {
  input: UpdateCommentInput;
};


export type MutationUpdateLabelArgs = {
  id: Scalars['ID'];
  input: UpdateLabelInput;
};


export type MutationUpdateProjectArgs = {
  boardsSortList?: InputMaybe<Array<Scalars['ID']>>;
  color?: InputMaybe<Scalars['String']>;
  projectId: Scalars['ID'];
  status?: InputMaybe<ProjectStatusEnum>;
  title?: InputMaybe<Scalars['String']>;
};


export type MutationUpdateStatusArgs = {
  color?: InputMaybe<Scalars['String']>;
  statusId: Scalars['ID'];
  taskOrder?: InputMaybe<Array<Scalars['ID']>>;
  title?: InputMaybe<Scalars['String']>;
};


export type MutationUpdateTaskArgs = {
  updateTaskInput: UpdateTaskInput;
};


export type MutationUpdateTaskAdvancedFieldArgs = {
  fieldId: Scalars['ID'];
  value: Scalars['String'];
};


export type MutationUpdateTimeTrackingArgs = {
  input: CreateTimeTrackingInput;
};


export type MutationUpdateUserArgs = {
  googleId?: InputMaybe<Scalars['String']>;
  projectsOrder?: InputMaybe<Array<Scalars['ID']>>;
  pushNotificationToken?: InputMaybe<Scalars['String']>;
};


export type MutationUpdateUserFilterArgs = {
  filter?: InputMaybe<UserFilterInput>;
};


export type MutationUpdateUserListeningArgs = {
  input: UpdateUserListeningInput;
};


export type MutationUpdateUserMinimizedTasksArgs = {
  addMinimizedTaskId?: InputMaybe<Scalars['ID']>;
  removeMinimizedTaskId?: InputMaybe<Scalars['ID']>;
};


export type MutationUpdateUserProfileArgs = {
  input: UpdateUserProfileInput;
};


export type MutationUpdateWordArgs = {
  isLearned?: InputMaybe<Scalars['Boolean']>;
  isSkip?: InputMaybe<Scalars['Boolean']>;
  wordId: Scalars['ID'];
};


export type MutationUploadBookArgs = {
  input: UploadBookInput;
};


export type MutationUploadFileArgs = {
  input: UploadFilesInput;
};

export type Notification = {
  __typename?: 'Notification';
  _id: Scalars['ID'];
  createdAt: Scalars['Date'];
  isPrivate: Scalars['Boolean'];
  isRead: Scalars['Boolean'];
  message?: Maybe<Scalars['String']>;
  projectId?: Maybe<Scalars['String']>;
  title: Scalars['String'];
  type: NotificationTypeEnum;
  updatedAt: Scalars['Date'];
  user: User;
  userId: Scalars['ID'];
  workspaceId: Scalars['ID'];
};

/** Notification type enum */
export enum NotificationTypeEnum {
  DueDate = 'DueDate',
  ProjectOverview = 'ProjectOverview',
  TaskAssigned = 'TaskAssigned',
  TaskCommented = 'TaskCommented',
  TaskCreated = 'TaskCreated',
  TaskDeleted = 'TaskDeleted',
  TaskUpdated = 'TaskUpdated'
}

export enum OrderDirection {
  Asc = 'Asc',
  Desc = 'Desc'
}

export type PaginationInput = {
  limit?: InputMaybe<Scalars['Float']>;
  skip?: InputMaybe<Scalars['Float']>;
};

export type Project = {
  __typename?: 'Project';
  _id: Scalars['ID'];
  boardsSortList?: Maybe<Array<Scalars['String']>>;
  color: Scalars['String'];
  completedStatusId: Scalars['ID'];
  createdAt: Scalars['Date'];
  description?: Maybe<Scalars['String']>;
  isDeleted: Scalars['Boolean'];
  members?: Maybe<Array<User>>;
  status?: Maybe<ProjectStatusEnum>;
  taskCustomFields?: Maybe<Array<CustomField>>;
  title: Scalars['String'];
  unstagedStatusId: Scalars['ID'];
  updatedAt: Scalars['Date'];
};

export type ProjectInput = {
  boardsSortList?: InputMaybe<Array<Scalars['ID']>>;
  color: Scalars['String'];
  title: Scalars['String'];
};

/** Status of project */
export enum ProjectStatusEnum {
  Archived = 'Archived',
  AtRisk = 'AtRisk',
  Completed = 'Completed',
  OffTrack = 'OffTrack',
  OnHold = 'OnHold',
  OnTrack = 'OnTrack'
}

export type ProjectTasksCount = {
  __typename?: 'ProjectTasksCount';
  archivedTasks?: Maybe<Scalars['Int']>;
  completedTasks?: Maybe<Scalars['Int']>;
  deletedTasks?: Maybe<Scalars['Int']>;
  incompleteTasks?: Maybe<Scalars['Int']>;
};

export type ProjectsDashboard = {
  __typename?: 'ProjectsDashboard';
  _id: Scalars['ID'];
  boardsSortList?: Maybe<Array<Scalars['String']>>;
  color: Scalars['String'];
  commentsCount?: Maybe<Scalars['Int']>;
  completedStatusId: Scalars['ID'];
  createdAt: Scalars['Date'];
  description?: Maybe<Scalars['String']>;
  filesCount?: Maybe<Scalars['Int']>;
  isDeleted: Scalars['Boolean'];
  labels?: Maybe<Array<Label>>;
  labelsCount?: Maybe<Scalars['Int']>;
  labelsList?: Maybe<Array<Scalars['String']>>;
  members?: Maybe<Array<User>>;
  status?: Maybe<ProjectStatusEnum>;
  statuses?: Maybe<Array<Status>>;
  statusesCount?: Maybe<Scalars['Int']>;
  statusesList?: Maybe<Array<Scalars['String']>>;
  taskCount?: Maybe<Scalars['Int']>;
  taskCustomFields?: Maybe<Array<CustomField>>;
  title: Scalars['String'];
  unstagedStatusId: Scalars['ID'];
  updatedAt: Scalars['Date'];
};

export type Query = {
  __typename?: 'Query';
  action: Action;
  books: Array<Book>;
  canvasBoards: Array<CanvasBoard>;
  comments: Array<Comment>;
  customFields: Array<CustomField>;
  dashboardTasksByPeriod: Array<DashboardTasksOutput>;
  files: Array<File>;
  kanbanTasks: Array<KanbanTasks>;
  labels: Array<Label>;
  me: User;
  notifications: Array<Notification>;
  project: Project;
  projects: Array<Project>;
  projectsDashboard: Array<ProjectsDashboard>;
  randomWord: Word;
  statuses: Array<Status>;
  taskByStatusWithCount: Array<KanbanTasks>;
  taskCounts: ProjectTasksCount;
  taskCustomFields: Array<TaskCustomField>;
  taskDescriptionHistory: Array<TaskDescriptionHistory>;
  taskHistory: Array<TaskHistory>;
  taskTemplates: Array<TaskTemplate>;
  tasks: TasksResponse;
  timeTracking: Array<TimeTracking>;
  trashBinTasks: Array<Action>;
  userFilter?: Maybe<UserFilter>;
  users: Array<User>;
  words: Array<Word>;
};


export type QueryActionArgs = {
  id: Scalars['ID'];
};


export type QueryBooksArgs = {
  workspaceId: Scalars['ID'];
};


export type QueryCanvasBoardsArgs = {
  workspaceId: Scalars['ID'];
};


export type QueryCommentsArgs = {
  taskId: Scalars['ID'];
};


export type QueryCustomFieldsArgs = {
  projectIds?: InputMaybe<Array<Scalars['ID']>>;
  workspaceId: Scalars['ID'];
};


export type QueryDashboardTasksByPeriodArgs = {
  period?: InputMaybe<DashboardTaskPeriodEnum>;
  projectId?: InputMaybe<Scalars['ID']>;
  workspaceId: Scalars['ID'];
};


export type QueryFilesArgs = {
  projectId: Scalars['ID'];
};


export type QueryKanbanTasksArgs = {
  assignees?: InputMaybe<Array<Scalars['ID']>>;
  dueDate?: InputMaybe<Scalars['String']>;
  labels?: InputMaybe<Array<Scalars['ID']>>;
  priority?: InputMaybe<Scalars['String']>;
  projectId: Scalars['ID'];
  statusIds?: InputMaybe<Array<Scalars['ID']>>;
};


export type QueryLabelsArgs = {
  limit?: InputMaybe<Scalars['Int']>;
  projectId: Scalars['ID'];
  skip?: InputMaybe<Scalars['Int']>;
  workspaceId: Scalars['ID'];
};


export type QueryNotificationsArgs = {
  isPrivate?: InputMaybe<Scalars['Boolean']>;
  limit?: InputMaybe<Scalars['Int']>;
  projectId?: InputMaybe<Scalars['ID']>;
  skip?: InputMaybe<Scalars['Int']>;
  type?: InputMaybe<NotificationTypeEnum>;
  userId?: InputMaybe<Scalars['ID']>;
  workspaceId: Scalars['ID'];
};


export type QueryProjectArgs = {
  projectId: Scalars['ID'];
};


export type QueryStatusesArgs = {
  projectId: Scalars['ID'];
  statusIds?: InputMaybe<Array<Scalars['ID']>>;
  title?: InputMaybe<Scalars['String']>;
};


export type QueryTaskByStatusWithCountArgs = {
  projectId: Scalars['ID'];
};


export type QueryTaskCountsArgs = {
  projectId: Scalars['ID'];
};


export type QueryTaskCustomFieldsArgs = {
  projectId: Scalars['ID'];
  taskId: Scalars['ID'];
};


export type QueryTaskDescriptionHistoryArgs = {
  limit?: InputMaybe<Scalars['Int']>;
  skip?: InputMaybe<Scalars['Int']>;
  taskId: Scalars['ID'];
};


export type QueryTaskHistoryArgs = {
  taskId: Scalars['ID'];
};


export type QueryTaskTemplatesArgs = {
  limit?: InputMaybe<Scalars['Int']>;
  skip?: InputMaybe<Scalars['Int']>;
  workspaceId: Scalars['ID'];
};


export type QueryTasksArgs = {
  filter?: InputMaybe<TaskFilter>;
  orderBy?: InputMaybe<TaskOrderBy>;
  pagination?: InputMaybe<PaginationInput>;
};


export type QueryTimeTrackingArgs = {
  actionId: Scalars['String'];
};


export type QueryTrashBinTasksArgs = {
  workspaceId: Scalars['ID'];
};


export type QueryUserFilterArgs = {
  layout: Scalars['String'];
  projectId: Scalars['ID'];
  userId: Scalars['ID'];
  workspaceId: Scalars['ID'];
};


export type QueryUsersArgs = {
  projectId?: InputMaybe<Scalars['ID']>;
  workspaceId: Scalars['ID'];
};

export type Reaction = {
  __typename?: 'Reaction';
  emoji: Scalars['String'];
  id: Scalars['ID'];
  user: User;
};

export type ReactionInput = {
  commentId: Scalars['ID'];
  emoji: Scalars['String'];
};

export type SignUpInput = {
  email: Scalars['String'];
  firstName: Scalars['String'];
  lastName: Scalars['String'];
  password?: InputMaybe<Scalars['String']>;
  workspaceName: Scalars['String'];
};

export type Status = {
  __typename?: 'Status';
  _id: Scalars['ID'];
  color: Scalars['String'];
  createdAt: Scalars['Date'];
  isDeleted: Scalars['Boolean'];
  projectId: Scalars['String'];
  taskOrder: Array<Scalars['String']>;
  title: Scalars['String'];
  updatedAt: Scalars['Date'];
  workspaceId: Scalars['ID'];
};

export type Subscription = {
  __typename?: 'Subscription';
  onCreateComment: Comment;
  onCreateNotification: Notification;
  onCreateTask: Action;
  onCreateTaskDescriptionHistory: TaskDescriptionHistory;
  onCreateTaskHistory: TaskHistory;
  onDeleteComment: Comment;
  onDeleteTasks: Array<Action>;
  onUpdateTask: Action;
  projectTasksCount: ProjectTasksCount;
};


export type SubscriptionOnCreateNotificationArgs = {
  isPrivate?: InputMaybe<Scalars['Boolean']>;
  limit?: InputMaybe<Scalars['Int']>;
  projectId?: InputMaybe<Scalars['ID']>;
  skip?: InputMaybe<Scalars['Int']>;
  type?: InputMaybe<NotificationTypeEnum>;
  userId?: InputMaybe<Scalars['ID']>;
  workspaceId: Scalars['ID'];
};

export type TaskAdvancedField = {
  __typename?: 'TaskAdvancedField';
  _id: Scalars['ID'];
  createdAt: Scalars['Date'];
  taskId: Scalars['String'];
  title: Scalars['String'];
  type: TaskAdvancedFieldTypeEnum;
  updatedAt: Scalars['Date'];
  value?: Maybe<Scalars['String']>;
  workspaceId: Scalars['String'];
};

/** Task advanced field type */
export enum TaskAdvancedFieldTypeEnum {
  Spreadsheet = 'Spreadsheet'
}

export type TaskCustomField = {
  __typename?: 'TaskCustomField';
  _id: Scalars['ID'];
  createdAt: Scalars['Date'];
  customField: CustomField;
  projectId: Scalars['String'];
  taskId: Scalars['String'];
  updatedAt: Scalars['Date'];
  value: Scalars['String'];
};

export type TaskDescriptionHistory = {
  __typename?: 'TaskDescriptionHistory';
  _id: Scalars['ID'];
  createdAt: Scalars['Date'];
  taskId: Scalars['String'];
  text: Scalars['String'];
  updatedAt: Scalars['Date'];
  user: User;
};

export type TaskFilter = {
  hideCompleted?: InputMaybe<Scalars['Boolean']>;
  labels?: InputMaybe<Array<Scalars['String']>>;
  parentId?: InputMaybe<Scalars['String']>;
  priorities?: InputMaybe<Array<Scalars['String']>>;
  projectId?: InputMaybe<Scalars['String']>;
  search?: InputMaybe<Scalars['String']>;
  statuses?: InputMaybe<Array<Scalars['String']>>;
  userIds?: InputMaybe<Array<Scalars['String']>>;
  workspaceId?: InputMaybe<Scalars['String']>;
};

export type TaskHistory = {
  __typename?: 'TaskHistory';
  _id: Scalars['ID'];
  body: Scalars['String'];
  createdAt: Scalars['Date'];
  createdBy: User;
  taskId: Scalars['String'];
  updatedAt: Scalars['Date'];
};

export type TaskOrderBy = {
  priority?: InputMaybe<OrderDirection>;
  storyPoints?: InputMaybe<OrderDirection>;
};

export type TaskParent = {
  __typename?: 'TaskParent';
  _id: Scalars['ID'];
  title: Scalars['String'];
};

export type TaskTemplate = {
  __typename?: 'TaskTemplate';
  _id: Scalars['ID'];
  assignee?: Maybe<Scalars['String']>;
  createdAt: Scalars['Date'];
  description?: Maybe<Scalars['String']>;
  dueDate?: Maybe<Scalars['Date']>;
  isUrgent?: Maybe<Scalars['Boolean']>;
  priority?: Maybe<Scalars['String']>;
  startDate?: Maybe<Scalars['Date']>;
  storyPoints?: Maybe<Scalars['Float']>;
  title: Scalars['String'];
  updatedAt: Scalars['Date'];
  workspaceId: Scalars['String'];
};

export type TasksResponse = {
  __typename?: 'TasksResponse';
  hasMore: Scalars['Boolean'];
  items: Array<Action>;
  totalCount: Scalars['Float'];
};

export type TimeTracking = {
  __typename?: 'TimeTracking';
  _id: Scalars['ID'];
  actionId: Action;
  createdAt: Scalars['Date'];
  createdBy: User;
  endTime?: Maybe<Scalars['Date']>;
  loggedTime: Scalars['Float'];
  startTime: Scalars['Date'];
  status: TimeTrackingStatus;
  totalTime: Scalars['Float'];
  updatedAt: Scalars['Date'];
};

/** Operation of time tracking */
export enum TimeTrackingOperation {
  Pause = 'PAUSE',
  Start = 'START',
  Stop = 'STOP'
}

/** Status of time tracking */
export enum TimeTrackingStatus {
  Paused = 'PAUSED',
  Stopped = 'STOPPED',
  Tracking = 'TRACKING'
}

export type UpdateCommentInput = {
  commentId: Scalars['ID'];
  text: Scalars['String'];
};

export type UpdateLabelInput = {
  color?: InputMaybe<Scalars['String']>;
  projectId?: InputMaybe<Scalars['ID']>;
  title?: InputMaybe<Scalars['String']>;
  workspaceId?: InputMaybe<Scalars['ID']>;
};

export type UpdateTaskInput = {
  addLabelIds?: InputMaybe<Array<Scalars['ID']>>;
  assigneeId?: InputMaybe<Scalars['ID']>;
  description?: InputMaybe<Scalars['String']>;
  dueDate?: InputMaybe<Scalars['Date']>;
  isArchived?: InputMaybe<Scalars['Boolean']>;
  isUrgent?: InputMaybe<Scalars['Boolean']>;
  priority?: InputMaybe<Scalars['String']>;
  rank?: InputMaybe<Scalars['Float']>;
  removeAttachmentIds?: InputMaybe<Array<Scalars['ID']>>;
  removeLabelIds?: InputMaybe<Array<Scalars['ID']>>;
  startDate?: InputMaybe<Scalars['Date']>;
  statusId?: InputMaybe<Scalars['String']>;
  storyPoints?: InputMaybe<Scalars['Float']>;
  taskId: Scalars['ID'];
  title?: InputMaybe<Scalars['String']>;
};

export type UpdateUserListeningInput = {
  chapterId: Scalars['ID'];
  listenedTime: Scalars['Float'];
};

export type UpdateUserProfileInput = {
  file?: InputMaybe<Scalars['Upload']>;
  firstName?: InputMaybe<Scalars['String']>;
  lastName?: InputMaybe<Scalars['String']>;
  role?: InputMaybe<UserRoleEnum>;
};

export type UploadBookInput = {
  chapters: Array<Scalars['Upload']>;
  poster: Scalars['Upload'];
  title: Scalars['String'];
};

export type UploadFilesInput = {
  file?: InputMaybe<Scalars['Upload']>;
};

export type User = {
  __typename?: 'User';
  _id: Scalars['ID'];
  createdAt: Scalars['Date'];
  email: Scalars['String'];
  firstName: Scalars['String'];
  fullName: Scalars['String'];
  googleId?: Maybe<Scalars['String']>;
  lastName: Scalars['String'];
  listening?: Maybe<Array<UserListening>>;
  minimizedTasks?: Maybe<Array<Action>>;
  password: Scalars['String'];
  profilePicture?: Maybe<Scalars['String']>;
  projectsOrder?: Maybe<Array<Scalars['String']>>;
  pushNotificationToken?: Maybe<Scalars['String']>;
  role: Scalars['String'];
  settings: UserSettings;
  timeTracking?: Maybe<TimeTracking>;
  updatedAt: Scalars['Date'];
  workspaceId: Scalars['ID'];
};

export type UserFilter = {
  __typename?: 'UserFilter';
  _id: Scalars['ID'];
  columns?: Maybe<Array<Scalars['String']>>;
  createdAt: Scalars['Date'];
  hideCompleted: Scalars['Boolean'];
  labels?: Maybe<Array<Scalars['String']>>;
  layout: Scalars['String'];
  priorities?: Maybe<Array<Scalars['String']>>;
  projectId: Scalars['String'];
  statuses?: Maybe<Array<Scalars['String']>>;
  updatedAt: Scalars['Date'];
  userId: Scalars['String'];
  workspaceId: Scalars['String'];
};

export type UserFilterInput = {
  columns?: InputMaybe<Array<Scalars['String']>>;
  hideCompleted?: InputMaybe<Scalars['Boolean']>;
  labels?: InputMaybe<Array<Scalars['String']>>;
  layout: Scalars['String'];
  priorities?: InputMaybe<Array<Scalars['String']>>;
  projectId: Scalars['ID'];
  statuses?: InputMaybe<Array<Scalars['String']>>;
  workspaceId: Scalars['ID'];
};

export type UserListening = {
  __typename?: 'UserListening';
  chapterId: Scalars['String'];
  listenedTime: Scalars['Float'];
};

/** User role enum */
export enum UserRoleEnum {
  Admin = 'ADMIN',
  Manager = 'MANAGER',
  Member = 'MEMBER'
}

export type UserSettings = {
  __typename?: 'UserSettings';
  filters?: Maybe<Array<Scalars['String']>>;
};

export type Word = {
  __typename?: 'Word';
  _id: Scalars['ID'];
  createdAt: Scalars['Date'];
  isLearned: Scalars['Boolean'];
  skipCount: Scalars['Float'];
  title: Scalars['String'];
  translation: Scalars['String'];
  type?: Maybe<WordTypeEnum>;
  updatedAt: Scalars['Date'];
};

/** Word type */
export enum WordTypeEnum {
  IrregularVerb = 'IRREGULAR_VERB'
}

export type Workspace = {
  __typename?: 'Workspace';
  _id: Scalars['ID'];
  createdAt: Scalars['Date'];
  members?: Maybe<Array<User>>;
  name: Scalars['String'];
  updatedAt: Scalars['Date'];
};

export type TaskFragmentFragment = { __typename?: 'Action', _id: string, workspaceId: string, title: string, priority?: string | null, storyPoints?: number | null, description?: string | null, dueDate?: any | null, startDate?: any | null, statusId: string, createdAt: any, updatedAt: any, completedAt?: any | null, rank: number, totalSubtasksCount?: number | null, isUrgent?: boolean | null, isArchived: boolean, projectId?: { __typename?: 'Project', _id: string, color: string, completedStatusId: string, unstagedStatusId: string } | null, assignee?: { __typename?: 'User', _id: string, email: string, fullName: string, profilePicture?: string | null } | null, parentId?: { __typename?: 'TaskParent', _id: string, title: string } | null, attachments?: Array<{ __typename?: 'File', _id: string, size: string, type: string, fileName: string, createdAt: any, urls?: { __typename?: 'FileUrls', original: string, thumbnail: string, thumbnailPreview?: string | null } | null }> | null, labels: Array<{ __typename?: 'Label', _id: string, title: string, color: string }>, taskAdvancedFields?: Array<{ __typename?: 'TaskAdvancedField', _id: string, type: TaskAdvancedFieldTypeEnum, title: string, value?: string | null }> | null };

export type CreateTaskMutationVariables = Exact<{
  workspaceId: Scalars['ID'];
  projectId: Scalars['ID'];
  title: Scalars['String'];
  statusId?: InputMaybe<Scalars['ID']>;
  parentId?: InputMaybe<Scalars['ID']>;
  dueDate?: InputMaybe<Scalars['Date']>;
}>;


export type CreateTaskMutation = { __typename?: 'Mutation', createTask: { __typename?: 'Action', _id: string, workspaceId: string, title: string, priority?: string | null, statusId: string, dueDate?: any | null, startDate?: any | null, storyPoints?: number | null, projectId?: { __typename?: 'Project', _id: string, color: string, completedStatusId: string, unstagedStatusId: string } | null, assignee?: { __typename?: 'User', _id: string, email: string, fullName: string, profilePicture?: string | null } | null, parentId?: { __typename?: 'TaskParent', _id: string, title: string } | null, labels: Array<{ __typename?: 'Label', _id: string, title: string, color: string }>, attachments?: Array<{ __typename?: 'File', _id: string, fileName: string, urls?: { __typename?: 'FileUrls', original: string, thumbnail: string, thumbnailPreview?: string | null } | null }> | null } };

export type DeleteTasksMutationVariables = Exact<{
  taskIds: Array<Scalars['ID']> | Scalars['ID'];
  projectId: Scalars['ID'];
}>;


export type DeleteTasksMutation = { __typename?: 'Mutation', deleteTasks: Array<{ __typename?: 'Action', _id: string }> };

export type AddAttachmentsToTaskMutationVariables = Exact<{
  taskId: Scalars['ID'];
  projectId: Scalars['ID'];
  attachments: Array<Scalars['Upload']> | Scalars['Upload'];
}>;


export type AddAttachmentsToTaskMutation = { __typename?: 'Mutation', addAttachmentsToTask: { __typename?: 'Action', _id: string, attachments?: Array<{ __typename?: 'File', _id: string, fileName: string, size: string, type: string, urls?: { __typename?: 'FileUrls', original: string, thumbnail: string, thumbnailPreview?: string | null } | null }> | null } };

export type DuplicateTaskMutationVariables = Exact<{
  taskId: Scalars['ID'];
}>;


export type DuplicateTaskMutation = { __typename?: 'Mutation', duplicateTask: { __typename?: 'Action', _id: string, workspaceId: string, title: string, priority?: string | null, storyPoints?: number | null, description?: string | null, dueDate?: any | null, startDate?: any | null, statusId: string, createdAt: any, updatedAt: any, completedAt?: any | null, rank: number, totalSubtasksCount?: number | null, isUrgent?: boolean | null, isArchived: boolean, projectId?: { __typename?: 'Project', _id: string, color: string, completedStatusId: string, unstagedStatusId: string } | null, assignee?: { __typename?: 'User', _id: string, email: string, fullName: string, profilePicture?: string | null } | null, parentId?: { __typename?: 'TaskParent', _id: string, title: string } | null, attachments?: Array<{ __typename?: 'File', _id: string, size: string, type: string, fileName: string, createdAt: any, urls?: { __typename?: 'FileUrls', original: string, thumbnail: string, thumbnailPreview?: string | null } | null }> | null, labels: Array<{ __typename?: 'Label', _id: string, title: string, color: string }>, taskAdvancedFields?: Array<{ __typename?: 'TaskAdvancedField', _id: string, type: TaskAdvancedFieldTypeEnum, title: string, value?: string | null }> | null } };

export type UpdateTaskMutationVariables = Exact<{
  updateTaskInput: UpdateTaskInput;
}>;


export type UpdateTaskMutation = { __typename?: 'Mutation', updateTask: { __typename?: 'Action', _id: string, workspaceId: string, title: string, priority?: string | null, storyPoints?: number | null, description?: string | null, dueDate?: any | null, startDate?: any | null, statusId: string, createdAt: any, updatedAt: any, completedAt?: any | null, rank: number, totalSubtasksCount?: number | null, isUrgent?: boolean | null, isArchived: boolean, projectId?: { __typename?: 'Project', _id: string, color: string, completedStatusId: string, unstagedStatusId: string } | null, assignee?: { __typename?: 'User', _id: string, email: string, fullName: string, profilePicture?: string | null } | null, parentId?: { __typename?: 'TaskParent', _id: string, title: string } | null, attachments?: Array<{ __typename?: 'File', _id: string, size: string, type: string, fileName: string, createdAt: any, urls?: { __typename?: 'FileUrls', original: string, thumbnail: string, thumbnailPreview?: string | null } | null }> | null, labels: Array<{ __typename?: 'Label', _id: string, title: string, color: string }>, taskAdvancedFields?: Array<{ __typename?: 'TaskAdvancedField', _id: string, type: TaskAdvancedFieldTypeEnum, title: string, value?: string | null }> | null } };

export type ApplyTaskTemplateMutationVariables = Exact<{
  taskId: Scalars['ID'];
  templateId: Scalars['ID'];
}>;


export type ApplyTaskTemplateMutation = { __typename?: 'Mutation', applyTaskTemplate: { __typename?: 'Action', _id: string, title: string, description?: string | null, dueDate?: any | null, isUrgent?: boolean | null, priority?: string | null, startDate?: any | null, storyPoints?: number | null, assignee?: { __typename?: 'User', _id: string, email: string, fullName: string, profilePicture?: string | null } | null } };

export type TaskCountsQueryVariables = Exact<{
  projectId: Scalars['ID'];
  workspaceId: Scalars['ID'];
}>;


export type TaskCountsQuery = { __typename?: 'Query', taskCounts: { __typename?: 'ProjectTasksCount', incompleteTasks?: number | null, completedTasks?: number | null }, taskByStatusWithCount: Array<{ __typename?: 'KanbanTasks', title: string, tasksCount: number }>, dashboardTasksByPeriod: Array<{ __typename?: 'DashboardTasksOutput', day?: string | null, count?: string | null, tasks?: Array<{ __typename?: 'Action', _id: string, title: string }> | null }> };

export type ProjectTasksCountQueryVariables = Exact<{
  projectId: Scalars['ID'];
}>;


export type ProjectTasksCountQuery = { __typename?: 'Query', taskCounts: { __typename?: 'ProjectTasksCount', incompleteTasks?: number | null, completedTasks?: number | null, deletedTasks?: number | null, archivedTasks?: number | null } };

export type TasksQueryVariables = Exact<{
  filter?: InputMaybe<TaskFilter>;
  orderBy?: InputMaybe<TaskOrderBy>;
  pagination?: InputMaybe<PaginationInput>;
}>;


export type TasksQuery = { __typename?: 'Query', tasks: { __typename?: 'TasksResponse', totalCount: number, hasMore: boolean, items: Array<{ __typename?: 'Action', _id: string, workspaceId: string, title: string, priority?: string | null, storyPoints?: number | null, description?: string | null, dueDate?: any | null, startDate?: any | null, statusId: string, createdAt: any, updatedAt: any, completedAt?: any | null, rank: number, totalSubtasksCount?: number | null, isUrgent?: boolean | null, isArchived: boolean, projectId?: { __typename?: 'Project', _id: string, color: string, completedStatusId: string, unstagedStatusId: string } | null, assignee?: { __typename?: 'User', _id: string, email: string, fullName: string, profilePicture?: string | null } | null, parentId?: { __typename?: 'TaskParent', _id: string, title: string } | null, attachments?: Array<{ __typename?: 'File', _id: string, size: string, type: string, fileName: string, createdAt: any, urls?: { __typename?: 'FileUrls', original: string, thumbnail: string, thumbnailPreview?: string | null } | null }> | null, labels: Array<{ __typename?: 'Label', _id: string, title: string, color: string }>, taskAdvancedFields?: Array<{ __typename?: 'TaskAdvancedField', _id: string, type: TaskAdvancedFieldTypeEnum, title: string, value?: string | null }> | null }> } };

export type TrashBinTasksQueryVariables = Exact<{
  workspaceId: Scalars['ID'];
}>;


export type TrashBinTasksQuery = { __typename?: 'Query', trashBinTasks: Array<{ __typename?: 'Action', _id: string, title: string, deletedAt?: any | null, deletedBy?: { __typename?: 'User', _id: string, fullName: string, profilePicture?: string | null } | null, projectId?: { __typename?: 'Project', _id: string, title: string, color: string } | null }> };

export type KanbanTasksQueryVariables = Exact<{
  projectId: Scalars['ID'];
  priority?: InputMaybe<Scalars['String']>;
  labels?: InputMaybe<Array<Scalars['ID']> | Scalars['ID']>;
  statusIds?: InputMaybe<Array<Scalars['ID']> | Scalars['ID']>;
  dueDate?: InputMaybe<Scalars['String']>;
  assignees?: InputMaybe<Array<Scalars['ID']> | Scalars['ID']>;
}>;


export type KanbanTasksQuery = { __typename?: 'Query', kanbanTasks: Array<{ __typename?: 'KanbanTasks', _id: string, title: string, tasksCount: number, color?: string | null, actions: Array<{ __typename?: 'Action', _id: string, workspaceId: string, title: string, priority?: string | null, storyPoints?: number | null, description?: string | null, dueDate?: any | null, startDate?: any | null, statusId: string, createdAt: any, updatedAt: any, completedAt?: any | null, rank: number, totalSubtasksCount?: number | null, isUrgent?: boolean | null, isArchived: boolean, projectId?: { __typename?: 'Project', _id: string, color: string, completedStatusId: string, unstagedStatusId: string } | null, assignee?: { __typename?: 'User', _id: string, email: string, fullName: string, profilePicture?: string | null } | null, parentId?: { __typename?: 'TaskParent', _id: string, title: string } | null, attachments?: Array<{ __typename?: 'File', _id: string, size: string, type: string, fileName: string, createdAt: any, urls?: { __typename?: 'FileUrls', original: string, thumbnail: string, thumbnailPreview?: string | null } | null }> | null, labels: Array<{ __typename?: 'Label', _id: string, title: string, color: string }>, taskAdvancedFields?: Array<{ __typename?: 'TaskAdvancedField', _id: string, type: TaskAdvancedFieldTypeEnum, title: string, value?: string | null }> | null }> }> };

export type GetActionQueryVariables = Exact<{
  id: Scalars['ID'];
}>;


export type GetActionQuery = { __typename?: 'Query', action: { __typename?: 'Action', _id: string, workspaceId: string, title: string, priority?: string | null, storyPoints?: number | null, description?: string | null, dueDate?: any | null, startDate?: any | null, statusId: string, createdAt: any, updatedAt: any, completedAt?: any | null, rank: number, totalSubtasksCount?: number | null, isUrgent?: boolean | null, isArchived: boolean, projectId?: { __typename?: 'Project', _id: string, color: string, completedStatusId: string, unstagedStatusId: string } | null, assignee?: { __typename?: 'User', _id: string, email: string, fullName: string, profilePicture?: string | null } | null, parentId?: { __typename?: 'TaskParent', _id: string, title: string } | null, attachments?: Array<{ __typename?: 'File', _id: string, size: string, type: string, fileName: string, createdAt: any, urls?: { __typename?: 'FileUrls', original: string, thumbnail: string, thumbnailPreview?: string | null } | null }> | null, labels: Array<{ __typename?: 'Label', _id: string, title: string, color: string }>, taskAdvancedFields?: Array<{ __typename?: 'TaskAdvancedField', _id: string, type: TaskAdvancedFieldTypeEnum, title: string, value?: string | null }> | null } };

export type TaskHistoryQueryVariables = Exact<{
  taskId: Scalars['ID'];
}>;


export type TaskHistoryQuery = { __typename?: 'Query', taskHistory: Array<{ __typename?: 'TaskHistory', _id: string, body: string, createdAt: any, taskId: string }> };

export type OnCreateTaskHistorySubscriptionVariables = Exact<{ [key: string]: never; }>;


export type OnCreateTaskHistorySubscription = { __typename?: 'Subscription', onCreateTaskHistory: { __typename?: 'TaskHistory', _id: string, body: string, taskId: string, createdAt: any } };

export type OnUpdateTaskSubscriptionVariables = Exact<{ [key: string]: never; }>;


export type OnUpdateTaskSubscription = { __typename?: 'Subscription', onUpdateTask: { __typename?: 'Action', _id: string, workspaceId: string, title: string, priority?: string | null, storyPoints?: number | null, description?: string | null, dueDate?: any | null, startDate?: any | null, statusId: string, createdAt: any, updatedAt: any, completedAt?: any | null, rank: number, totalSubtasksCount?: number | null, isUrgent?: boolean | null, isArchived: boolean, projectId?: { __typename?: 'Project', _id: string, color: string, completedStatusId: string, unstagedStatusId: string } | null, assignee?: { __typename?: 'User', _id: string, email: string, fullName: string, profilePicture?: string | null } | null, parentId?: { __typename?: 'TaskParent', _id: string, title: string } | null, attachments?: Array<{ __typename?: 'File', _id: string, size: string, type: string, fileName: string, createdAt: any, urls?: { __typename?: 'FileUrls', original: string, thumbnail: string, thumbnailPreview?: string | null } | null }> | null, labels: Array<{ __typename?: 'Label', _id: string, title: string, color: string }>, taskAdvancedFields?: Array<{ __typename?: 'TaskAdvancedField', _id: string, type: TaskAdvancedFieldTypeEnum, title: string, value?: string | null }> | null } };

export type OnCreateTaskSubscriptionVariables = Exact<{ [key: string]: never; }>;


export type OnCreateTaskSubscription = { __typename?: 'Subscription', onCreateTask: { __typename?: 'Action', _id: string, workspaceId: string, title: string, priority?: string | null, storyPoints?: number | null, description?: string | null, dueDate?: any | null, startDate?: any | null, statusId: string, createdAt: any, updatedAt: any, completedAt?: any | null, rank: number, totalSubtasksCount?: number | null, isUrgent?: boolean | null, isArchived: boolean, projectId?: { __typename?: 'Project', _id: string, color: string, completedStatusId: string, unstagedStatusId: string } | null, assignee?: { __typename?: 'User', _id: string, email: string, fullName: string, profilePicture?: string | null } | null, parentId?: { __typename?: 'TaskParent', _id: string, title: string } | null, attachments?: Array<{ __typename?: 'File', _id: string, size: string, type: string, fileName: string, createdAt: any, urls?: { __typename?: 'FileUrls', original: string, thumbnail: string, thumbnailPreview?: string | null } | null }> | null, labels: Array<{ __typename?: 'Label', _id: string, title: string, color: string }>, taskAdvancedFields?: Array<{ __typename?: 'TaskAdvancedField', _id: string, type: TaskAdvancedFieldTypeEnum, title: string, value?: string | null }> | null } };

export type ProjectTasksCountSubSubscriptionVariables = Exact<{ [key: string]: never; }>;


export type ProjectTasksCountSubSubscription = { __typename?: 'Subscription', projectTasksCount: { __typename?: 'ProjectTasksCount', incompleteTasks?: number | null, completedTasks?: number | null, deletedTasks?: number | null, archivedTasks?: number | null } };

export type OnDeleteTasksSubscriptionVariables = Exact<{ [key: string]: never; }>;


export type OnDeleteTasksSubscription = { __typename?: 'Subscription', onDeleteTasks: Array<{ __typename?: 'Action', _id: string }> };

export type SingUpMutationVariables = Exact<{
  firstName: Scalars['String'];
  lastName: Scalars['String'];
  email: Scalars['String'];
  password: Scalars['String'];
  workspaceName: Scalars['String'];
}>;


export type SingUpMutation = { __typename?: 'Mutation', signup: { __typename?: 'AuthResponse', token: string } };

export type LoginMutationVariables = Exact<{
  email: Scalars['String'];
  password: Scalars['String'];
}>;


export type LoginMutation = { __typename?: 'Mutation', login: { __typename?: 'AuthResponse', token: string, user: { __typename?: 'User', _id: string, firstName: string, lastName: string, fullName: string, role: string, email: string, profilePicture?: string | null, workspaceId: string } } };

export type GoogleLoginMutationVariables = Exact<{
  token: Scalars['String'];
}>;


export type GoogleLoginMutation = { __typename?: 'Mutation', googleLogin: { __typename?: 'AuthResponse', token: string, user: { __typename?: 'User', _id: string, firstName: string, lastName: string, fullName: string, role: string, email: string, profilePicture?: string | null, workspaceId: string } } };

export type UploadBookMutationVariables = Exact<{
  input: UploadBookInput;
}>;


export type UploadBookMutation = { __typename?: 'Mutation', uploadBook: { __typename?: 'Book', _id: string } };

export type UpdateUserListeningMutationVariables = Exact<{
  chapterId: Scalars['ID'];
  listenedTime: Scalars['Float'];
}>;


export type UpdateUserListeningMutation = { __typename?: 'Mutation', updateUserListening: { __typename?: 'User', _id: string, listening?: Array<{ __typename?: 'UserListening', chapterId: string, listenedTime: number }> | null } };

export type BooksQueryVariables = Exact<{
  workspaceId: Scalars['ID'];
}>;


export type BooksQuery = { __typename?: 'Query', books: Array<{ __typename?: 'Book', _id: string, title: string, poster: string, chapters: Array<{ __typename?: 'Chapter', id: string, title: string, url: string, time: number }> }> };

export type CreateCanvasBoardMutationVariables = Exact<{
  workspaceId: Scalars['ID'];
  title: Scalars['String'];
  previewImage?: InputMaybe<Scalars['String']>;
}>;


export type CreateCanvasBoardMutation = { __typename?: 'Mutation', createCanvasBoard: { __typename?: 'CanvasBoard', _id: string, workspaceId: string, title: string, previewImage?: string | null, createdBy?: { __typename?: 'User', _id: string, email: string, fullName: string } | null } };

export type DeleteCanvasBoardMutationVariables = Exact<{
  workspaceId: Scalars['ID'];
  boardId: Scalars['ID'];
}>;


export type DeleteCanvasBoardMutation = { __typename?: 'Mutation', deleteCanvasBoard: { __typename?: 'CanvasBoard', _id: string } };

export type UpdateCanvasBoardMutationVariables = Exact<{
  boardId: Scalars['ID'];
  title?: InputMaybe<Scalars['String']>;
  previewImage?: InputMaybe<Scalars['String']>;
}>;


export type UpdateCanvasBoardMutation = { __typename?: 'Mutation', updateCanvasBoard: { __typename?: 'CanvasBoard', _id: string, title: string, previewImage?: string | null } };

export type CanvasBoardsQueryVariables = Exact<{
  workspaceId: Scalars['ID'];
}>;


export type CanvasBoardsQuery = { __typename?: 'Query', canvasBoards: Array<{ __typename?: 'CanvasBoard', _id: string, title: string, previewImage?: string | null, createdAt: any, updatedAt: any, createdBy?: { __typename?: 'User', _id: string, fullName: string } | null }> };

export type CreateCommentMutationVariables = Exact<{
  text?: InputMaybe<Scalars['String']>;
  taskId: Scalars['ID'];
  type: CommentTypeEnum;
  sticker?: InputMaybe<Scalars['String']>;
  projectId: Scalars['ID'];
}>;


export type CreateCommentMutation = { __typename?: 'Mutation', createComment: { __typename?: 'Comment', _id: string, text?: string | null, taskId: string, type: CommentTypeEnum, sticker?: string | null, createdAt: any } };

export type UpdateCommentMutationVariables = Exact<{
  commentId: Scalars['ID'];
  text: Scalars['String'];
}>;


export type UpdateCommentMutation = { __typename?: 'Mutation', updateComment: { __typename?: 'Comment', _id: string, text?: string | null, isEdited?: boolean | null } };

export type DeleteCommentMutationVariables = Exact<{
  id: Scalars['ID'];
}>;


export type DeleteCommentMutation = { __typename?: 'Mutation', deleteComment: { __typename?: 'Comment', _id: string } };

export type AddReactionMutationVariables = Exact<{
  commentId: Scalars['ID'];
  emoji: Scalars['String'];
}>;


export type AddReactionMutation = { __typename?: 'Mutation', addReaction: { __typename?: 'Comment', _id: string, reactions: Array<{ __typename?: 'Reaction', id: string, emoji: string, user: { __typename?: 'User', _id: string, fullName: string } }> } };

export type RemoveReactionMutationVariables = Exact<{
  commentId: Scalars['ID'];
  emoji: Scalars['String'];
}>;


export type RemoveReactionMutation = { __typename?: 'Mutation', removeReaction: { __typename?: 'Comment', _id: string, reactions: Array<{ __typename?: 'Reaction', id: string, emoji: string }> } };

export type CommentsQueryVariables = Exact<{
  taskId: Scalars['ID'];
}>;


export type CommentsQuery = { __typename?: 'Query', comments: Array<{ __typename?: 'Comment', _id: string, text?: string | null, type: CommentTypeEnum, sticker?: string | null, createdAt: any, isEdited?: boolean | null, user: { __typename?: 'User', _id: string, fullName: string, profilePicture?: string | null }, reactions: Array<{ __typename?: 'Reaction', id: string, emoji: string, user: { __typename?: 'User', _id: string, fullName: string } }> }> };

export type OnCreateCommentSubscriptionVariables = Exact<{ [key: string]: never; }>;


export type OnCreateCommentSubscription = { __typename?: 'Subscription', onCreateComment: { __typename?: 'Comment', _id: string, text?: string | null, type: CommentTypeEnum, sticker?: string | null, createdAt: any, taskId: string, reactions: Array<{ __typename?: 'Reaction', id: string, emoji: string, user: { __typename?: 'User', _id: string, fullName: string } }>, user: { __typename?: 'User', _id: string, fullName: string, profilePicture?: string | null } } };

export type OnDeleteCommentSubscriptionVariables = Exact<{ [key: string]: never; }>;


export type OnDeleteCommentSubscription = { __typename?: 'Subscription', onDeleteComment: { __typename?: 'Comment', _id: string } };

export type CreateCustomFieldMutationVariables = Exact<{
  workspaceId: Scalars['ID'];
  title: Scalars['String'];
  type: Scalars['String'];
}>;


export type CreateCustomFieldMutation = { __typename?: 'Mutation', createCustomField: { __typename?: 'CustomField', _id: string, title: string, type: string, projectIds: Array<string> } };

export type ToggleCustomFieldToProjectMutationVariables = Exact<{
  projectId: Scalars['ID'];
  customFieldId: Scalars['ID'];
  isChecked: Scalars['Boolean'];
}>;


export type ToggleCustomFieldToProjectMutation = { __typename?: 'Mutation', toggleCustomFieldToProject: { __typename?: 'CustomField', _id: string, projectIds: Array<string> } };

export type CustomFieldsQueryVariables = Exact<{
  workspaceId: Scalars['ID'];
}>;


export type CustomFieldsQuery = { __typename?: 'Query', customFields: Array<{ __typename?: 'CustomField', _id: string, title: string, type: string, projectIds: Array<string> }> };

export type FilesQueryVariables = Exact<{
  projectId: Scalars['ID'];
}>;


export type FilesQuery = { __typename?: 'Query', files: Array<{ __typename?: 'File', _id: string, fileName: string, size: string, type: string, createdAt: any, urls?: { __typename?: 'FileUrls', original: string, thumbnail: string, thumbnailPreview?: string | null } | null }> };

export type AssigneeFieldsFragment = { __typename?: 'Action', assignee?: { __typename?: 'User', _id: string, email: string, fullName: string, profilePicture?: string | null } | null };

export type NotificationFragmentFragment = { __typename?: 'Notification', _id: string, title: string, message?: string | null, isRead: boolean, isPrivate: boolean, createdAt: any, projectId?: string | null, type: NotificationTypeEnum, user: { __typename?: 'User', _id: string, fullName: string, profilePicture?: string | null } };

export type CreateLabelMutationVariables = Exact<{
  workspaceId: Scalars['ID'];
  projectId: Scalars['ID'];
  title: Scalars['String'];
  color: Scalars['String'];
}>;


export type CreateLabelMutation = { __typename?: 'Mutation', createLabel: { __typename?: 'Label', _id: string, color: string, title: string } };

export type UpdateLabelMutationVariables = Exact<{
  id: Scalars['ID'];
  color?: InputMaybe<Scalars['String']>;
  title?: InputMaybe<Scalars['String']>;
}>;


export type UpdateLabelMutation = { __typename?: 'Mutation', updateLabel: { __typename?: 'Label', _id: string, color: string, title: string } };

export type DeleteLabelMutationVariables = Exact<{
  id: Scalars['ID'];
}>;


export type DeleteLabelMutation = { __typename?: 'Mutation', deleteLabel: { __typename?: 'Label', _id: string } };

export type LabelsQueryVariables = Exact<{
  workspaceId: Scalars['ID'];
  projectId: Scalars['ID'];
}>;


export type LabelsQuery = { __typename?: 'Query', labels: Array<{ __typename?: 'Label', _id: string, title: string, color: string }> };

export type ReadNotificationMutationVariables = Exact<{
  notificationId: Scalars['ID'];
}>;


export type ReadNotificationMutation = { __typename?: 'Mutation', readNotification: { __typename?: 'Notification', _id: string, isRead: boolean } };

export type ReadAllNotificationMutationVariables = Exact<{ [key: string]: never; }>;


export type ReadAllNotificationMutation = { __typename?: 'Mutation', readAllNotification: boolean };

export type NotificationsQueryVariables = Exact<{
  workspaceId: Scalars['ID'];
  userId?: InputMaybe<Scalars['ID']>;
  skip?: InputMaybe<Scalars['Int']>;
  limit?: InputMaybe<Scalars['Int']>;
  projectId?: InputMaybe<Scalars['ID']>;
  isPrivate?: InputMaybe<Scalars['Boolean']>;
  type?: InputMaybe<NotificationTypeEnum>;
}>;


export type NotificationsQuery = { __typename?: 'Query', notifications: Array<{ __typename?: 'Notification', _id: string, title: string, message?: string | null, isRead: boolean, isPrivate: boolean, createdAt: any, projectId?: string | null, type: NotificationTypeEnum, user: { __typename?: 'User', _id: string, fullName: string, profilePicture?: string | null } }> };

export type OnCreateNotificationSubscriptionVariables = Exact<{
  workspaceId: Scalars['ID'];
  projectId?: InputMaybe<Scalars['ID']>;
  userId?: InputMaybe<Scalars['ID']>;
  isPrivate?: InputMaybe<Scalars['Boolean']>;
}>;


export type OnCreateNotificationSubscription = { __typename?: 'Subscription', onCreateNotification: { __typename?: 'Notification', _id: string, title: string, message?: string | null, isRead: boolean, isPrivate: boolean, createdAt: any, projectId?: string | null, type: NotificationTypeEnum, user: { __typename?: 'User', _id: string, fullName: string, profilePicture?: string | null } } };

export type CreateProjectMutationVariables = Exact<{
  title: Scalars['String'];
  color: Scalars['String'];
}>;


export type CreateProjectMutation = { __typename?: 'Mutation', createProject: { __typename?: 'Project', title: string, color: string } };

export type UpdateProjectMutationVariables = Exact<{
  projectId: Scalars['ID'];
  title?: InputMaybe<Scalars['String']>;
  color?: InputMaybe<Scalars['String']>;
  boardsSortList?: InputMaybe<Array<Scalars['ID']> | Scalars['ID']>;
  status?: InputMaybe<ProjectStatusEnum>;
}>;


export type UpdateProjectMutation = { __typename?: 'Mutation', updateProject: { __typename?: 'Project', _id: string, title: string, color: string, boardsSortList?: Array<string> | null, status?: ProjectStatusEnum | null } };

export type AcceptInviteToProjectMutationVariables = Exact<{
  firstName: Scalars['String'];
  lastName: Scalars['String'];
  password: Scalars['String'];
  inviteToken: Scalars['String'];
}>;


export type AcceptInviteToProjectMutation = { __typename?: 'Mutation', acceptInviteToProject: { __typename?: 'AuthResponse', token: string, user: { __typename?: 'User', _id: string, fullName: string, email: string, profilePicture?: string | null } } };

export type InviteProjectMemberMutationVariables = Exact<{
  workspaceId: Scalars['ID'];
  projectId: Scalars['ID'];
  email: Scalars['String'];
}>;


export type InviteProjectMemberMutation = { __typename?: 'Mutation', inviteProjectMember: boolean };

export type DeleteProjectMutationVariables = Exact<{
  projectId: Scalars['ID'];
}>;


export type DeleteProjectMutation = { __typename?: 'Mutation', deleteProject: boolean };

export type GetProjectsQueryVariables = Exact<{ [key: string]: never; }>;


export type GetProjectsQuery = { __typename?: 'Query', projects: Array<{ __typename?: 'Project', _id: string, title: string, color: string, createdAt: any, boardsSortList?: Array<string> | null, members?: Array<{ __typename?: 'User', _id: string, email: string, fullName: string, profilePicture?: string | null }> | null }> };

export type GetProjectQueryVariables = Exact<{
  projectId: Scalars['ID'];
}>;


export type GetProjectQuery = { __typename?: 'Query', project: { __typename?: 'Project', _id: string, title: string, color: string, boardsSortList?: Array<string> | null, completedStatusId: string, unstagedStatusId: string, status?: ProjectStatusEnum | null, description?: string | null, members?: Array<{ __typename?: 'User', _id: string, email: string, fullName: string, profilePicture?: string | null }> | null }, statuses: Array<{ __typename?: 'Status', _id: string, title: string, color: string }> };

export type ProjectsDashboardQueryVariables = Exact<{ [key: string]: never; }>;


export type ProjectsDashboardQuery = { __typename?: 'Query', projectsDashboard: Array<{ __typename?: 'ProjectsDashboard', _id: string, color: string, title: string, createdAt: any, updatedAt: any, statusesList?: Array<string> | null, labelsList?: Array<string> | null, statusesCount?: number | null, labelsCount?: number | null, taskCount?: number | null, filesCount?: number | null, members?: Array<{ __typename?: 'User', _id: string, fullName: string, profilePicture?: string | null }> | null }> };

export type DeleteStatusMutationVariables = Exact<{
  statusId: Scalars['ID'];
}>;


export type DeleteStatusMutation = { __typename?: 'Mutation', deleteStatus: { __typename?: 'Status', _id: string } };

export type CreateStatusMutationVariables = Exact<{
  workspaceId: Scalars['ID'];
  projectId: Scalars['ID'];
  title: Scalars['String'];
}>;


export type CreateStatusMutation = { __typename?: 'Mutation', createStatus: { __typename?: 'Status', _id: string, title: string, color: string, taskOrder: Array<string> } };

export type UpdateStatusMutationVariables = Exact<{
  statusId: Scalars['ID'];
  taskOrder?: InputMaybe<Array<Scalars['ID']> | Scalars['ID']>;
  color?: InputMaybe<Scalars['String']>;
  title?: InputMaybe<Scalars['String']>;
}>;


export type UpdateStatusMutation = { __typename?: 'Mutation', updateStatus: { __typename?: 'Status', _id: string, title: string, color: string, taskOrder: Array<string> } };

export type StatusesQueryVariables = Exact<{
  projectId: Scalars['ID'];
}>;


export type StatusesQuery = { __typename?: 'Query', statuses: Array<{ __typename?: 'Status', _id: string, title: string, color: string, createdAt: any }> };

export type CreateTaskAdvancedFieldMutationVariables = Exact<{
  taskId: Scalars['ID'];
  type: TaskAdvancedFieldTypeEnum;
  title: Scalars['String'];
}>;


export type CreateTaskAdvancedFieldMutation = { __typename?: 'Mutation', createTaskAdvancedField: { __typename?: 'TaskAdvancedField', _id: string } };

export type UpdateTaskAdvancedFieldMutationVariables = Exact<{
  fieldId: Scalars['ID'];
  value: Scalars['String'];
}>;


export type UpdateTaskAdvancedFieldMutation = { __typename?: 'Mutation', updateTaskAdvancedField: { __typename?: 'TaskAdvancedField', _id: string } };

export type DeleteTaskAdvancedFieldMutationVariables = Exact<{
  fieldId: Scalars['ID'];
}>;


export type DeleteTaskAdvancedFieldMutation = { __typename?: 'Mutation', deleteTaskAdvancedField: { __typename?: 'TaskAdvancedField', _id: string } };

export type CreateTaskCustomFieldMutationVariables = Exact<{
  value: Scalars['String'];
  taskId: Scalars['ID'];
  projectId: Scalars['ID'];
  customFieldId: Scalars['ID'];
}>;


export type CreateTaskCustomFieldMutation = { __typename?: 'Mutation', createTaskCustomField: { __typename?: 'TaskCustomField', _id: string, value: string } };

export type TaskCustomFieldsQueryVariables = Exact<{
  workspaceId: Scalars['ID'];
  projectId: Scalars['ID'];
  taskId: Scalars['ID'];
  projectIds?: InputMaybe<Array<Scalars['ID']> | Scalars['ID']>;
}>;


export type TaskCustomFieldsQuery = { __typename?: 'Query', customFields: Array<{ __typename?: 'CustomField', _id: string, title: string, type: string, projectIds: Array<string> }>, taskCustomFields: Array<{ __typename?: 'TaskCustomField', _id: string, value: string, customField: { __typename?: 'CustomField', _id: string, title: string, type: string } }> };

export type TaskDescriptionHistoryQueryVariables = Exact<{
  taskId: Scalars['ID'];
  skip?: InputMaybe<Scalars['Int']>;
  limit?: InputMaybe<Scalars['Int']>;
}>;


export type TaskDescriptionHistoryQuery = { __typename?: 'Query', taskDescriptionHistory: Array<{ __typename?: 'TaskDescriptionHistory', _id: string, text: string, taskId: string, createdAt: any, user: { __typename?: 'User', fullName: string } }> };

export type OnCreateTaskDescriptionHistorySubscriptionVariables = Exact<{ [key: string]: never; }>;


export type OnCreateTaskDescriptionHistorySubscription = { __typename?: 'Subscription', onCreateTaskDescriptionHistory: { __typename?: 'TaskDescriptionHistory', _id: string, text: string, taskId: string, user: { __typename?: 'User', fullName: string } } };

export type CreateTaskTemplateMutationVariables = Exact<{
  workspaceId: Scalars['ID'];
  title: Scalars['String'];
  description?: InputMaybe<Scalars['String']>;
  dueDate?: InputMaybe<Scalars['Date']>;
  isUrgent?: InputMaybe<Scalars['Boolean']>;
  priority?: InputMaybe<Scalars['String']>;
  startDate?: InputMaybe<Scalars['Date']>;
  storyPoints?: InputMaybe<Scalars['Float']>;
  assignee?: InputMaybe<Scalars['String']>;
}>;


export type CreateTaskTemplateMutation = { __typename?: 'Mutation', createTaskTemplate: { __typename?: 'TaskTemplate', _id: string, workspaceId: string, title: string, description?: string | null, dueDate?: any | null, isUrgent?: boolean | null, priority?: string | null, startDate?: any | null, storyPoints?: number | null } };

export type DeleteTaskTemplateMutationVariables = Exact<{
  templateId: Scalars['ID'];
}>;


export type DeleteTaskTemplateMutation = { __typename?: 'Mutation', deleteTaskTemplate: { __typename?: 'TaskTemplate', _id: string } };

export type TaskTemplatesQueryVariables = Exact<{
  workspaceId: Scalars['ID'];
}>;


export type TaskTemplatesQuery = { __typename?: 'Query', taskTemplates: Array<{ __typename?: 'TaskTemplate', _id: string, title: string, description?: string | null }> };

export type UpdateTimeTrackingMutationVariables = Exact<{
  actionId: Scalars['String'];
  timeTrackingId?: InputMaybe<Scalars['String']>;
  operation: TimeTrackingOperation;
}>;


export type UpdateTimeTrackingMutation = { __typename?: 'Mutation', updateTimeTracking: { __typename?: 'TimeTracking', _id: string } };

export type TimeTrackingQueryVariables = Exact<{
  actionId: Scalars['String'];
}>;


export type TimeTrackingQuery = { __typename?: 'Query', timeTracking: Array<{ __typename?: 'TimeTracking', _id: string, startTime: any, endTime?: any | null, loggedTime: number, createdAt: any }> };

export type UpdateUserFilterMutationVariables = Exact<{
  filter: UserFilterInput;
}>;


export type UpdateUserFilterMutation = { __typename?: 'Mutation', updateUserFilter: { __typename?: 'UserFilter', _id: string, workspaceId: string, projectId: string, userId: string, hideCompleted: boolean, columns?: Array<string> | null, labels?: Array<string> | null, priorities?: Array<string> | null, statuses?: Array<string> | null } };

export type UserFilterQueryVariables = Exact<{
  workspaceId: Scalars['ID'];
  projectId: Scalars['ID'];
  layout: Scalars['String'];
  userId: Scalars['ID'];
}>;


export type UserFilterQuery = { __typename?: 'Query', userFilter?: { __typename?: 'UserFilter', _id: string, hideCompleted: boolean, columns?: Array<string> | null, labels?: Array<string> | null, statuses?: Array<string> | null, priorities?: Array<string> | null } | null };

export type UpdateUserProfileMutationVariables = Exact<{
  firstName?: InputMaybe<Scalars['String']>;
  lastName?: InputMaybe<Scalars['String']>;
  role?: InputMaybe<UserRoleEnum>;
  file?: InputMaybe<Scalars['Upload']>;
}>;


export type UpdateUserProfileMutation = { __typename?: 'Mutation', updateUserProfile: { __typename?: 'User', _id: string, profilePicture?: string | null, firstName: string, lastName: string, fullName: string, email: string, role: string } };

export type UpdateUserMutationVariables = Exact<{
  pushNotificationToken?: InputMaybe<Scalars['String']>;
  projectsOrder?: InputMaybe<Array<Scalars['ID']> | Scalars['ID']>;
}>;


export type UpdateUserMutation = { __typename?: 'Mutation', updateUser: { __typename?: 'User', _id: string, pushNotificationToken?: string | null, projectsOrder?: Array<string> | null } };

export type UpdateUserMinimizedTasksMutationVariables = Exact<{
  addMinimizedTaskId?: InputMaybe<Scalars['ID']>;
  removeMinimizedTaskId?: InputMaybe<Scalars['ID']>;
}>;


export type UpdateUserMinimizedTasksMutation = { __typename?: 'Mutation', updateUserMinimizedTasks: { __typename?: 'User', _id: string, minimizedTasks?: Array<{ __typename?: 'Action', _id: string, title: string }> | null } };

export type UsersQueryVariables = Exact<{
  workspaceId: Scalars['ID'];
  projectId?: InputMaybe<Scalars['ID']>;
}>;


export type UsersQuery = { __typename?: 'Query', users: Array<{ __typename?: 'User', _id: string, firstName: string, lastName: string, fullName: string, email: string, profilePicture?: string | null }> };

export type DashboardTasksByPeriodQueryVariables = Exact<{
  workspaceId: Scalars['ID'];
  period?: InputMaybe<DashboardTaskPeriodEnum>;
  projectId?: InputMaybe<Scalars['ID']>;
}>;


export type DashboardTasksByPeriodQuery = { __typename?: 'Query', dashboardTasksByPeriod: Array<{ __typename?: 'DashboardTasksOutput', day?: string | null, count?: string | null, tasks?: Array<{ __typename?: 'Action', _id: string, title: string }> | null }> };

export type GetCurrentUserQueryVariables = Exact<{ [key: string]: never; }>;


export type GetCurrentUserQuery = { __typename?: 'Query', me: { __typename?: 'User', _id: string, workspaceId: string, firstName: string, lastName: string, fullName: string, role: string, email: string, profilePicture?: string | null, pushNotificationToken?: string | null, timeTracking?: { __typename?: 'TimeTracking', _id: string, startTime: any, endTime?: any | null, totalTime: number, status: TimeTrackingStatus, actionId: { __typename?: 'Action', _id: string, title: string } } | null, minimizedTasks?: Array<{ __typename?: 'Action', _id: string, title: string }> | null } };

export type UpdateWordMutationVariables = Exact<{
  wordId: Scalars['ID'];
  isLearned?: InputMaybe<Scalars['Boolean']>;
  isSkip?: InputMaybe<Scalars['Boolean']>;
}>;


export type UpdateWordMutation = { __typename?: 'Mutation', updateWord: { __typename?: 'Word', _id: string, title: string, isLearned: boolean, skipCount: number } };

export type RandomWordQueryVariables = Exact<{ [key: string]: never; }>;


export type RandomWordQuery = { __typename?: 'Query', randomWord: { __typename?: 'Word', _id: string, title: string, isLearned: boolean, skipCount: number } };

export type WordsQueryVariables = Exact<{ [key: string]: never; }>;


export type WordsQuery = { __typename?: 'Query', words: Array<{ __typename?: 'Word', _id: string, title: string, isLearned: boolean, skipCount: number, type?: WordTypeEnum | null, translation: string }> };

export type NewCanvasBoardFragment = { __typename?: 'CanvasBoard', _id: string, title: string };

export type NewStatusFragment = { __typename?: 'KanbanTasks', _id: string, title: string, color?: string | null, tasksCount: number, actions: Array<{ __typename?: 'Action', _id: string }> };

export const TaskFragmentFragmentDoc = gql`
    fragment TaskFragment on Action {
  _id
  workspaceId
  title
  priority
  storyPoints
  description
  dueDate
  startDate
  statusId
  createdAt
  updatedAt
  completedAt
  rank
  totalSubtasksCount
  isUrgent
  isArchived
  projectId {
    _id
    color
    completedStatusId
    unstagedStatusId
  }
  assignee {
    _id
    email
    fullName
    profilePicture
  }
  parentId {
    _id
    title
  }
  attachments {
    _id
    urls {
      original
      thumbnail
      thumbnailPreview
    }
    size
    type
    fileName
    createdAt
  }
  labels {
    _id
    title
    color
  }
  taskAdvancedFields {
    _id
    type
    title
    value
  }
}
    `;
export const AssigneeFieldsFragmentDoc = gql`
    fragment AssigneeFields on Action {
  assignee {
    _id
    email
    fullName
    profilePicture
  }
}
    `;
export const NotificationFragmentFragmentDoc = gql`
    fragment NotificationFragment on Notification {
  _id
  title
  message
  isRead
  isPrivate
  createdAt
  projectId
  type
  user {
    _id
    fullName
    profilePicture
  }
}
    `;
export const NewCanvasBoardFragmentDoc = gql`
    fragment NewCanvasBoard on CanvasBoard {
  _id
  title
}
    `;
export const NewStatusFragmentDoc = gql`
    fragment NewStatus on KanbanTasks {
  _id
  title
  color
  actions {
    _id
  }
  tasksCount
}
    `;
export const CreateTaskDocument = gql`
    mutation CreateTask($workspaceId: ID!, $projectId: ID!, $title: String!, $statusId: ID, $parentId: ID, $dueDate: Date) {
  createTask(
    workspaceId: $workspaceId
    projectId: $projectId
    statusId: $statusId
    title: $title
    parentId: $parentId
    dueDate: $dueDate
  ) {
    _id
    workspaceId
    title
    priority
    statusId
    dueDate
    startDate
    storyPoints
    projectId {
      _id
      color
      completedStatusId
      unstagedStatusId
    }
    assignee {
      _id
      email
      fullName
      profilePicture
    }
    parentId {
      _id
      title
    }
    labels {
      _id
      title
      color
    }
    attachments {
      _id
      urls {
        original
        thumbnail
        thumbnailPreview
      }
      fileName
    }
  }
}
    `;
export type CreateTaskMutationFn = Apollo.MutationFunction<CreateTaskMutation, CreateTaskMutationVariables>;

/**
 * __useCreateTaskMutation__
 *
 * To run a mutation, you first call `useCreateTaskMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreateTaskMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createTaskMutation, { data, loading, error }] = useCreateTaskMutation({
 *   variables: {
 *      workspaceId: // value for 'workspaceId'
 *      projectId: // value for 'projectId'
 *      title: // value for 'title'
 *      statusId: // value for 'statusId'
 *      parentId: // value for 'parentId'
 *      dueDate: // value for 'dueDate'
 *   },
 * });
 */
export function useCreateTaskMutation(baseOptions?: Apollo.MutationHookOptions<CreateTaskMutation, CreateTaskMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<CreateTaskMutation, CreateTaskMutationVariables>(CreateTaskDocument, options);
      }
export type CreateTaskMutationHookResult = ReturnType<typeof useCreateTaskMutation>;
export type CreateTaskMutationResult = Apollo.MutationResult<CreateTaskMutation>;
export type CreateTaskMutationOptions = Apollo.BaseMutationOptions<CreateTaskMutation, CreateTaskMutationVariables>;
export const DeleteTasksDocument = gql`
    mutation DeleteTasks($taskIds: [ID!]!, $projectId: ID!) {
  deleteTasks(taskIds: $taskIds, projectId: $projectId) {
    _id
  }
}
    `;
export type DeleteTasksMutationFn = Apollo.MutationFunction<DeleteTasksMutation, DeleteTasksMutationVariables>;

/**
 * __useDeleteTasksMutation__
 *
 * To run a mutation, you first call `useDeleteTasksMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useDeleteTasksMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [deleteTasksMutation, { data, loading, error }] = useDeleteTasksMutation({
 *   variables: {
 *      taskIds: // value for 'taskIds'
 *      projectId: // value for 'projectId'
 *   },
 * });
 */
export function useDeleteTasksMutation(baseOptions?: Apollo.MutationHookOptions<DeleteTasksMutation, DeleteTasksMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<DeleteTasksMutation, DeleteTasksMutationVariables>(DeleteTasksDocument, options);
      }
export type DeleteTasksMutationHookResult = ReturnType<typeof useDeleteTasksMutation>;
export type DeleteTasksMutationResult = Apollo.MutationResult<DeleteTasksMutation>;
export type DeleteTasksMutationOptions = Apollo.BaseMutationOptions<DeleteTasksMutation, DeleteTasksMutationVariables>;
export const AddAttachmentsToTaskDocument = gql`
    mutation AddAttachmentsToTask($taskId: ID!, $projectId: ID!, $attachments: [Upload!]!) {
  addAttachmentsToTask(
    input: {taskId: $taskId, projectId: $projectId, attachments: $attachments}
  ) {
    _id
    attachments {
      _id
      urls {
        original
        thumbnail
        thumbnailPreview
      }
      fileName
      size
      type
    }
  }
}
    `;
export type AddAttachmentsToTaskMutationFn = Apollo.MutationFunction<AddAttachmentsToTaskMutation, AddAttachmentsToTaskMutationVariables>;

/**
 * __useAddAttachmentsToTaskMutation__
 *
 * To run a mutation, you first call `useAddAttachmentsToTaskMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useAddAttachmentsToTaskMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [addAttachmentsToTaskMutation, { data, loading, error }] = useAddAttachmentsToTaskMutation({
 *   variables: {
 *      taskId: // value for 'taskId'
 *      projectId: // value for 'projectId'
 *      attachments: // value for 'attachments'
 *   },
 * });
 */
export function useAddAttachmentsToTaskMutation(baseOptions?: Apollo.MutationHookOptions<AddAttachmentsToTaskMutation, AddAttachmentsToTaskMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<AddAttachmentsToTaskMutation, AddAttachmentsToTaskMutationVariables>(AddAttachmentsToTaskDocument, options);
      }
export type AddAttachmentsToTaskMutationHookResult = ReturnType<typeof useAddAttachmentsToTaskMutation>;
export type AddAttachmentsToTaskMutationResult = Apollo.MutationResult<AddAttachmentsToTaskMutation>;
export type AddAttachmentsToTaskMutationOptions = Apollo.BaseMutationOptions<AddAttachmentsToTaskMutation, AddAttachmentsToTaskMutationVariables>;
export const DuplicateTaskDocument = gql`
    mutation DuplicateTask($taskId: ID!) {
  duplicateTask(taskId: $taskId) {
    ...TaskFragment
  }
}
    ${TaskFragmentFragmentDoc}`;
export type DuplicateTaskMutationFn = Apollo.MutationFunction<DuplicateTaskMutation, DuplicateTaskMutationVariables>;

/**
 * __useDuplicateTaskMutation__
 *
 * To run a mutation, you first call `useDuplicateTaskMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useDuplicateTaskMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [duplicateTaskMutation, { data, loading, error }] = useDuplicateTaskMutation({
 *   variables: {
 *      taskId: // value for 'taskId'
 *   },
 * });
 */
export function useDuplicateTaskMutation(baseOptions?: Apollo.MutationHookOptions<DuplicateTaskMutation, DuplicateTaskMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<DuplicateTaskMutation, DuplicateTaskMutationVariables>(DuplicateTaskDocument, options);
      }
export type DuplicateTaskMutationHookResult = ReturnType<typeof useDuplicateTaskMutation>;
export type DuplicateTaskMutationResult = Apollo.MutationResult<DuplicateTaskMutation>;
export type DuplicateTaskMutationOptions = Apollo.BaseMutationOptions<DuplicateTaskMutation, DuplicateTaskMutationVariables>;
export const UpdateTaskDocument = gql`
    mutation UpdateTask($updateTaskInput: UpdateTaskInput!) {
  updateTask(updateTaskInput: $updateTaskInput) {
    ...TaskFragment
  }
}
    ${TaskFragmentFragmentDoc}`;
export type UpdateTaskMutationFn = Apollo.MutationFunction<UpdateTaskMutation, UpdateTaskMutationVariables>;

/**
 * __useUpdateTaskMutation__
 *
 * To run a mutation, you first call `useUpdateTaskMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateTaskMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateTaskMutation, { data, loading, error }] = useUpdateTaskMutation({
 *   variables: {
 *      updateTaskInput: // value for 'updateTaskInput'
 *   },
 * });
 */
export function useUpdateTaskMutation(baseOptions?: Apollo.MutationHookOptions<UpdateTaskMutation, UpdateTaskMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<UpdateTaskMutation, UpdateTaskMutationVariables>(UpdateTaskDocument, options);
      }
export type UpdateTaskMutationHookResult = ReturnType<typeof useUpdateTaskMutation>;
export type UpdateTaskMutationResult = Apollo.MutationResult<UpdateTaskMutation>;
export type UpdateTaskMutationOptions = Apollo.BaseMutationOptions<UpdateTaskMutation, UpdateTaskMutationVariables>;
export const ApplyTaskTemplateDocument = gql`
    mutation ApplyTaskTemplate($taskId: ID!, $templateId: ID!) {
  applyTaskTemplate(taskId: $taskId, templateId: $templateId) {
    _id
    title
    description
    dueDate
    isUrgent
    priority
    startDate
    storyPoints
    assignee {
      _id
      email
      fullName
      profilePicture
    }
  }
}
    `;
export type ApplyTaskTemplateMutationFn = Apollo.MutationFunction<ApplyTaskTemplateMutation, ApplyTaskTemplateMutationVariables>;

/**
 * __useApplyTaskTemplateMutation__
 *
 * To run a mutation, you first call `useApplyTaskTemplateMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useApplyTaskTemplateMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [applyTaskTemplateMutation, { data, loading, error }] = useApplyTaskTemplateMutation({
 *   variables: {
 *      taskId: // value for 'taskId'
 *      templateId: // value for 'templateId'
 *   },
 * });
 */
export function useApplyTaskTemplateMutation(baseOptions?: Apollo.MutationHookOptions<ApplyTaskTemplateMutation, ApplyTaskTemplateMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<ApplyTaskTemplateMutation, ApplyTaskTemplateMutationVariables>(ApplyTaskTemplateDocument, options);
      }
export type ApplyTaskTemplateMutationHookResult = ReturnType<typeof useApplyTaskTemplateMutation>;
export type ApplyTaskTemplateMutationResult = Apollo.MutationResult<ApplyTaskTemplateMutation>;
export type ApplyTaskTemplateMutationOptions = Apollo.BaseMutationOptions<ApplyTaskTemplateMutation, ApplyTaskTemplateMutationVariables>;
export const TaskCountsDocument = gql`
    query TaskCounts($projectId: ID!, $workspaceId: ID!) {
  taskCounts(projectId: $projectId) {
    incompleteTasks
    completedTasks
  }
  taskByStatusWithCount(projectId: $projectId) {
    title
    tasksCount
  }
  dashboardTasksByPeriod(projectId: $projectId, workspaceId: $workspaceId) {
    day
    count
    tasks {
      _id
      title
    }
  }
}
    `;

/**
 * __useTaskCountsQuery__
 *
 * To run a query within a React component, call `useTaskCountsQuery` and pass it any options that fit your needs.
 * When your component renders, `useTaskCountsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useTaskCountsQuery({
 *   variables: {
 *      projectId: // value for 'projectId'
 *      workspaceId: // value for 'workspaceId'
 *   },
 * });
 */
export function useTaskCountsQuery(baseOptions: Apollo.QueryHookOptions<TaskCountsQuery, TaskCountsQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<TaskCountsQuery, TaskCountsQueryVariables>(TaskCountsDocument, options);
      }
export function useTaskCountsLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<TaskCountsQuery, TaskCountsQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<TaskCountsQuery, TaskCountsQueryVariables>(TaskCountsDocument, options);
        }
export type TaskCountsQueryHookResult = ReturnType<typeof useTaskCountsQuery>;
export type TaskCountsLazyQueryHookResult = ReturnType<typeof useTaskCountsLazyQuery>;
export type TaskCountsQueryResult = Apollo.QueryResult<TaskCountsQuery, TaskCountsQueryVariables>;
export const ProjectTasksCountDocument = gql`
    query ProjectTasksCount($projectId: ID!) {
  taskCounts(projectId: $projectId) {
    incompleteTasks
    completedTasks
    deletedTasks
    archivedTasks
  }
}
    `;

/**
 * __useProjectTasksCountQuery__
 *
 * To run a query within a React component, call `useProjectTasksCountQuery` and pass it any options that fit your needs.
 * When your component renders, `useProjectTasksCountQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useProjectTasksCountQuery({
 *   variables: {
 *      projectId: // value for 'projectId'
 *   },
 * });
 */
export function useProjectTasksCountQuery(baseOptions: Apollo.QueryHookOptions<ProjectTasksCountQuery, ProjectTasksCountQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<ProjectTasksCountQuery, ProjectTasksCountQueryVariables>(ProjectTasksCountDocument, options);
      }
export function useProjectTasksCountLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<ProjectTasksCountQuery, ProjectTasksCountQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<ProjectTasksCountQuery, ProjectTasksCountQueryVariables>(ProjectTasksCountDocument, options);
        }
export type ProjectTasksCountQueryHookResult = ReturnType<typeof useProjectTasksCountQuery>;
export type ProjectTasksCountLazyQueryHookResult = ReturnType<typeof useProjectTasksCountLazyQuery>;
export type ProjectTasksCountQueryResult = Apollo.QueryResult<ProjectTasksCountQuery, ProjectTasksCountQueryVariables>;
export const TasksDocument = gql`
    query Tasks($filter: TaskFilter, $orderBy: TaskOrderBy, $pagination: PaginationInput) {
  tasks(filter: $filter, orderBy: $orderBy, pagination: $pagination) {
    items {
      ...TaskFragment
    }
    totalCount
    hasMore
  }
}
    ${TaskFragmentFragmentDoc}`;

/**
 * __useTasksQuery__
 *
 * To run a query within a React component, call `useTasksQuery` and pass it any options that fit your needs.
 * When your component renders, `useTasksQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useTasksQuery({
 *   variables: {
 *      filter: // value for 'filter'
 *      orderBy: // value for 'orderBy'
 *      pagination: // value for 'pagination'
 *   },
 * });
 */
export function useTasksQuery(baseOptions?: Apollo.QueryHookOptions<TasksQuery, TasksQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<TasksQuery, TasksQueryVariables>(TasksDocument, options);
      }
export function useTasksLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<TasksQuery, TasksQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<TasksQuery, TasksQueryVariables>(TasksDocument, options);
        }
export type TasksQueryHookResult = ReturnType<typeof useTasksQuery>;
export type TasksLazyQueryHookResult = ReturnType<typeof useTasksLazyQuery>;
export type TasksQueryResult = Apollo.QueryResult<TasksQuery, TasksQueryVariables>;
export const TrashBinTasksDocument = gql`
    query TrashBinTasks($workspaceId: ID!) {
  trashBinTasks(workspaceId: $workspaceId) {
    _id
    title
    deletedAt
    deletedBy {
      _id
      fullName
      profilePicture
    }
    projectId {
      _id
      title
      color
    }
  }
}
    `;

/**
 * __useTrashBinTasksQuery__
 *
 * To run a query within a React component, call `useTrashBinTasksQuery` and pass it any options that fit your needs.
 * When your component renders, `useTrashBinTasksQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useTrashBinTasksQuery({
 *   variables: {
 *      workspaceId: // value for 'workspaceId'
 *   },
 * });
 */
export function useTrashBinTasksQuery(baseOptions: Apollo.QueryHookOptions<TrashBinTasksQuery, TrashBinTasksQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<TrashBinTasksQuery, TrashBinTasksQueryVariables>(TrashBinTasksDocument, options);
      }
export function useTrashBinTasksLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<TrashBinTasksQuery, TrashBinTasksQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<TrashBinTasksQuery, TrashBinTasksQueryVariables>(TrashBinTasksDocument, options);
        }
export type TrashBinTasksQueryHookResult = ReturnType<typeof useTrashBinTasksQuery>;
export type TrashBinTasksLazyQueryHookResult = ReturnType<typeof useTrashBinTasksLazyQuery>;
export type TrashBinTasksQueryResult = Apollo.QueryResult<TrashBinTasksQuery, TrashBinTasksQueryVariables>;
export const KanbanTasksDocument = gql`
    query KanbanTasks($projectId: ID!, $priority: String, $labels: [ID!], $statusIds: [ID!], $dueDate: String, $assignees: [ID!]) {
  kanbanTasks(
    projectId: $projectId
    priority: $priority
    labels: $labels
    statusIds: $statusIds
    dueDate: $dueDate
    assignees: $assignees
  ) {
    _id
    title
    tasksCount
    color
    actions {
      ...TaskFragment
    }
  }
}
    ${TaskFragmentFragmentDoc}`;

/**
 * __useKanbanTasksQuery__
 *
 * To run a query within a React component, call `useKanbanTasksQuery` and pass it any options that fit your needs.
 * When your component renders, `useKanbanTasksQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useKanbanTasksQuery({
 *   variables: {
 *      projectId: // value for 'projectId'
 *      priority: // value for 'priority'
 *      labels: // value for 'labels'
 *      statusIds: // value for 'statusIds'
 *      dueDate: // value for 'dueDate'
 *      assignees: // value for 'assignees'
 *   },
 * });
 */
export function useKanbanTasksQuery(baseOptions: Apollo.QueryHookOptions<KanbanTasksQuery, KanbanTasksQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<KanbanTasksQuery, KanbanTasksQueryVariables>(KanbanTasksDocument, options);
      }
export function useKanbanTasksLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<KanbanTasksQuery, KanbanTasksQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<KanbanTasksQuery, KanbanTasksQueryVariables>(KanbanTasksDocument, options);
        }
export type KanbanTasksQueryHookResult = ReturnType<typeof useKanbanTasksQuery>;
export type KanbanTasksLazyQueryHookResult = ReturnType<typeof useKanbanTasksLazyQuery>;
export type KanbanTasksQueryResult = Apollo.QueryResult<KanbanTasksQuery, KanbanTasksQueryVariables>;
export const GetActionDocument = gql`
    query GetAction($id: ID!) {
  action(id: $id) {
    ...TaskFragment
  }
}
    ${TaskFragmentFragmentDoc}`;

/**
 * __useGetActionQuery__
 *
 * To run a query within a React component, call `useGetActionQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetActionQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetActionQuery({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useGetActionQuery(baseOptions: Apollo.QueryHookOptions<GetActionQuery, GetActionQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetActionQuery, GetActionQueryVariables>(GetActionDocument, options);
      }
export function useGetActionLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetActionQuery, GetActionQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetActionQuery, GetActionQueryVariables>(GetActionDocument, options);
        }
export type GetActionQueryHookResult = ReturnType<typeof useGetActionQuery>;
export type GetActionLazyQueryHookResult = ReturnType<typeof useGetActionLazyQuery>;
export type GetActionQueryResult = Apollo.QueryResult<GetActionQuery, GetActionQueryVariables>;
export const TaskHistoryDocument = gql`
    query TaskHistory($taskId: ID!) {
  taskHistory(taskId: $taskId) {
    _id
    body
    createdAt
    taskId
  }
}
    `;

/**
 * __useTaskHistoryQuery__
 *
 * To run a query within a React component, call `useTaskHistoryQuery` and pass it any options that fit your needs.
 * When your component renders, `useTaskHistoryQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useTaskHistoryQuery({
 *   variables: {
 *      taskId: // value for 'taskId'
 *   },
 * });
 */
export function useTaskHistoryQuery(baseOptions: Apollo.QueryHookOptions<TaskHistoryQuery, TaskHistoryQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<TaskHistoryQuery, TaskHistoryQueryVariables>(TaskHistoryDocument, options);
      }
export function useTaskHistoryLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<TaskHistoryQuery, TaskHistoryQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<TaskHistoryQuery, TaskHistoryQueryVariables>(TaskHistoryDocument, options);
        }
export type TaskHistoryQueryHookResult = ReturnType<typeof useTaskHistoryQuery>;
export type TaskHistoryLazyQueryHookResult = ReturnType<typeof useTaskHistoryLazyQuery>;
export type TaskHistoryQueryResult = Apollo.QueryResult<TaskHistoryQuery, TaskHistoryQueryVariables>;
export const OnCreateTaskHistoryDocument = gql`
    subscription onCreateTaskHistory {
  onCreateTaskHistory {
    _id
    body
    taskId
    createdAt
  }
}
    `;

/**
 * __useOnCreateTaskHistorySubscription__
 *
 * To run a query within a React component, call `useOnCreateTaskHistorySubscription` and pass it any options that fit your needs.
 * When your component renders, `useOnCreateTaskHistorySubscription` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the subscription, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useOnCreateTaskHistorySubscription({
 *   variables: {
 *   },
 * });
 */
export function useOnCreateTaskHistorySubscription(baseOptions?: Apollo.SubscriptionHookOptions<OnCreateTaskHistorySubscription, OnCreateTaskHistorySubscriptionVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useSubscription<OnCreateTaskHistorySubscription, OnCreateTaskHistorySubscriptionVariables>(OnCreateTaskHistoryDocument, options);
      }
export type OnCreateTaskHistorySubscriptionHookResult = ReturnType<typeof useOnCreateTaskHistorySubscription>;
export type OnCreateTaskHistorySubscriptionResult = Apollo.SubscriptionResult<OnCreateTaskHistorySubscription>;
export const OnUpdateTaskDocument = gql`
    subscription onUpdateTask {
  onUpdateTask {
    ...TaskFragment
  }
}
    ${TaskFragmentFragmentDoc}`;

/**
 * __useOnUpdateTaskSubscription__
 *
 * To run a query within a React component, call `useOnUpdateTaskSubscription` and pass it any options that fit your needs.
 * When your component renders, `useOnUpdateTaskSubscription` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the subscription, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useOnUpdateTaskSubscription({
 *   variables: {
 *   },
 * });
 */
export function useOnUpdateTaskSubscription(baseOptions?: Apollo.SubscriptionHookOptions<OnUpdateTaskSubscription, OnUpdateTaskSubscriptionVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useSubscription<OnUpdateTaskSubscription, OnUpdateTaskSubscriptionVariables>(OnUpdateTaskDocument, options);
      }
export type OnUpdateTaskSubscriptionHookResult = ReturnType<typeof useOnUpdateTaskSubscription>;
export type OnUpdateTaskSubscriptionResult = Apollo.SubscriptionResult<OnUpdateTaskSubscription>;
export const OnCreateTaskDocument = gql`
    subscription onCreateTask {
  onCreateTask {
    ...TaskFragment
  }
}
    ${TaskFragmentFragmentDoc}`;

/**
 * __useOnCreateTaskSubscription__
 *
 * To run a query within a React component, call `useOnCreateTaskSubscription` and pass it any options that fit your needs.
 * When your component renders, `useOnCreateTaskSubscription` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the subscription, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useOnCreateTaskSubscription({
 *   variables: {
 *   },
 * });
 */
export function useOnCreateTaskSubscription(baseOptions?: Apollo.SubscriptionHookOptions<OnCreateTaskSubscription, OnCreateTaskSubscriptionVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useSubscription<OnCreateTaskSubscription, OnCreateTaskSubscriptionVariables>(OnCreateTaskDocument, options);
      }
export type OnCreateTaskSubscriptionHookResult = ReturnType<typeof useOnCreateTaskSubscription>;
export type OnCreateTaskSubscriptionResult = Apollo.SubscriptionResult<OnCreateTaskSubscription>;
export const ProjectTasksCountSubDocument = gql`
    subscription ProjectTasksCountSub {
  projectTasksCount {
    incompleteTasks
    completedTasks
    deletedTasks
    archivedTasks
  }
}
    `;

/**
 * __useProjectTasksCountSubSubscription__
 *
 * To run a query within a React component, call `useProjectTasksCountSubSubscription` and pass it any options that fit your needs.
 * When your component renders, `useProjectTasksCountSubSubscription` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the subscription, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useProjectTasksCountSubSubscription({
 *   variables: {
 *   },
 * });
 */
export function useProjectTasksCountSubSubscription(baseOptions?: Apollo.SubscriptionHookOptions<ProjectTasksCountSubSubscription, ProjectTasksCountSubSubscriptionVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useSubscription<ProjectTasksCountSubSubscription, ProjectTasksCountSubSubscriptionVariables>(ProjectTasksCountSubDocument, options);
      }
export type ProjectTasksCountSubSubscriptionHookResult = ReturnType<typeof useProjectTasksCountSubSubscription>;
export type ProjectTasksCountSubSubscriptionResult = Apollo.SubscriptionResult<ProjectTasksCountSubSubscription>;
export const OnDeleteTasksDocument = gql`
    subscription OnDeleteTasks {
  onDeleteTasks {
    _id
  }
}
    `;

/**
 * __useOnDeleteTasksSubscription__
 *
 * To run a query within a React component, call `useOnDeleteTasksSubscription` and pass it any options that fit your needs.
 * When your component renders, `useOnDeleteTasksSubscription` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the subscription, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useOnDeleteTasksSubscription({
 *   variables: {
 *   },
 * });
 */
export function useOnDeleteTasksSubscription(baseOptions?: Apollo.SubscriptionHookOptions<OnDeleteTasksSubscription, OnDeleteTasksSubscriptionVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useSubscription<OnDeleteTasksSubscription, OnDeleteTasksSubscriptionVariables>(OnDeleteTasksDocument, options);
      }
export type OnDeleteTasksSubscriptionHookResult = ReturnType<typeof useOnDeleteTasksSubscription>;
export type OnDeleteTasksSubscriptionResult = Apollo.SubscriptionResult<OnDeleteTasksSubscription>;
export const SingUpDocument = gql`
    mutation SingUp($firstName: String!, $lastName: String!, $email: String!, $password: String!, $workspaceName: String!) {
  signup(
    input: {firstName: $firstName, lastName: $lastName, email: $email, password: $password, workspaceName: $workspaceName}
  ) {
    token
  }
}
    `;
export type SingUpMutationFn = Apollo.MutationFunction<SingUpMutation, SingUpMutationVariables>;

/**
 * __useSingUpMutation__
 *
 * To run a mutation, you first call `useSingUpMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useSingUpMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [singUpMutation, { data, loading, error }] = useSingUpMutation({
 *   variables: {
 *      firstName: // value for 'firstName'
 *      lastName: // value for 'lastName'
 *      email: // value for 'email'
 *      password: // value for 'password'
 *      workspaceName: // value for 'workspaceName'
 *   },
 * });
 */
export function useSingUpMutation(baseOptions?: Apollo.MutationHookOptions<SingUpMutation, SingUpMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<SingUpMutation, SingUpMutationVariables>(SingUpDocument, options);
      }
export type SingUpMutationHookResult = ReturnType<typeof useSingUpMutation>;
export type SingUpMutationResult = Apollo.MutationResult<SingUpMutation>;
export type SingUpMutationOptions = Apollo.BaseMutationOptions<SingUpMutation, SingUpMutationVariables>;
export const LoginDocument = gql`
    mutation Login($email: String!, $password: String!) {
  login(user: {email: $email, password: $password}) {
    user {
      _id
      firstName
      lastName
      fullName
      role
      email
      profilePicture
      workspaceId
    }
    token
  }
}
    `;
export type LoginMutationFn = Apollo.MutationFunction<LoginMutation, LoginMutationVariables>;

/**
 * __useLoginMutation__
 *
 * To run a mutation, you first call `useLoginMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useLoginMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [loginMutation, { data, loading, error }] = useLoginMutation({
 *   variables: {
 *      email: // value for 'email'
 *      password: // value for 'password'
 *   },
 * });
 */
export function useLoginMutation(baseOptions?: Apollo.MutationHookOptions<LoginMutation, LoginMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<LoginMutation, LoginMutationVariables>(LoginDocument, options);
      }
export type LoginMutationHookResult = ReturnType<typeof useLoginMutation>;
export type LoginMutationResult = Apollo.MutationResult<LoginMutation>;
export type LoginMutationOptions = Apollo.BaseMutationOptions<LoginMutation, LoginMutationVariables>;
export const GoogleLoginDocument = gql`
    mutation GoogleLogin($token: String!) {
  googleLogin(token: $token) {
    token
    user {
      _id
      firstName
      lastName
      fullName
      role
      email
      profilePicture
      workspaceId
    }
  }
}
    `;
export type GoogleLoginMutationFn = Apollo.MutationFunction<GoogleLoginMutation, GoogleLoginMutationVariables>;

/**
 * __useGoogleLoginMutation__
 *
 * To run a mutation, you first call `useGoogleLoginMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useGoogleLoginMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [googleLoginMutation, { data, loading, error }] = useGoogleLoginMutation({
 *   variables: {
 *      token: // value for 'token'
 *   },
 * });
 */
export function useGoogleLoginMutation(baseOptions?: Apollo.MutationHookOptions<GoogleLoginMutation, GoogleLoginMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<GoogleLoginMutation, GoogleLoginMutationVariables>(GoogleLoginDocument, options);
      }
export type GoogleLoginMutationHookResult = ReturnType<typeof useGoogleLoginMutation>;
export type GoogleLoginMutationResult = Apollo.MutationResult<GoogleLoginMutation>;
export type GoogleLoginMutationOptions = Apollo.BaseMutationOptions<GoogleLoginMutation, GoogleLoginMutationVariables>;
export const UploadBookDocument = gql`
    mutation UploadBook($input: UploadBookInput!) {
  uploadBook(input: $input) {
    _id
  }
}
    `;
export type UploadBookMutationFn = Apollo.MutationFunction<UploadBookMutation, UploadBookMutationVariables>;

/**
 * __useUploadBookMutation__
 *
 * To run a mutation, you first call `useUploadBookMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUploadBookMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [uploadBookMutation, { data, loading, error }] = useUploadBookMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useUploadBookMutation(baseOptions?: Apollo.MutationHookOptions<UploadBookMutation, UploadBookMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<UploadBookMutation, UploadBookMutationVariables>(UploadBookDocument, options);
      }
export type UploadBookMutationHookResult = ReturnType<typeof useUploadBookMutation>;
export type UploadBookMutationResult = Apollo.MutationResult<UploadBookMutation>;
export type UploadBookMutationOptions = Apollo.BaseMutationOptions<UploadBookMutation, UploadBookMutationVariables>;
export const UpdateUserListeningDocument = gql`
    mutation UpdateUserListening($chapterId: ID!, $listenedTime: Float!) {
  updateUserListening(input: {chapterId: $chapterId, listenedTime: $listenedTime}) {
    _id
    listening {
      chapterId
      listenedTime
    }
  }
}
    `;
export type UpdateUserListeningMutationFn = Apollo.MutationFunction<UpdateUserListeningMutation, UpdateUserListeningMutationVariables>;

/**
 * __useUpdateUserListeningMutation__
 *
 * To run a mutation, you first call `useUpdateUserListeningMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateUserListeningMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateUserListeningMutation, { data, loading, error }] = useUpdateUserListeningMutation({
 *   variables: {
 *      chapterId: // value for 'chapterId'
 *      listenedTime: // value for 'listenedTime'
 *   },
 * });
 */
export function useUpdateUserListeningMutation(baseOptions?: Apollo.MutationHookOptions<UpdateUserListeningMutation, UpdateUserListeningMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<UpdateUserListeningMutation, UpdateUserListeningMutationVariables>(UpdateUserListeningDocument, options);
      }
export type UpdateUserListeningMutationHookResult = ReturnType<typeof useUpdateUserListeningMutation>;
export type UpdateUserListeningMutationResult = Apollo.MutationResult<UpdateUserListeningMutation>;
export type UpdateUserListeningMutationOptions = Apollo.BaseMutationOptions<UpdateUserListeningMutation, UpdateUserListeningMutationVariables>;
export const BooksDocument = gql`
    query Books($workspaceId: ID!) {
  books(workspaceId: $workspaceId) {
    _id
    title
    poster
    chapters {
      id
      title
      url
      time
    }
  }
}
    `;

/**
 * __useBooksQuery__
 *
 * To run a query within a React component, call `useBooksQuery` and pass it any options that fit your needs.
 * When your component renders, `useBooksQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useBooksQuery({
 *   variables: {
 *      workspaceId: // value for 'workspaceId'
 *   },
 * });
 */
export function useBooksQuery(baseOptions: Apollo.QueryHookOptions<BooksQuery, BooksQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<BooksQuery, BooksQueryVariables>(BooksDocument, options);
      }
export function useBooksLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<BooksQuery, BooksQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<BooksQuery, BooksQueryVariables>(BooksDocument, options);
        }
export type BooksQueryHookResult = ReturnType<typeof useBooksQuery>;
export type BooksLazyQueryHookResult = ReturnType<typeof useBooksLazyQuery>;
export type BooksQueryResult = Apollo.QueryResult<BooksQuery, BooksQueryVariables>;
export const CreateCanvasBoardDocument = gql`
    mutation CreateCanvasBoard($workspaceId: ID!, $title: String!, $previewImage: String) {
  createCanvasBoard(
    workspaceId: $workspaceId
    title: $title
    previewImage: $previewImage
  ) {
    _id
    workspaceId
    title
    createdBy {
      _id
      email
      fullName
    }
    previewImage
  }
}
    `;
export type CreateCanvasBoardMutationFn = Apollo.MutationFunction<CreateCanvasBoardMutation, CreateCanvasBoardMutationVariables>;

/**
 * __useCreateCanvasBoardMutation__
 *
 * To run a mutation, you first call `useCreateCanvasBoardMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreateCanvasBoardMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createCanvasBoardMutation, { data, loading, error }] = useCreateCanvasBoardMutation({
 *   variables: {
 *      workspaceId: // value for 'workspaceId'
 *      title: // value for 'title'
 *      previewImage: // value for 'previewImage'
 *   },
 * });
 */
export function useCreateCanvasBoardMutation(baseOptions?: Apollo.MutationHookOptions<CreateCanvasBoardMutation, CreateCanvasBoardMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<CreateCanvasBoardMutation, CreateCanvasBoardMutationVariables>(CreateCanvasBoardDocument, options);
      }
export type CreateCanvasBoardMutationHookResult = ReturnType<typeof useCreateCanvasBoardMutation>;
export type CreateCanvasBoardMutationResult = Apollo.MutationResult<CreateCanvasBoardMutation>;
export type CreateCanvasBoardMutationOptions = Apollo.BaseMutationOptions<CreateCanvasBoardMutation, CreateCanvasBoardMutationVariables>;
export const DeleteCanvasBoardDocument = gql`
    mutation DeleteCanvasBoard($workspaceId: ID!, $boardId: ID!) {
  deleteCanvasBoard(workspaceId: $workspaceId, boardId: $boardId) {
    _id
  }
}
    `;
export type DeleteCanvasBoardMutationFn = Apollo.MutationFunction<DeleteCanvasBoardMutation, DeleteCanvasBoardMutationVariables>;

/**
 * __useDeleteCanvasBoardMutation__
 *
 * To run a mutation, you first call `useDeleteCanvasBoardMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useDeleteCanvasBoardMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [deleteCanvasBoardMutation, { data, loading, error }] = useDeleteCanvasBoardMutation({
 *   variables: {
 *      workspaceId: // value for 'workspaceId'
 *      boardId: // value for 'boardId'
 *   },
 * });
 */
export function useDeleteCanvasBoardMutation(baseOptions?: Apollo.MutationHookOptions<DeleteCanvasBoardMutation, DeleteCanvasBoardMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<DeleteCanvasBoardMutation, DeleteCanvasBoardMutationVariables>(DeleteCanvasBoardDocument, options);
      }
export type DeleteCanvasBoardMutationHookResult = ReturnType<typeof useDeleteCanvasBoardMutation>;
export type DeleteCanvasBoardMutationResult = Apollo.MutationResult<DeleteCanvasBoardMutation>;
export type DeleteCanvasBoardMutationOptions = Apollo.BaseMutationOptions<DeleteCanvasBoardMutation, DeleteCanvasBoardMutationVariables>;
export const UpdateCanvasBoardDocument = gql`
    mutation UpdateCanvasBoard($boardId: ID!, $title: String, $previewImage: String) {
  updateCanvasBoard(boardId: $boardId, title: $title, previewImage: $previewImage) {
    _id
    title
    previewImage
  }
}
    `;
export type UpdateCanvasBoardMutationFn = Apollo.MutationFunction<UpdateCanvasBoardMutation, UpdateCanvasBoardMutationVariables>;

/**
 * __useUpdateCanvasBoardMutation__
 *
 * To run a mutation, you first call `useUpdateCanvasBoardMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateCanvasBoardMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateCanvasBoardMutation, { data, loading, error }] = useUpdateCanvasBoardMutation({
 *   variables: {
 *      boardId: // value for 'boardId'
 *      title: // value for 'title'
 *      previewImage: // value for 'previewImage'
 *   },
 * });
 */
export function useUpdateCanvasBoardMutation(baseOptions?: Apollo.MutationHookOptions<UpdateCanvasBoardMutation, UpdateCanvasBoardMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<UpdateCanvasBoardMutation, UpdateCanvasBoardMutationVariables>(UpdateCanvasBoardDocument, options);
      }
export type UpdateCanvasBoardMutationHookResult = ReturnType<typeof useUpdateCanvasBoardMutation>;
export type UpdateCanvasBoardMutationResult = Apollo.MutationResult<UpdateCanvasBoardMutation>;
export type UpdateCanvasBoardMutationOptions = Apollo.BaseMutationOptions<UpdateCanvasBoardMutation, UpdateCanvasBoardMutationVariables>;
export const CanvasBoardsDocument = gql`
    query CanvasBoards($workspaceId: ID!) {
  canvasBoards(workspaceId: $workspaceId) {
    _id
    title
    previewImage
    createdBy {
      _id
      fullName
    }
    createdAt
    updatedAt
  }
}
    `;

/**
 * __useCanvasBoardsQuery__
 *
 * To run a query within a React component, call `useCanvasBoardsQuery` and pass it any options that fit your needs.
 * When your component renders, `useCanvasBoardsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useCanvasBoardsQuery({
 *   variables: {
 *      workspaceId: // value for 'workspaceId'
 *   },
 * });
 */
export function useCanvasBoardsQuery(baseOptions: Apollo.QueryHookOptions<CanvasBoardsQuery, CanvasBoardsQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<CanvasBoardsQuery, CanvasBoardsQueryVariables>(CanvasBoardsDocument, options);
      }
export function useCanvasBoardsLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<CanvasBoardsQuery, CanvasBoardsQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<CanvasBoardsQuery, CanvasBoardsQueryVariables>(CanvasBoardsDocument, options);
        }
export type CanvasBoardsQueryHookResult = ReturnType<typeof useCanvasBoardsQuery>;
export type CanvasBoardsLazyQueryHookResult = ReturnType<typeof useCanvasBoardsLazyQuery>;
export type CanvasBoardsQueryResult = Apollo.QueryResult<CanvasBoardsQuery, CanvasBoardsQueryVariables>;
export const CreateCommentDocument = gql`
    mutation CreateComment($text: String, $taskId: ID!, $type: CommentTypeEnum!, $sticker: String, $projectId: ID!) {
  createComment(
    input: {text: $text, taskId: $taskId, type: $type, sticker: $sticker, projectId: $projectId}
  ) {
    _id
    text
    taskId
    type
    sticker
    createdAt
  }
}
    `;
export type CreateCommentMutationFn = Apollo.MutationFunction<CreateCommentMutation, CreateCommentMutationVariables>;

/**
 * __useCreateCommentMutation__
 *
 * To run a mutation, you first call `useCreateCommentMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreateCommentMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createCommentMutation, { data, loading, error }] = useCreateCommentMutation({
 *   variables: {
 *      text: // value for 'text'
 *      taskId: // value for 'taskId'
 *      type: // value for 'type'
 *      sticker: // value for 'sticker'
 *      projectId: // value for 'projectId'
 *   },
 * });
 */
export function useCreateCommentMutation(baseOptions?: Apollo.MutationHookOptions<CreateCommentMutation, CreateCommentMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<CreateCommentMutation, CreateCommentMutationVariables>(CreateCommentDocument, options);
      }
export type CreateCommentMutationHookResult = ReturnType<typeof useCreateCommentMutation>;
export type CreateCommentMutationResult = Apollo.MutationResult<CreateCommentMutation>;
export type CreateCommentMutationOptions = Apollo.BaseMutationOptions<CreateCommentMutation, CreateCommentMutationVariables>;
export const UpdateCommentDocument = gql`
    mutation UpdateComment($commentId: ID!, $text: String!) {
  updateComment(input: {commentId: $commentId, text: $text}) {
    _id
    text
    isEdited
  }
}
    `;
export type UpdateCommentMutationFn = Apollo.MutationFunction<UpdateCommentMutation, UpdateCommentMutationVariables>;

/**
 * __useUpdateCommentMutation__
 *
 * To run a mutation, you first call `useUpdateCommentMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateCommentMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateCommentMutation, { data, loading, error }] = useUpdateCommentMutation({
 *   variables: {
 *      commentId: // value for 'commentId'
 *      text: // value for 'text'
 *   },
 * });
 */
export function useUpdateCommentMutation(baseOptions?: Apollo.MutationHookOptions<UpdateCommentMutation, UpdateCommentMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<UpdateCommentMutation, UpdateCommentMutationVariables>(UpdateCommentDocument, options);
      }
export type UpdateCommentMutationHookResult = ReturnType<typeof useUpdateCommentMutation>;
export type UpdateCommentMutationResult = Apollo.MutationResult<UpdateCommentMutation>;
export type UpdateCommentMutationOptions = Apollo.BaseMutationOptions<UpdateCommentMutation, UpdateCommentMutationVariables>;
export const DeleteCommentDocument = gql`
    mutation DeleteComment($id: ID!) {
  deleteComment(id: $id) {
    _id
  }
}
    `;
export type DeleteCommentMutationFn = Apollo.MutationFunction<DeleteCommentMutation, DeleteCommentMutationVariables>;

/**
 * __useDeleteCommentMutation__
 *
 * To run a mutation, you first call `useDeleteCommentMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useDeleteCommentMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [deleteCommentMutation, { data, loading, error }] = useDeleteCommentMutation({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useDeleteCommentMutation(baseOptions?: Apollo.MutationHookOptions<DeleteCommentMutation, DeleteCommentMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<DeleteCommentMutation, DeleteCommentMutationVariables>(DeleteCommentDocument, options);
      }
export type DeleteCommentMutationHookResult = ReturnType<typeof useDeleteCommentMutation>;
export type DeleteCommentMutationResult = Apollo.MutationResult<DeleteCommentMutation>;
export type DeleteCommentMutationOptions = Apollo.BaseMutationOptions<DeleteCommentMutation, DeleteCommentMutationVariables>;
export const AddReactionDocument = gql`
    mutation AddReaction($commentId: ID!, $emoji: String!) {
  addReaction(input: {commentId: $commentId, emoji: $emoji}) {
    _id
    reactions {
      id
      emoji
      user {
        _id
        fullName
      }
    }
  }
}
    `;
export type AddReactionMutationFn = Apollo.MutationFunction<AddReactionMutation, AddReactionMutationVariables>;

/**
 * __useAddReactionMutation__
 *
 * To run a mutation, you first call `useAddReactionMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useAddReactionMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [addReactionMutation, { data, loading, error }] = useAddReactionMutation({
 *   variables: {
 *      commentId: // value for 'commentId'
 *      emoji: // value for 'emoji'
 *   },
 * });
 */
export function useAddReactionMutation(baseOptions?: Apollo.MutationHookOptions<AddReactionMutation, AddReactionMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<AddReactionMutation, AddReactionMutationVariables>(AddReactionDocument, options);
      }
export type AddReactionMutationHookResult = ReturnType<typeof useAddReactionMutation>;
export type AddReactionMutationResult = Apollo.MutationResult<AddReactionMutation>;
export type AddReactionMutationOptions = Apollo.BaseMutationOptions<AddReactionMutation, AddReactionMutationVariables>;
export const RemoveReactionDocument = gql`
    mutation RemoveReaction($commentId: ID!, $emoji: String!) {
  removeReaction(input: {commentId: $commentId, emoji: $emoji}) {
    _id
    reactions {
      id
      emoji
    }
  }
}
    `;
export type RemoveReactionMutationFn = Apollo.MutationFunction<RemoveReactionMutation, RemoveReactionMutationVariables>;

/**
 * __useRemoveReactionMutation__
 *
 * To run a mutation, you first call `useRemoveReactionMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useRemoveReactionMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [removeReactionMutation, { data, loading, error }] = useRemoveReactionMutation({
 *   variables: {
 *      commentId: // value for 'commentId'
 *      emoji: // value for 'emoji'
 *   },
 * });
 */
export function useRemoveReactionMutation(baseOptions?: Apollo.MutationHookOptions<RemoveReactionMutation, RemoveReactionMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<RemoveReactionMutation, RemoveReactionMutationVariables>(RemoveReactionDocument, options);
      }
export type RemoveReactionMutationHookResult = ReturnType<typeof useRemoveReactionMutation>;
export type RemoveReactionMutationResult = Apollo.MutationResult<RemoveReactionMutation>;
export type RemoveReactionMutationOptions = Apollo.BaseMutationOptions<RemoveReactionMutation, RemoveReactionMutationVariables>;
export const CommentsDocument = gql`
    query Comments($taskId: ID!) {
  comments(taskId: $taskId) {
    _id
    text
    type
    sticker
    createdAt
    isEdited
    user {
      _id
      fullName
      profilePicture
    }
    reactions {
      id
      emoji
      user {
        _id
        fullName
      }
    }
  }
}
    `;

/**
 * __useCommentsQuery__
 *
 * To run a query within a React component, call `useCommentsQuery` and pass it any options that fit your needs.
 * When your component renders, `useCommentsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useCommentsQuery({
 *   variables: {
 *      taskId: // value for 'taskId'
 *   },
 * });
 */
export function useCommentsQuery(baseOptions: Apollo.QueryHookOptions<CommentsQuery, CommentsQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<CommentsQuery, CommentsQueryVariables>(CommentsDocument, options);
      }
export function useCommentsLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<CommentsQuery, CommentsQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<CommentsQuery, CommentsQueryVariables>(CommentsDocument, options);
        }
export type CommentsQueryHookResult = ReturnType<typeof useCommentsQuery>;
export type CommentsLazyQueryHookResult = ReturnType<typeof useCommentsLazyQuery>;
export type CommentsQueryResult = Apollo.QueryResult<CommentsQuery, CommentsQueryVariables>;
export const OnCreateCommentDocument = gql`
    subscription onCreateComment {
  onCreateComment {
    _id
    text
    type
    sticker
    createdAt
    taskId
    reactions {
      id
      emoji
      user {
        _id
        fullName
      }
    }
    user {
      _id
      fullName
      profilePicture
    }
  }
}
    `;

/**
 * __useOnCreateCommentSubscription__
 *
 * To run a query within a React component, call `useOnCreateCommentSubscription` and pass it any options that fit your needs.
 * When your component renders, `useOnCreateCommentSubscription` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the subscription, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useOnCreateCommentSubscription({
 *   variables: {
 *   },
 * });
 */
export function useOnCreateCommentSubscription(baseOptions?: Apollo.SubscriptionHookOptions<OnCreateCommentSubscription, OnCreateCommentSubscriptionVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useSubscription<OnCreateCommentSubscription, OnCreateCommentSubscriptionVariables>(OnCreateCommentDocument, options);
      }
export type OnCreateCommentSubscriptionHookResult = ReturnType<typeof useOnCreateCommentSubscription>;
export type OnCreateCommentSubscriptionResult = Apollo.SubscriptionResult<OnCreateCommentSubscription>;
export const OnDeleteCommentDocument = gql`
    subscription onDeleteComment {
  onDeleteComment {
    _id
  }
}
    `;

/**
 * __useOnDeleteCommentSubscription__
 *
 * To run a query within a React component, call `useOnDeleteCommentSubscription` and pass it any options that fit your needs.
 * When your component renders, `useOnDeleteCommentSubscription` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the subscription, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useOnDeleteCommentSubscription({
 *   variables: {
 *   },
 * });
 */
export function useOnDeleteCommentSubscription(baseOptions?: Apollo.SubscriptionHookOptions<OnDeleteCommentSubscription, OnDeleteCommentSubscriptionVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useSubscription<OnDeleteCommentSubscription, OnDeleteCommentSubscriptionVariables>(OnDeleteCommentDocument, options);
      }
export type OnDeleteCommentSubscriptionHookResult = ReturnType<typeof useOnDeleteCommentSubscription>;
export type OnDeleteCommentSubscriptionResult = Apollo.SubscriptionResult<OnDeleteCommentSubscription>;
export const CreateCustomFieldDocument = gql`
    mutation CreateCustomField($workspaceId: ID!, $title: String!, $type: String!) {
  createCustomField(workspaceId: $workspaceId, title: $title, type: $type) {
    _id
    title
    type
    projectIds
  }
}
    `;
export type CreateCustomFieldMutationFn = Apollo.MutationFunction<CreateCustomFieldMutation, CreateCustomFieldMutationVariables>;

/**
 * __useCreateCustomFieldMutation__
 *
 * To run a mutation, you first call `useCreateCustomFieldMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreateCustomFieldMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createCustomFieldMutation, { data, loading, error }] = useCreateCustomFieldMutation({
 *   variables: {
 *      workspaceId: // value for 'workspaceId'
 *      title: // value for 'title'
 *      type: // value for 'type'
 *   },
 * });
 */
export function useCreateCustomFieldMutation(baseOptions?: Apollo.MutationHookOptions<CreateCustomFieldMutation, CreateCustomFieldMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<CreateCustomFieldMutation, CreateCustomFieldMutationVariables>(CreateCustomFieldDocument, options);
      }
export type CreateCustomFieldMutationHookResult = ReturnType<typeof useCreateCustomFieldMutation>;
export type CreateCustomFieldMutationResult = Apollo.MutationResult<CreateCustomFieldMutation>;
export type CreateCustomFieldMutationOptions = Apollo.BaseMutationOptions<CreateCustomFieldMutation, CreateCustomFieldMutationVariables>;
export const ToggleCustomFieldToProjectDocument = gql`
    mutation ToggleCustomFieldToProject($projectId: ID!, $customFieldId: ID!, $isChecked: Boolean!) {
  toggleCustomFieldToProject(
    input: {projectId: $projectId, customFieldId: $customFieldId, isChecked: $isChecked}
  ) {
    _id
    projectIds
  }
}
    `;
export type ToggleCustomFieldToProjectMutationFn = Apollo.MutationFunction<ToggleCustomFieldToProjectMutation, ToggleCustomFieldToProjectMutationVariables>;

/**
 * __useToggleCustomFieldToProjectMutation__
 *
 * To run a mutation, you first call `useToggleCustomFieldToProjectMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useToggleCustomFieldToProjectMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [toggleCustomFieldToProjectMutation, { data, loading, error }] = useToggleCustomFieldToProjectMutation({
 *   variables: {
 *      projectId: // value for 'projectId'
 *      customFieldId: // value for 'customFieldId'
 *      isChecked: // value for 'isChecked'
 *   },
 * });
 */
export function useToggleCustomFieldToProjectMutation(baseOptions?: Apollo.MutationHookOptions<ToggleCustomFieldToProjectMutation, ToggleCustomFieldToProjectMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<ToggleCustomFieldToProjectMutation, ToggleCustomFieldToProjectMutationVariables>(ToggleCustomFieldToProjectDocument, options);
      }
export type ToggleCustomFieldToProjectMutationHookResult = ReturnType<typeof useToggleCustomFieldToProjectMutation>;
export type ToggleCustomFieldToProjectMutationResult = Apollo.MutationResult<ToggleCustomFieldToProjectMutation>;
export type ToggleCustomFieldToProjectMutationOptions = Apollo.BaseMutationOptions<ToggleCustomFieldToProjectMutation, ToggleCustomFieldToProjectMutationVariables>;
export const CustomFieldsDocument = gql`
    query CustomFields($workspaceId: ID!) {
  customFields(workspaceId: $workspaceId) {
    _id
    title
    type
    projectIds
  }
}
    `;

/**
 * __useCustomFieldsQuery__
 *
 * To run a query within a React component, call `useCustomFieldsQuery` and pass it any options that fit your needs.
 * When your component renders, `useCustomFieldsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useCustomFieldsQuery({
 *   variables: {
 *      workspaceId: // value for 'workspaceId'
 *   },
 * });
 */
export function useCustomFieldsQuery(baseOptions: Apollo.QueryHookOptions<CustomFieldsQuery, CustomFieldsQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<CustomFieldsQuery, CustomFieldsQueryVariables>(CustomFieldsDocument, options);
      }
export function useCustomFieldsLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<CustomFieldsQuery, CustomFieldsQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<CustomFieldsQuery, CustomFieldsQueryVariables>(CustomFieldsDocument, options);
        }
export type CustomFieldsQueryHookResult = ReturnType<typeof useCustomFieldsQuery>;
export type CustomFieldsLazyQueryHookResult = ReturnType<typeof useCustomFieldsLazyQuery>;
export type CustomFieldsQueryResult = Apollo.QueryResult<CustomFieldsQuery, CustomFieldsQueryVariables>;
export const FilesDocument = gql`
    query Files($projectId: ID!) {
  files(projectId: $projectId) {
    _id
    urls {
      original
      thumbnail
      thumbnailPreview
    }
    fileName
    size
    type
    createdAt
  }
}
    `;

/**
 * __useFilesQuery__
 *
 * To run a query within a React component, call `useFilesQuery` and pass it any options that fit your needs.
 * When your component renders, `useFilesQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useFilesQuery({
 *   variables: {
 *      projectId: // value for 'projectId'
 *   },
 * });
 */
export function useFilesQuery(baseOptions: Apollo.QueryHookOptions<FilesQuery, FilesQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<FilesQuery, FilesQueryVariables>(FilesDocument, options);
      }
export function useFilesLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<FilesQuery, FilesQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<FilesQuery, FilesQueryVariables>(FilesDocument, options);
        }
export type FilesQueryHookResult = ReturnType<typeof useFilesQuery>;
export type FilesLazyQueryHookResult = ReturnType<typeof useFilesLazyQuery>;
export type FilesQueryResult = Apollo.QueryResult<FilesQuery, FilesQueryVariables>;
export const CreateLabelDocument = gql`
    mutation CreateLabel($workspaceId: ID!, $projectId: ID!, $title: String!, $color: String!) {
  createLabel(
    input: {workspaceId: $workspaceId, projectId: $projectId, title: $title, color: $color}
  ) {
    _id
    color
    title
  }
}
    `;
export type CreateLabelMutationFn = Apollo.MutationFunction<CreateLabelMutation, CreateLabelMutationVariables>;

/**
 * __useCreateLabelMutation__
 *
 * To run a mutation, you first call `useCreateLabelMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreateLabelMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createLabelMutation, { data, loading, error }] = useCreateLabelMutation({
 *   variables: {
 *      workspaceId: // value for 'workspaceId'
 *      projectId: // value for 'projectId'
 *      title: // value for 'title'
 *      color: // value for 'color'
 *   },
 * });
 */
export function useCreateLabelMutation(baseOptions?: Apollo.MutationHookOptions<CreateLabelMutation, CreateLabelMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<CreateLabelMutation, CreateLabelMutationVariables>(CreateLabelDocument, options);
      }
export type CreateLabelMutationHookResult = ReturnType<typeof useCreateLabelMutation>;
export type CreateLabelMutationResult = Apollo.MutationResult<CreateLabelMutation>;
export type CreateLabelMutationOptions = Apollo.BaseMutationOptions<CreateLabelMutation, CreateLabelMutationVariables>;
export const UpdateLabelDocument = gql`
    mutation UpdateLabel($id: ID!, $color: String, $title: String) {
  updateLabel(id: $id, input: {color: $color, title: $title}) {
    _id
    color
    title
  }
}
    `;
export type UpdateLabelMutationFn = Apollo.MutationFunction<UpdateLabelMutation, UpdateLabelMutationVariables>;

/**
 * __useUpdateLabelMutation__
 *
 * To run a mutation, you first call `useUpdateLabelMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateLabelMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateLabelMutation, { data, loading, error }] = useUpdateLabelMutation({
 *   variables: {
 *      id: // value for 'id'
 *      color: // value for 'color'
 *      title: // value for 'title'
 *   },
 * });
 */
export function useUpdateLabelMutation(baseOptions?: Apollo.MutationHookOptions<UpdateLabelMutation, UpdateLabelMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<UpdateLabelMutation, UpdateLabelMutationVariables>(UpdateLabelDocument, options);
      }
export type UpdateLabelMutationHookResult = ReturnType<typeof useUpdateLabelMutation>;
export type UpdateLabelMutationResult = Apollo.MutationResult<UpdateLabelMutation>;
export type UpdateLabelMutationOptions = Apollo.BaseMutationOptions<UpdateLabelMutation, UpdateLabelMutationVariables>;
export const DeleteLabelDocument = gql`
    mutation DeleteLabel($id: ID!) {
  deleteLabel(id: $id) {
    _id
  }
}
    `;
export type DeleteLabelMutationFn = Apollo.MutationFunction<DeleteLabelMutation, DeleteLabelMutationVariables>;

/**
 * __useDeleteLabelMutation__
 *
 * To run a mutation, you first call `useDeleteLabelMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useDeleteLabelMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [deleteLabelMutation, { data, loading, error }] = useDeleteLabelMutation({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useDeleteLabelMutation(baseOptions?: Apollo.MutationHookOptions<DeleteLabelMutation, DeleteLabelMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<DeleteLabelMutation, DeleteLabelMutationVariables>(DeleteLabelDocument, options);
      }
export type DeleteLabelMutationHookResult = ReturnType<typeof useDeleteLabelMutation>;
export type DeleteLabelMutationResult = Apollo.MutationResult<DeleteLabelMutation>;
export type DeleteLabelMutationOptions = Apollo.BaseMutationOptions<DeleteLabelMutation, DeleteLabelMutationVariables>;
export const LabelsDocument = gql`
    query Labels($workspaceId: ID!, $projectId: ID!) {
  labels(workspaceId: $workspaceId, projectId: $projectId) {
    _id
    title
    color
  }
}
    `;

/**
 * __useLabelsQuery__
 *
 * To run a query within a React component, call `useLabelsQuery` and pass it any options that fit your needs.
 * When your component renders, `useLabelsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useLabelsQuery({
 *   variables: {
 *      workspaceId: // value for 'workspaceId'
 *      projectId: // value for 'projectId'
 *   },
 * });
 */
export function useLabelsQuery(baseOptions: Apollo.QueryHookOptions<LabelsQuery, LabelsQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<LabelsQuery, LabelsQueryVariables>(LabelsDocument, options);
      }
export function useLabelsLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<LabelsQuery, LabelsQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<LabelsQuery, LabelsQueryVariables>(LabelsDocument, options);
        }
export type LabelsQueryHookResult = ReturnType<typeof useLabelsQuery>;
export type LabelsLazyQueryHookResult = ReturnType<typeof useLabelsLazyQuery>;
export type LabelsQueryResult = Apollo.QueryResult<LabelsQuery, LabelsQueryVariables>;
export const ReadNotificationDocument = gql`
    mutation ReadNotification($notificationId: ID!) {
  readNotification(notificationId: $notificationId) {
    _id
    isRead
  }
}
    `;
export type ReadNotificationMutationFn = Apollo.MutationFunction<ReadNotificationMutation, ReadNotificationMutationVariables>;

/**
 * __useReadNotificationMutation__
 *
 * To run a mutation, you first call `useReadNotificationMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useReadNotificationMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [readNotificationMutation, { data, loading, error }] = useReadNotificationMutation({
 *   variables: {
 *      notificationId: // value for 'notificationId'
 *   },
 * });
 */
export function useReadNotificationMutation(baseOptions?: Apollo.MutationHookOptions<ReadNotificationMutation, ReadNotificationMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<ReadNotificationMutation, ReadNotificationMutationVariables>(ReadNotificationDocument, options);
      }
export type ReadNotificationMutationHookResult = ReturnType<typeof useReadNotificationMutation>;
export type ReadNotificationMutationResult = Apollo.MutationResult<ReadNotificationMutation>;
export type ReadNotificationMutationOptions = Apollo.BaseMutationOptions<ReadNotificationMutation, ReadNotificationMutationVariables>;
export const ReadAllNotificationDocument = gql`
    mutation ReadAllNotification {
  readAllNotification
}
    `;
export type ReadAllNotificationMutationFn = Apollo.MutationFunction<ReadAllNotificationMutation, ReadAllNotificationMutationVariables>;

/**
 * __useReadAllNotificationMutation__
 *
 * To run a mutation, you first call `useReadAllNotificationMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useReadAllNotificationMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [readAllNotificationMutation, { data, loading, error }] = useReadAllNotificationMutation({
 *   variables: {
 *   },
 * });
 */
export function useReadAllNotificationMutation(baseOptions?: Apollo.MutationHookOptions<ReadAllNotificationMutation, ReadAllNotificationMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<ReadAllNotificationMutation, ReadAllNotificationMutationVariables>(ReadAllNotificationDocument, options);
      }
export type ReadAllNotificationMutationHookResult = ReturnType<typeof useReadAllNotificationMutation>;
export type ReadAllNotificationMutationResult = Apollo.MutationResult<ReadAllNotificationMutation>;
export type ReadAllNotificationMutationOptions = Apollo.BaseMutationOptions<ReadAllNotificationMutation, ReadAllNotificationMutationVariables>;
export const NotificationsDocument = gql`
    query Notifications($workspaceId: ID!, $userId: ID, $skip: Int, $limit: Int, $projectId: ID, $isPrivate: Boolean, $type: NotificationTypeEnum) {
  notifications(
    workspaceId: $workspaceId
    userId: $userId
    skip: $skip
    limit: $limit
    projectId: $projectId
    isPrivate: $isPrivate
    type: $type
  ) {
    ...NotificationFragment
  }
}
    ${NotificationFragmentFragmentDoc}`;

/**
 * __useNotificationsQuery__
 *
 * To run a query within a React component, call `useNotificationsQuery` and pass it any options that fit your needs.
 * When your component renders, `useNotificationsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useNotificationsQuery({
 *   variables: {
 *      workspaceId: // value for 'workspaceId'
 *      userId: // value for 'userId'
 *      skip: // value for 'skip'
 *      limit: // value for 'limit'
 *      projectId: // value for 'projectId'
 *      isPrivate: // value for 'isPrivate'
 *      type: // value for 'type'
 *   },
 * });
 */
export function useNotificationsQuery(baseOptions: Apollo.QueryHookOptions<NotificationsQuery, NotificationsQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<NotificationsQuery, NotificationsQueryVariables>(NotificationsDocument, options);
      }
export function useNotificationsLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<NotificationsQuery, NotificationsQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<NotificationsQuery, NotificationsQueryVariables>(NotificationsDocument, options);
        }
export type NotificationsQueryHookResult = ReturnType<typeof useNotificationsQuery>;
export type NotificationsLazyQueryHookResult = ReturnType<typeof useNotificationsLazyQuery>;
export type NotificationsQueryResult = Apollo.QueryResult<NotificationsQuery, NotificationsQueryVariables>;
export const OnCreateNotificationDocument = gql`
    subscription OnCreateNotification($workspaceId: ID!, $projectId: ID, $userId: ID, $isPrivate: Boolean) {
  onCreateNotification(
    workspaceId: $workspaceId
    projectId: $projectId
    userId: $userId
    isPrivate: $isPrivate
  ) {
    ...NotificationFragment
  }
}
    ${NotificationFragmentFragmentDoc}`;

/**
 * __useOnCreateNotificationSubscription__
 *
 * To run a query within a React component, call `useOnCreateNotificationSubscription` and pass it any options that fit your needs.
 * When your component renders, `useOnCreateNotificationSubscription` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the subscription, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useOnCreateNotificationSubscription({
 *   variables: {
 *      workspaceId: // value for 'workspaceId'
 *      projectId: // value for 'projectId'
 *      userId: // value for 'userId'
 *      isPrivate: // value for 'isPrivate'
 *   },
 * });
 */
export function useOnCreateNotificationSubscription(baseOptions: Apollo.SubscriptionHookOptions<OnCreateNotificationSubscription, OnCreateNotificationSubscriptionVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useSubscription<OnCreateNotificationSubscription, OnCreateNotificationSubscriptionVariables>(OnCreateNotificationDocument, options);
      }
export type OnCreateNotificationSubscriptionHookResult = ReturnType<typeof useOnCreateNotificationSubscription>;
export type OnCreateNotificationSubscriptionResult = Apollo.SubscriptionResult<OnCreateNotificationSubscription>;
export const CreateProjectDocument = gql`
    mutation CreateProject($title: String!, $color: String!) {
  createProject(input: {title: $title, color: $color}) {
    title
    color
  }
}
    `;
export type CreateProjectMutationFn = Apollo.MutationFunction<CreateProjectMutation, CreateProjectMutationVariables>;

/**
 * __useCreateProjectMutation__
 *
 * To run a mutation, you first call `useCreateProjectMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreateProjectMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createProjectMutation, { data, loading, error }] = useCreateProjectMutation({
 *   variables: {
 *      title: // value for 'title'
 *      color: // value for 'color'
 *   },
 * });
 */
export function useCreateProjectMutation(baseOptions?: Apollo.MutationHookOptions<CreateProjectMutation, CreateProjectMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<CreateProjectMutation, CreateProjectMutationVariables>(CreateProjectDocument, options);
      }
export type CreateProjectMutationHookResult = ReturnType<typeof useCreateProjectMutation>;
export type CreateProjectMutationResult = Apollo.MutationResult<CreateProjectMutation>;
export type CreateProjectMutationOptions = Apollo.BaseMutationOptions<CreateProjectMutation, CreateProjectMutationVariables>;
export const UpdateProjectDocument = gql`
    mutation UpdateProject($projectId: ID!, $title: String, $color: String, $boardsSortList: [ID!], $status: ProjectStatusEnum) {
  updateProject(
    projectId: $projectId
    title: $title
    color: $color
    boardsSortList: $boardsSortList
    status: $status
  ) {
    _id
    title
    color
    boardsSortList
    status
  }
}
    `;
export type UpdateProjectMutationFn = Apollo.MutationFunction<UpdateProjectMutation, UpdateProjectMutationVariables>;

/**
 * __useUpdateProjectMutation__
 *
 * To run a mutation, you first call `useUpdateProjectMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateProjectMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateProjectMutation, { data, loading, error }] = useUpdateProjectMutation({
 *   variables: {
 *      projectId: // value for 'projectId'
 *      title: // value for 'title'
 *      color: // value for 'color'
 *      boardsSortList: // value for 'boardsSortList'
 *      status: // value for 'status'
 *   },
 * });
 */
export function useUpdateProjectMutation(baseOptions?: Apollo.MutationHookOptions<UpdateProjectMutation, UpdateProjectMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<UpdateProjectMutation, UpdateProjectMutationVariables>(UpdateProjectDocument, options);
      }
export type UpdateProjectMutationHookResult = ReturnType<typeof useUpdateProjectMutation>;
export type UpdateProjectMutationResult = Apollo.MutationResult<UpdateProjectMutation>;
export type UpdateProjectMutationOptions = Apollo.BaseMutationOptions<UpdateProjectMutation, UpdateProjectMutationVariables>;
export const AcceptInviteToProjectDocument = gql`
    mutation AcceptInviteToProject($firstName: String!, $lastName: String!, $password: String!, $inviteToken: String!) {
  acceptInviteToProject(
    firstName: $firstName
    lastName: $lastName
    password: $password
    inviteToken: $inviteToken
  ) {
    user {
      _id
      fullName
      email
      profilePicture
    }
    token
  }
}
    `;
export type AcceptInviteToProjectMutationFn = Apollo.MutationFunction<AcceptInviteToProjectMutation, AcceptInviteToProjectMutationVariables>;

/**
 * __useAcceptInviteToProjectMutation__
 *
 * To run a mutation, you first call `useAcceptInviteToProjectMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useAcceptInviteToProjectMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [acceptInviteToProjectMutation, { data, loading, error }] = useAcceptInviteToProjectMutation({
 *   variables: {
 *      firstName: // value for 'firstName'
 *      lastName: // value for 'lastName'
 *      password: // value for 'password'
 *      inviteToken: // value for 'inviteToken'
 *   },
 * });
 */
export function useAcceptInviteToProjectMutation(baseOptions?: Apollo.MutationHookOptions<AcceptInviteToProjectMutation, AcceptInviteToProjectMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<AcceptInviteToProjectMutation, AcceptInviteToProjectMutationVariables>(AcceptInviteToProjectDocument, options);
      }
export type AcceptInviteToProjectMutationHookResult = ReturnType<typeof useAcceptInviteToProjectMutation>;
export type AcceptInviteToProjectMutationResult = Apollo.MutationResult<AcceptInviteToProjectMutation>;
export type AcceptInviteToProjectMutationOptions = Apollo.BaseMutationOptions<AcceptInviteToProjectMutation, AcceptInviteToProjectMutationVariables>;
export const InviteProjectMemberDocument = gql`
    mutation InviteProjectMember($workspaceId: ID!, $projectId: ID!, $email: String!) {
  inviteProjectMember(
    workspaceId: $workspaceId
    projectId: $projectId
    email: $email
  )
}
    `;
export type InviteProjectMemberMutationFn = Apollo.MutationFunction<InviteProjectMemberMutation, InviteProjectMemberMutationVariables>;

/**
 * __useInviteProjectMemberMutation__
 *
 * To run a mutation, you first call `useInviteProjectMemberMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useInviteProjectMemberMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [inviteProjectMemberMutation, { data, loading, error }] = useInviteProjectMemberMutation({
 *   variables: {
 *      workspaceId: // value for 'workspaceId'
 *      projectId: // value for 'projectId'
 *      email: // value for 'email'
 *   },
 * });
 */
export function useInviteProjectMemberMutation(baseOptions?: Apollo.MutationHookOptions<InviteProjectMemberMutation, InviteProjectMemberMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<InviteProjectMemberMutation, InviteProjectMemberMutationVariables>(InviteProjectMemberDocument, options);
      }
export type InviteProjectMemberMutationHookResult = ReturnType<typeof useInviteProjectMemberMutation>;
export type InviteProjectMemberMutationResult = Apollo.MutationResult<InviteProjectMemberMutation>;
export type InviteProjectMemberMutationOptions = Apollo.BaseMutationOptions<InviteProjectMemberMutation, InviteProjectMemberMutationVariables>;
export const DeleteProjectDocument = gql`
    mutation DeleteProject($projectId: ID!) {
  deleteProject(projectId: $projectId)
}
    `;
export type DeleteProjectMutationFn = Apollo.MutationFunction<DeleteProjectMutation, DeleteProjectMutationVariables>;

/**
 * __useDeleteProjectMutation__
 *
 * To run a mutation, you first call `useDeleteProjectMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useDeleteProjectMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [deleteProjectMutation, { data, loading, error }] = useDeleteProjectMutation({
 *   variables: {
 *      projectId: // value for 'projectId'
 *   },
 * });
 */
export function useDeleteProjectMutation(baseOptions?: Apollo.MutationHookOptions<DeleteProjectMutation, DeleteProjectMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<DeleteProjectMutation, DeleteProjectMutationVariables>(DeleteProjectDocument, options);
      }
export type DeleteProjectMutationHookResult = ReturnType<typeof useDeleteProjectMutation>;
export type DeleteProjectMutationResult = Apollo.MutationResult<DeleteProjectMutation>;
export type DeleteProjectMutationOptions = Apollo.BaseMutationOptions<DeleteProjectMutation, DeleteProjectMutationVariables>;
export const GetProjectsDocument = gql`
    query GetProjects {
  projects {
    _id
    title
    color
    createdAt
    boardsSortList
    members {
      _id
      email
      fullName
      profilePicture
    }
  }
}
    `;

/**
 * __useGetProjectsQuery__
 *
 * To run a query within a React component, call `useGetProjectsQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetProjectsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetProjectsQuery({
 *   variables: {
 *   },
 * });
 */
export function useGetProjectsQuery(baseOptions?: Apollo.QueryHookOptions<GetProjectsQuery, GetProjectsQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetProjectsQuery, GetProjectsQueryVariables>(GetProjectsDocument, options);
      }
export function useGetProjectsLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetProjectsQuery, GetProjectsQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetProjectsQuery, GetProjectsQueryVariables>(GetProjectsDocument, options);
        }
export type GetProjectsQueryHookResult = ReturnType<typeof useGetProjectsQuery>;
export type GetProjectsLazyQueryHookResult = ReturnType<typeof useGetProjectsLazyQuery>;
export type GetProjectsQueryResult = Apollo.QueryResult<GetProjectsQuery, GetProjectsQueryVariables>;
export const GetProjectDocument = gql`
    query GetProject($projectId: ID!) {
  project(projectId: $projectId) {
    _id
    title
    color
    boardsSortList
    completedStatusId
    unstagedStatusId
    status
    description
    members {
      _id
      email
      fullName
      profilePicture
    }
  }
  statuses(projectId: $projectId) {
    _id
    title
    color
  }
}
    `;

/**
 * __useGetProjectQuery__
 *
 * To run a query within a React component, call `useGetProjectQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetProjectQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetProjectQuery({
 *   variables: {
 *      projectId: // value for 'projectId'
 *   },
 * });
 */
export function useGetProjectQuery(baseOptions: Apollo.QueryHookOptions<GetProjectQuery, GetProjectQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetProjectQuery, GetProjectQueryVariables>(GetProjectDocument, options);
      }
export function useGetProjectLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetProjectQuery, GetProjectQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetProjectQuery, GetProjectQueryVariables>(GetProjectDocument, options);
        }
export type GetProjectQueryHookResult = ReturnType<typeof useGetProjectQuery>;
export type GetProjectLazyQueryHookResult = ReturnType<typeof useGetProjectLazyQuery>;
export type GetProjectQueryResult = Apollo.QueryResult<GetProjectQuery, GetProjectQueryVariables>;
export const ProjectsDashboardDocument = gql`
    query ProjectsDashboard {
  projectsDashboard {
    _id
    color
    title
    createdAt
    updatedAt
    statusesList
    labelsList
    statusesCount
    labelsCount
    taskCount
    filesCount
    members {
      _id
      fullName
      profilePicture
    }
  }
}
    `;

/**
 * __useProjectsDashboardQuery__
 *
 * To run a query within a React component, call `useProjectsDashboardQuery` and pass it any options that fit your needs.
 * When your component renders, `useProjectsDashboardQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useProjectsDashboardQuery({
 *   variables: {
 *   },
 * });
 */
export function useProjectsDashboardQuery(baseOptions?: Apollo.QueryHookOptions<ProjectsDashboardQuery, ProjectsDashboardQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<ProjectsDashboardQuery, ProjectsDashboardQueryVariables>(ProjectsDashboardDocument, options);
      }
export function useProjectsDashboardLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<ProjectsDashboardQuery, ProjectsDashboardQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<ProjectsDashboardQuery, ProjectsDashboardQueryVariables>(ProjectsDashboardDocument, options);
        }
export type ProjectsDashboardQueryHookResult = ReturnType<typeof useProjectsDashboardQuery>;
export type ProjectsDashboardLazyQueryHookResult = ReturnType<typeof useProjectsDashboardLazyQuery>;
export type ProjectsDashboardQueryResult = Apollo.QueryResult<ProjectsDashboardQuery, ProjectsDashboardQueryVariables>;
export const DeleteStatusDocument = gql`
    mutation DeleteStatus($statusId: ID!) {
  deleteStatus(input: {statusId: $statusId}) {
    _id
  }
}
    `;
export type DeleteStatusMutationFn = Apollo.MutationFunction<DeleteStatusMutation, DeleteStatusMutationVariables>;

/**
 * __useDeleteStatusMutation__
 *
 * To run a mutation, you first call `useDeleteStatusMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useDeleteStatusMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [deleteStatusMutation, { data, loading, error }] = useDeleteStatusMutation({
 *   variables: {
 *      statusId: // value for 'statusId'
 *   },
 * });
 */
export function useDeleteStatusMutation(baseOptions?: Apollo.MutationHookOptions<DeleteStatusMutation, DeleteStatusMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<DeleteStatusMutation, DeleteStatusMutationVariables>(DeleteStatusDocument, options);
      }
export type DeleteStatusMutationHookResult = ReturnType<typeof useDeleteStatusMutation>;
export type DeleteStatusMutationResult = Apollo.MutationResult<DeleteStatusMutation>;
export type DeleteStatusMutationOptions = Apollo.BaseMutationOptions<DeleteStatusMutation, DeleteStatusMutationVariables>;
export const CreateStatusDocument = gql`
    mutation CreateStatus($workspaceId: ID!, $projectId: ID!, $title: String!) {
  createStatus(
    input: {workspaceId: $workspaceId, projectId: $projectId, title: $title}
  ) {
    _id
    title
    color
    taskOrder
  }
}
    `;
export type CreateStatusMutationFn = Apollo.MutationFunction<CreateStatusMutation, CreateStatusMutationVariables>;

/**
 * __useCreateStatusMutation__
 *
 * To run a mutation, you first call `useCreateStatusMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreateStatusMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createStatusMutation, { data, loading, error }] = useCreateStatusMutation({
 *   variables: {
 *      workspaceId: // value for 'workspaceId'
 *      projectId: // value for 'projectId'
 *      title: // value for 'title'
 *   },
 * });
 */
export function useCreateStatusMutation(baseOptions?: Apollo.MutationHookOptions<CreateStatusMutation, CreateStatusMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<CreateStatusMutation, CreateStatusMutationVariables>(CreateStatusDocument, options);
      }
export type CreateStatusMutationHookResult = ReturnType<typeof useCreateStatusMutation>;
export type CreateStatusMutationResult = Apollo.MutationResult<CreateStatusMutation>;
export type CreateStatusMutationOptions = Apollo.BaseMutationOptions<CreateStatusMutation, CreateStatusMutationVariables>;
export const UpdateStatusDocument = gql`
    mutation UpdateStatus($statusId: ID!, $taskOrder: [ID!], $color: String, $title: String) {
  updateStatus(
    statusId: $statusId
    taskOrder: $taskOrder
    color: $color
    title: $title
  ) {
    _id
    title
    color
    taskOrder
  }
}
    `;
export type UpdateStatusMutationFn = Apollo.MutationFunction<UpdateStatusMutation, UpdateStatusMutationVariables>;

/**
 * __useUpdateStatusMutation__
 *
 * To run a mutation, you first call `useUpdateStatusMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateStatusMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateStatusMutation, { data, loading, error }] = useUpdateStatusMutation({
 *   variables: {
 *      statusId: // value for 'statusId'
 *      taskOrder: // value for 'taskOrder'
 *      color: // value for 'color'
 *      title: // value for 'title'
 *   },
 * });
 */
export function useUpdateStatusMutation(baseOptions?: Apollo.MutationHookOptions<UpdateStatusMutation, UpdateStatusMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<UpdateStatusMutation, UpdateStatusMutationVariables>(UpdateStatusDocument, options);
      }
export type UpdateStatusMutationHookResult = ReturnType<typeof useUpdateStatusMutation>;
export type UpdateStatusMutationResult = Apollo.MutationResult<UpdateStatusMutation>;
export type UpdateStatusMutationOptions = Apollo.BaseMutationOptions<UpdateStatusMutation, UpdateStatusMutationVariables>;
export const StatusesDocument = gql`
    query Statuses($projectId: ID!) {
  statuses(projectId: $projectId) {
    _id
    title
    color
    createdAt
  }
}
    `;

/**
 * __useStatusesQuery__
 *
 * To run a query within a React component, call `useStatusesQuery` and pass it any options that fit your needs.
 * When your component renders, `useStatusesQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useStatusesQuery({
 *   variables: {
 *      projectId: // value for 'projectId'
 *   },
 * });
 */
export function useStatusesQuery(baseOptions: Apollo.QueryHookOptions<StatusesQuery, StatusesQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<StatusesQuery, StatusesQueryVariables>(StatusesDocument, options);
      }
export function useStatusesLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<StatusesQuery, StatusesQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<StatusesQuery, StatusesQueryVariables>(StatusesDocument, options);
        }
export type StatusesQueryHookResult = ReturnType<typeof useStatusesQuery>;
export type StatusesLazyQueryHookResult = ReturnType<typeof useStatusesLazyQuery>;
export type StatusesQueryResult = Apollo.QueryResult<StatusesQuery, StatusesQueryVariables>;
export const CreateTaskAdvancedFieldDocument = gql`
    mutation CreateTaskAdvancedField($taskId: ID!, $type: TaskAdvancedFieldTypeEnum!, $title: String!) {
  createTaskAdvancedField(taskId: $taskId, type: $type, title: $title) {
    _id
  }
}
    `;
export type CreateTaskAdvancedFieldMutationFn = Apollo.MutationFunction<CreateTaskAdvancedFieldMutation, CreateTaskAdvancedFieldMutationVariables>;

/**
 * __useCreateTaskAdvancedFieldMutation__
 *
 * To run a mutation, you first call `useCreateTaskAdvancedFieldMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreateTaskAdvancedFieldMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createTaskAdvancedFieldMutation, { data, loading, error }] = useCreateTaskAdvancedFieldMutation({
 *   variables: {
 *      taskId: // value for 'taskId'
 *      type: // value for 'type'
 *      title: // value for 'title'
 *   },
 * });
 */
export function useCreateTaskAdvancedFieldMutation(baseOptions?: Apollo.MutationHookOptions<CreateTaskAdvancedFieldMutation, CreateTaskAdvancedFieldMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<CreateTaskAdvancedFieldMutation, CreateTaskAdvancedFieldMutationVariables>(CreateTaskAdvancedFieldDocument, options);
      }
export type CreateTaskAdvancedFieldMutationHookResult = ReturnType<typeof useCreateTaskAdvancedFieldMutation>;
export type CreateTaskAdvancedFieldMutationResult = Apollo.MutationResult<CreateTaskAdvancedFieldMutation>;
export type CreateTaskAdvancedFieldMutationOptions = Apollo.BaseMutationOptions<CreateTaskAdvancedFieldMutation, CreateTaskAdvancedFieldMutationVariables>;
export const UpdateTaskAdvancedFieldDocument = gql`
    mutation UpdateTaskAdvancedField($fieldId: ID!, $value: String!) {
  updateTaskAdvancedField(fieldId: $fieldId, value: $value) {
    _id
  }
}
    `;
export type UpdateTaskAdvancedFieldMutationFn = Apollo.MutationFunction<UpdateTaskAdvancedFieldMutation, UpdateTaskAdvancedFieldMutationVariables>;

/**
 * __useUpdateTaskAdvancedFieldMutation__
 *
 * To run a mutation, you first call `useUpdateTaskAdvancedFieldMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateTaskAdvancedFieldMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateTaskAdvancedFieldMutation, { data, loading, error }] = useUpdateTaskAdvancedFieldMutation({
 *   variables: {
 *      fieldId: // value for 'fieldId'
 *      value: // value for 'value'
 *   },
 * });
 */
export function useUpdateTaskAdvancedFieldMutation(baseOptions?: Apollo.MutationHookOptions<UpdateTaskAdvancedFieldMutation, UpdateTaskAdvancedFieldMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<UpdateTaskAdvancedFieldMutation, UpdateTaskAdvancedFieldMutationVariables>(UpdateTaskAdvancedFieldDocument, options);
      }
export type UpdateTaskAdvancedFieldMutationHookResult = ReturnType<typeof useUpdateTaskAdvancedFieldMutation>;
export type UpdateTaskAdvancedFieldMutationResult = Apollo.MutationResult<UpdateTaskAdvancedFieldMutation>;
export type UpdateTaskAdvancedFieldMutationOptions = Apollo.BaseMutationOptions<UpdateTaskAdvancedFieldMutation, UpdateTaskAdvancedFieldMutationVariables>;
export const DeleteTaskAdvancedFieldDocument = gql`
    mutation DeleteTaskAdvancedField($fieldId: ID!) {
  deleteTaskAdvancedField(fieldId: $fieldId) {
    _id
  }
}
    `;
export type DeleteTaskAdvancedFieldMutationFn = Apollo.MutationFunction<DeleteTaskAdvancedFieldMutation, DeleteTaskAdvancedFieldMutationVariables>;

/**
 * __useDeleteTaskAdvancedFieldMutation__
 *
 * To run a mutation, you first call `useDeleteTaskAdvancedFieldMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useDeleteTaskAdvancedFieldMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [deleteTaskAdvancedFieldMutation, { data, loading, error }] = useDeleteTaskAdvancedFieldMutation({
 *   variables: {
 *      fieldId: // value for 'fieldId'
 *   },
 * });
 */
export function useDeleteTaskAdvancedFieldMutation(baseOptions?: Apollo.MutationHookOptions<DeleteTaskAdvancedFieldMutation, DeleteTaskAdvancedFieldMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<DeleteTaskAdvancedFieldMutation, DeleteTaskAdvancedFieldMutationVariables>(DeleteTaskAdvancedFieldDocument, options);
      }
export type DeleteTaskAdvancedFieldMutationHookResult = ReturnType<typeof useDeleteTaskAdvancedFieldMutation>;
export type DeleteTaskAdvancedFieldMutationResult = Apollo.MutationResult<DeleteTaskAdvancedFieldMutation>;
export type DeleteTaskAdvancedFieldMutationOptions = Apollo.BaseMutationOptions<DeleteTaskAdvancedFieldMutation, DeleteTaskAdvancedFieldMutationVariables>;
export const CreateTaskCustomFieldDocument = gql`
    mutation CreateTaskCustomField($value: String!, $taskId: ID!, $projectId: ID!, $customFieldId: ID!) {
  createTaskCustomField(
    input: {value: $value, taskId: $taskId, projectId: $projectId, customFieldId: $customFieldId}
  ) {
    _id
    value
  }
}
    `;
export type CreateTaskCustomFieldMutationFn = Apollo.MutationFunction<CreateTaskCustomFieldMutation, CreateTaskCustomFieldMutationVariables>;

/**
 * __useCreateTaskCustomFieldMutation__
 *
 * To run a mutation, you first call `useCreateTaskCustomFieldMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreateTaskCustomFieldMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createTaskCustomFieldMutation, { data, loading, error }] = useCreateTaskCustomFieldMutation({
 *   variables: {
 *      value: // value for 'value'
 *      taskId: // value for 'taskId'
 *      projectId: // value for 'projectId'
 *      customFieldId: // value for 'customFieldId'
 *   },
 * });
 */
export function useCreateTaskCustomFieldMutation(baseOptions?: Apollo.MutationHookOptions<CreateTaskCustomFieldMutation, CreateTaskCustomFieldMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<CreateTaskCustomFieldMutation, CreateTaskCustomFieldMutationVariables>(CreateTaskCustomFieldDocument, options);
      }
export type CreateTaskCustomFieldMutationHookResult = ReturnType<typeof useCreateTaskCustomFieldMutation>;
export type CreateTaskCustomFieldMutationResult = Apollo.MutationResult<CreateTaskCustomFieldMutation>;
export type CreateTaskCustomFieldMutationOptions = Apollo.BaseMutationOptions<CreateTaskCustomFieldMutation, CreateTaskCustomFieldMutationVariables>;
export const TaskCustomFieldsDocument = gql`
    query TaskCustomFields($workspaceId: ID!, $projectId: ID!, $taskId: ID!, $projectIds: [ID!]) {
  customFields(workspaceId: $workspaceId, projectIds: $projectIds) {
    _id
    title
    type
    projectIds
  }
  taskCustomFields(taskId: $taskId, projectId: $projectId) {
    _id
    value
    customField {
      _id
      title
      type
    }
  }
}
    `;

/**
 * __useTaskCustomFieldsQuery__
 *
 * To run a query within a React component, call `useTaskCustomFieldsQuery` and pass it any options that fit your needs.
 * When your component renders, `useTaskCustomFieldsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useTaskCustomFieldsQuery({
 *   variables: {
 *      workspaceId: // value for 'workspaceId'
 *      projectId: // value for 'projectId'
 *      taskId: // value for 'taskId'
 *      projectIds: // value for 'projectIds'
 *   },
 * });
 */
export function useTaskCustomFieldsQuery(baseOptions: Apollo.QueryHookOptions<TaskCustomFieldsQuery, TaskCustomFieldsQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<TaskCustomFieldsQuery, TaskCustomFieldsQueryVariables>(TaskCustomFieldsDocument, options);
      }
export function useTaskCustomFieldsLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<TaskCustomFieldsQuery, TaskCustomFieldsQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<TaskCustomFieldsQuery, TaskCustomFieldsQueryVariables>(TaskCustomFieldsDocument, options);
        }
export type TaskCustomFieldsQueryHookResult = ReturnType<typeof useTaskCustomFieldsQuery>;
export type TaskCustomFieldsLazyQueryHookResult = ReturnType<typeof useTaskCustomFieldsLazyQuery>;
export type TaskCustomFieldsQueryResult = Apollo.QueryResult<TaskCustomFieldsQuery, TaskCustomFieldsQueryVariables>;
export const TaskDescriptionHistoryDocument = gql`
    query TaskDescriptionHistory($taskId: ID!, $skip: Int, $limit: Int) {
  taskDescriptionHistory(taskId: $taskId, skip: $skip, limit: $limit) {
    _id
    text
    taskId
    createdAt
    user {
      fullName
    }
  }
}
    `;

/**
 * __useTaskDescriptionHistoryQuery__
 *
 * To run a query within a React component, call `useTaskDescriptionHistoryQuery` and pass it any options that fit your needs.
 * When your component renders, `useTaskDescriptionHistoryQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useTaskDescriptionHistoryQuery({
 *   variables: {
 *      taskId: // value for 'taskId'
 *      skip: // value for 'skip'
 *      limit: // value for 'limit'
 *   },
 * });
 */
export function useTaskDescriptionHistoryQuery(baseOptions: Apollo.QueryHookOptions<TaskDescriptionHistoryQuery, TaskDescriptionHistoryQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<TaskDescriptionHistoryQuery, TaskDescriptionHistoryQueryVariables>(TaskDescriptionHistoryDocument, options);
      }
export function useTaskDescriptionHistoryLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<TaskDescriptionHistoryQuery, TaskDescriptionHistoryQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<TaskDescriptionHistoryQuery, TaskDescriptionHistoryQueryVariables>(TaskDescriptionHistoryDocument, options);
        }
export type TaskDescriptionHistoryQueryHookResult = ReturnType<typeof useTaskDescriptionHistoryQuery>;
export type TaskDescriptionHistoryLazyQueryHookResult = ReturnType<typeof useTaskDescriptionHistoryLazyQuery>;
export type TaskDescriptionHistoryQueryResult = Apollo.QueryResult<TaskDescriptionHistoryQuery, TaskDescriptionHistoryQueryVariables>;
export const OnCreateTaskDescriptionHistoryDocument = gql`
    subscription OnCreateTaskDescriptionHistory {
  onCreateTaskDescriptionHistory {
    _id
    text
    taskId
    user {
      fullName
    }
  }
}
    `;

/**
 * __useOnCreateTaskDescriptionHistorySubscription__
 *
 * To run a query within a React component, call `useOnCreateTaskDescriptionHistorySubscription` and pass it any options that fit your needs.
 * When your component renders, `useOnCreateTaskDescriptionHistorySubscription` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the subscription, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useOnCreateTaskDescriptionHistorySubscription({
 *   variables: {
 *   },
 * });
 */
export function useOnCreateTaskDescriptionHistorySubscription(baseOptions?: Apollo.SubscriptionHookOptions<OnCreateTaskDescriptionHistorySubscription, OnCreateTaskDescriptionHistorySubscriptionVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useSubscription<OnCreateTaskDescriptionHistorySubscription, OnCreateTaskDescriptionHistorySubscriptionVariables>(OnCreateTaskDescriptionHistoryDocument, options);
      }
export type OnCreateTaskDescriptionHistorySubscriptionHookResult = ReturnType<typeof useOnCreateTaskDescriptionHistorySubscription>;
export type OnCreateTaskDescriptionHistorySubscriptionResult = Apollo.SubscriptionResult<OnCreateTaskDescriptionHistorySubscription>;
export const CreateTaskTemplateDocument = gql`
    mutation CreateTaskTemplate($workspaceId: ID!, $title: String!, $description: String, $dueDate: Date, $isUrgent: Boolean, $priority: String, $startDate: Date, $storyPoints: Float, $assignee: String) {
  createTaskTemplate(
    workspaceId: $workspaceId
    title: $title
    description: $description
    dueDate: $dueDate
    isUrgent: $isUrgent
    priority: $priority
    startDate: $startDate
    storyPoints: $storyPoints
    assignee: $assignee
  ) {
    _id
    workspaceId
    title
    description
    dueDate
    isUrgent
    priority
    startDate
    storyPoints
  }
}
    `;
export type CreateTaskTemplateMutationFn = Apollo.MutationFunction<CreateTaskTemplateMutation, CreateTaskTemplateMutationVariables>;

/**
 * __useCreateTaskTemplateMutation__
 *
 * To run a mutation, you first call `useCreateTaskTemplateMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreateTaskTemplateMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createTaskTemplateMutation, { data, loading, error }] = useCreateTaskTemplateMutation({
 *   variables: {
 *      workspaceId: // value for 'workspaceId'
 *      title: // value for 'title'
 *      description: // value for 'description'
 *      dueDate: // value for 'dueDate'
 *      isUrgent: // value for 'isUrgent'
 *      priority: // value for 'priority'
 *      startDate: // value for 'startDate'
 *      storyPoints: // value for 'storyPoints'
 *      assignee: // value for 'assignee'
 *   },
 * });
 */
export function useCreateTaskTemplateMutation(baseOptions?: Apollo.MutationHookOptions<CreateTaskTemplateMutation, CreateTaskTemplateMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<CreateTaskTemplateMutation, CreateTaskTemplateMutationVariables>(CreateTaskTemplateDocument, options);
      }
export type CreateTaskTemplateMutationHookResult = ReturnType<typeof useCreateTaskTemplateMutation>;
export type CreateTaskTemplateMutationResult = Apollo.MutationResult<CreateTaskTemplateMutation>;
export type CreateTaskTemplateMutationOptions = Apollo.BaseMutationOptions<CreateTaskTemplateMutation, CreateTaskTemplateMutationVariables>;
export const DeleteTaskTemplateDocument = gql`
    mutation DeleteTaskTemplate($templateId: ID!) {
  deleteTaskTemplate(templateId: $templateId) {
    _id
  }
}
    `;
export type DeleteTaskTemplateMutationFn = Apollo.MutationFunction<DeleteTaskTemplateMutation, DeleteTaskTemplateMutationVariables>;

/**
 * __useDeleteTaskTemplateMutation__
 *
 * To run a mutation, you first call `useDeleteTaskTemplateMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useDeleteTaskTemplateMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [deleteTaskTemplateMutation, { data, loading, error }] = useDeleteTaskTemplateMutation({
 *   variables: {
 *      templateId: // value for 'templateId'
 *   },
 * });
 */
export function useDeleteTaskTemplateMutation(baseOptions?: Apollo.MutationHookOptions<DeleteTaskTemplateMutation, DeleteTaskTemplateMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<DeleteTaskTemplateMutation, DeleteTaskTemplateMutationVariables>(DeleteTaskTemplateDocument, options);
      }
export type DeleteTaskTemplateMutationHookResult = ReturnType<typeof useDeleteTaskTemplateMutation>;
export type DeleteTaskTemplateMutationResult = Apollo.MutationResult<DeleteTaskTemplateMutation>;
export type DeleteTaskTemplateMutationOptions = Apollo.BaseMutationOptions<DeleteTaskTemplateMutation, DeleteTaskTemplateMutationVariables>;
export const TaskTemplatesDocument = gql`
    query TaskTemplates($workspaceId: ID!) {
  taskTemplates(workspaceId: $workspaceId) {
    _id
    title
    description
  }
}
    `;

/**
 * __useTaskTemplatesQuery__
 *
 * To run a query within a React component, call `useTaskTemplatesQuery` and pass it any options that fit your needs.
 * When your component renders, `useTaskTemplatesQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useTaskTemplatesQuery({
 *   variables: {
 *      workspaceId: // value for 'workspaceId'
 *   },
 * });
 */
export function useTaskTemplatesQuery(baseOptions: Apollo.QueryHookOptions<TaskTemplatesQuery, TaskTemplatesQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<TaskTemplatesQuery, TaskTemplatesQueryVariables>(TaskTemplatesDocument, options);
      }
export function useTaskTemplatesLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<TaskTemplatesQuery, TaskTemplatesQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<TaskTemplatesQuery, TaskTemplatesQueryVariables>(TaskTemplatesDocument, options);
        }
export type TaskTemplatesQueryHookResult = ReturnType<typeof useTaskTemplatesQuery>;
export type TaskTemplatesLazyQueryHookResult = ReturnType<typeof useTaskTemplatesLazyQuery>;
export type TaskTemplatesQueryResult = Apollo.QueryResult<TaskTemplatesQuery, TaskTemplatesQueryVariables>;
export const UpdateTimeTrackingDocument = gql`
    mutation UpdateTimeTracking($actionId: String!, $timeTrackingId: String, $operation: TimeTrackingOperation!) {
  updateTimeTracking(
    input: {actionId: $actionId, timeTrackingId: $timeTrackingId, operation: $operation}
  ) {
    _id
  }
}
    `;
export type UpdateTimeTrackingMutationFn = Apollo.MutationFunction<UpdateTimeTrackingMutation, UpdateTimeTrackingMutationVariables>;

/**
 * __useUpdateTimeTrackingMutation__
 *
 * To run a mutation, you first call `useUpdateTimeTrackingMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateTimeTrackingMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateTimeTrackingMutation, { data, loading, error }] = useUpdateTimeTrackingMutation({
 *   variables: {
 *      actionId: // value for 'actionId'
 *      timeTrackingId: // value for 'timeTrackingId'
 *      operation: // value for 'operation'
 *   },
 * });
 */
export function useUpdateTimeTrackingMutation(baseOptions?: Apollo.MutationHookOptions<UpdateTimeTrackingMutation, UpdateTimeTrackingMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<UpdateTimeTrackingMutation, UpdateTimeTrackingMutationVariables>(UpdateTimeTrackingDocument, options);
      }
export type UpdateTimeTrackingMutationHookResult = ReturnType<typeof useUpdateTimeTrackingMutation>;
export type UpdateTimeTrackingMutationResult = Apollo.MutationResult<UpdateTimeTrackingMutation>;
export type UpdateTimeTrackingMutationOptions = Apollo.BaseMutationOptions<UpdateTimeTrackingMutation, UpdateTimeTrackingMutationVariables>;
export const TimeTrackingDocument = gql`
    query TimeTracking($actionId: String!) {
  timeTracking(actionId: $actionId) {
    _id
    startTime
    endTime
    loggedTime
    createdAt
    endTime
  }
}
    `;

/**
 * __useTimeTrackingQuery__
 *
 * To run a query within a React component, call `useTimeTrackingQuery` and pass it any options that fit your needs.
 * When your component renders, `useTimeTrackingQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useTimeTrackingQuery({
 *   variables: {
 *      actionId: // value for 'actionId'
 *   },
 * });
 */
export function useTimeTrackingQuery(baseOptions: Apollo.QueryHookOptions<TimeTrackingQuery, TimeTrackingQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<TimeTrackingQuery, TimeTrackingQueryVariables>(TimeTrackingDocument, options);
      }
export function useTimeTrackingLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<TimeTrackingQuery, TimeTrackingQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<TimeTrackingQuery, TimeTrackingQueryVariables>(TimeTrackingDocument, options);
        }
export type TimeTrackingQueryHookResult = ReturnType<typeof useTimeTrackingQuery>;
export type TimeTrackingLazyQueryHookResult = ReturnType<typeof useTimeTrackingLazyQuery>;
export type TimeTrackingQueryResult = Apollo.QueryResult<TimeTrackingQuery, TimeTrackingQueryVariables>;
export const UpdateUserFilterDocument = gql`
    mutation UpdateUserFilter($filter: UserFilterInput!) {
  updateUserFilter(filter: $filter) {
    _id
    workspaceId
    projectId
    userId
    hideCompleted
    columns
    labels
    priorities
    statuses
  }
}
    `;
export type UpdateUserFilterMutationFn = Apollo.MutationFunction<UpdateUserFilterMutation, UpdateUserFilterMutationVariables>;

/**
 * __useUpdateUserFilterMutation__
 *
 * To run a mutation, you first call `useUpdateUserFilterMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateUserFilterMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateUserFilterMutation, { data, loading, error }] = useUpdateUserFilterMutation({
 *   variables: {
 *      filter: // value for 'filter'
 *   },
 * });
 */
export function useUpdateUserFilterMutation(baseOptions?: Apollo.MutationHookOptions<UpdateUserFilterMutation, UpdateUserFilterMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<UpdateUserFilterMutation, UpdateUserFilterMutationVariables>(UpdateUserFilterDocument, options);
      }
export type UpdateUserFilterMutationHookResult = ReturnType<typeof useUpdateUserFilterMutation>;
export type UpdateUserFilterMutationResult = Apollo.MutationResult<UpdateUserFilterMutation>;
export type UpdateUserFilterMutationOptions = Apollo.BaseMutationOptions<UpdateUserFilterMutation, UpdateUserFilterMutationVariables>;
export const UserFilterDocument = gql`
    query UserFilter($workspaceId: ID!, $projectId: ID!, $layout: String!, $userId: ID!) {
  userFilter(
    workspaceId: $workspaceId
    projectId: $projectId
    layout: $layout
    userId: $userId
  ) {
    _id
    hideCompleted
    columns
    labels
    statuses
    priorities
  }
}
    `;

/**
 * __useUserFilterQuery__
 *
 * To run a query within a React component, call `useUserFilterQuery` and pass it any options that fit your needs.
 * When your component renders, `useUserFilterQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useUserFilterQuery({
 *   variables: {
 *      workspaceId: // value for 'workspaceId'
 *      projectId: // value for 'projectId'
 *      layout: // value for 'layout'
 *      userId: // value for 'userId'
 *   },
 * });
 */
export function useUserFilterQuery(baseOptions: Apollo.QueryHookOptions<UserFilterQuery, UserFilterQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<UserFilterQuery, UserFilterQueryVariables>(UserFilterDocument, options);
      }
export function useUserFilterLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<UserFilterQuery, UserFilterQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<UserFilterQuery, UserFilterQueryVariables>(UserFilterDocument, options);
        }
export type UserFilterQueryHookResult = ReturnType<typeof useUserFilterQuery>;
export type UserFilterLazyQueryHookResult = ReturnType<typeof useUserFilterLazyQuery>;
export type UserFilterQueryResult = Apollo.QueryResult<UserFilterQuery, UserFilterQueryVariables>;
export const UpdateUserProfileDocument = gql`
    mutation UpdateUserProfile($firstName: String, $lastName: String, $role: UserRoleEnum, $file: Upload) {
  updateUserProfile(
    input: {firstName: $firstName, lastName: $lastName, role: $role, file: $file}
  ) {
    _id
    profilePicture
    firstName
    lastName
    fullName
    email
    role
  }
}
    `;
export type UpdateUserProfileMutationFn = Apollo.MutationFunction<UpdateUserProfileMutation, UpdateUserProfileMutationVariables>;

/**
 * __useUpdateUserProfileMutation__
 *
 * To run a mutation, you first call `useUpdateUserProfileMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateUserProfileMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateUserProfileMutation, { data, loading, error }] = useUpdateUserProfileMutation({
 *   variables: {
 *      firstName: // value for 'firstName'
 *      lastName: // value for 'lastName'
 *      role: // value for 'role'
 *      file: // value for 'file'
 *   },
 * });
 */
export function useUpdateUserProfileMutation(baseOptions?: Apollo.MutationHookOptions<UpdateUserProfileMutation, UpdateUserProfileMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<UpdateUserProfileMutation, UpdateUserProfileMutationVariables>(UpdateUserProfileDocument, options);
      }
export type UpdateUserProfileMutationHookResult = ReturnType<typeof useUpdateUserProfileMutation>;
export type UpdateUserProfileMutationResult = Apollo.MutationResult<UpdateUserProfileMutation>;
export type UpdateUserProfileMutationOptions = Apollo.BaseMutationOptions<UpdateUserProfileMutation, UpdateUserProfileMutationVariables>;
export const UpdateUserDocument = gql`
    mutation UpdateUser($pushNotificationToken: String, $projectsOrder: [ID!]) {
  updateUser(
    pushNotificationToken: $pushNotificationToken
    projectsOrder: $projectsOrder
  ) {
    _id
    pushNotificationToken
    projectsOrder
  }
}
    `;
export type UpdateUserMutationFn = Apollo.MutationFunction<UpdateUserMutation, UpdateUserMutationVariables>;

/**
 * __useUpdateUserMutation__
 *
 * To run a mutation, you first call `useUpdateUserMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateUserMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateUserMutation, { data, loading, error }] = useUpdateUserMutation({
 *   variables: {
 *      pushNotificationToken: // value for 'pushNotificationToken'
 *      projectsOrder: // value for 'projectsOrder'
 *   },
 * });
 */
export function useUpdateUserMutation(baseOptions?: Apollo.MutationHookOptions<UpdateUserMutation, UpdateUserMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<UpdateUserMutation, UpdateUserMutationVariables>(UpdateUserDocument, options);
      }
export type UpdateUserMutationHookResult = ReturnType<typeof useUpdateUserMutation>;
export type UpdateUserMutationResult = Apollo.MutationResult<UpdateUserMutation>;
export type UpdateUserMutationOptions = Apollo.BaseMutationOptions<UpdateUserMutation, UpdateUserMutationVariables>;
export const UpdateUserMinimizedTasksDocument = gql`
    mutation UpdateUserMinimizedTasks($addMinimizedTaskId: ID, $removeMinimizedTaskId: ID) {
  updateUserMinimizedTasks(
    addMinimizedTaskId: $addMinimizedTaskId
    removeMinimizedTaskId: $removeMinimizedTaskId
  ) {
    _id
    minimizedTasks {
      _id
      title
    }
  }
}
    `;
export type UpdateUserMinimizedTasksMutationFn = Apollo.MutationFunction<UpdateUserMinimizedTasksMutation, UpdateUserMinimizedTasksMutationVariables>;

/**
 * __useUpdateUserMinimizedTasksMutation__
 *
 * To run a mutation, you first call `useUpdateUserMinimizedTasksMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateUserMinimizedTasksMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateUserMinimizedTasksMutation, { data, loading, error }] = useUpdateUserMinimizedTasksMutation({
 *   variables: {
 *      addMinimizedTaskId: // value for 'addMinimizedTaskId'
 *      removeMinimizedTaskId: // value for 'removeMinimizedTaskId'
 *   },
 * });
 */
export function useUpdateUserMinimizedTasksMutation(baseOptions?: Apollo.MutationHookOptions<UpdateUserMinimizedTasksMutation, UpdateUserMinimizedTasksMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<UpdateUserMinimizedTasksMutation, UpdateUserMinimizedTasksMutationVariables>(UpdateUserMinimizedTasksDocument, options);
      }
export type UpdateUserMinimizedTasksMutationHookResult = ReturnType<typeof useUpdateUserMinimizedTasksMutation>;
export type UpdateUserMinimizedTasksMutationResult = Apollo.MutationResult<UpdateUserMinimizedTasksMutation>;
export type UpdateUserMinimizedTasksMutationOptions = Apollo.BaseMutationOptions<UpdateUserMinimizedTasksMutation, UpdateUserMinimizedTasksMutationVariables>;
export const UsersDocument = gql`
    query Users($workspaceId: ID!, $projectId: ID) {
  users(workspaceId: $workspaceId, projectId: $projectId) {
    _id
    firstName
    lastName
    fullName
    email
    profilePicture
  }
}
    `;

/**
 * __useUsersQuery__
 *
 * To run a query within a React component, call `useUsersQuery` and pass it any options that fit your needs.
 * When your component renders, `useUsersQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useUsersQuery({
 *   variables: {
 *      workspaceId: // value for 'workspaceId'
 *      projectId: // value for 'projectId'
 *   },
 * });
 */
export function useUsersQuery(baseOptions: Apollo.QueryHookOptions<UsersQuery, UsersQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<UsersQuery, UsersQueryVariables>(UsersDocument, options);
      }
export function useUsersLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<UsersQuery, UsersQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<UsersQuery, UsersQueryVariables>(UsersDocument, options);
        }
export type UsersQueryHookResult = ReturnType<typeof useUsersQuery>;
export type UsersLazyQueryHookResult = ReturnType<typeof useUsersLazyQuery>;
export type UsersQueryResult = Apollo.QueryResult<UsersQuery, UsersQueryVariables>;
export const DashboardTasksByPeriodDocument = gql`
    query DashboardTasksByPeriod($workspaceId: ID!, $period: DashboardTaskPeriodEnum, $projectId: ID) {
  dashboardTasksByPeriod(
    workspaceId: $workspaceId
    period: $period
    projectId: $projectId
  ) {
    day
    count
    tasks {
      _id
      title
    }
  }
}
    `;

/**
 * __useDashboardTasksByPeriodQuery__
 *
 * To run a query within a React component, call `useDashboardTasksByPeriodQuery` and pass it any options that fit your needs.
 * When your component renders, `useDashboardTasksByPeriodQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useDashboardTasksByPeriodQuery({
 *   variables: {
 *      workspaceId: // value for 'workspaceId'
 *      period: // value for 'period'
 *      projectId: // value for 'projectId'
 *   },
 * });
 */
export function useDashboardTasksByPeriodQuery(baseOptions: Apollo.QueryHookOptions<DashboardTasksByPeriodQuery, DashboardTasksByPeriodQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<DashboardTasksByPeriodQuery, DashboardTasksByPeriodQueryVariables>(DashboardTasksByPeriodDocument, options);
      }
export function useDashboardTasksByPeriodLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<DashboardTasksByPeriodQuery, DashboardTasksByPeriodQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<DashboardTasksByPeriodQuery, DashboardTasksByPeriodQueryVariables>(DashboardTasksByPeriodDocument, options);
        }
export type DashboardTasksByPeriodQueryHookResult = ReturnType<typeof useDashboardTasksByPeriodQuery>;
export type DashboardTasksByPeriodLazyQueryHookResult = ReturnType<typeof useDashboardTasksByPeriodLazyQuery>;
export type DashboardTasksByPeriodQueryResult = Apollo.QueryResult<DashboardTasksByPeriodQuery, DashboardTasksByPeriodQueryVariables>;
export const GetCurrentUserDocument = gql`
    query GetCurrentUser {
  me {
    _id
    workspaceId
    firstName
    lastName
    fullName
    role
    email
    profilePicture
    pushNotificationToken
    timeTracking {
      _id
      startTime
      endTime
      totalTime
      status
      actionId {
        _id
        title
      }
    }
    minimizedTasks {
      _id
      title
    }
  }
}
    `;

/**
 * __useGetCurrentUserQuery__
 *
 * To run a query within a React component, call `useGetCurrentUserQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetCurrentUserQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetCurrentUserQuery({
 *   variables: {
 *   },
 * });
 */
export function useGetCurrentUserQuery(baseOptions?: Apollo.QueryHookOptions<GetCurrentUserQuery, GetCurrentUserQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetCurrentUserQuery, GetCurrentUserQueryVariables>(GetCurrentUserDocument, options);
      }
export function useGetCurrentUserLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetCurrentUserQuery, GetCurrentUserQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetCurrentUserQuery, GetCurrentUserQueryVariables>(GetCurrentUserDocument, options);
        }
export type GetCurrentUserQueryHookResult = ReturnType<typeof useGetCurrentUserQuery>;
export type GetCurrentUserLazyQueryHookResult = ReturnType<typeof useGetCurrentUserLazyQuery>;
export type GetCurrentUserQueryResult = Apollo.QueryResult<GetCurrentUserQuery, GetCurrentUserQueryVariables>;
export const UpdateWordDocument = gql`
    mutation UpdateWord($wordId: ID!, $isLearned: Boolean, $isSkip: Boolean) {
  updateWord(wordId: $wordId, isLearned: $isLearned, isSkip: $isSkip) {
    _id
    title
    isLearned
    skipCount
  }
}
    `;
export type UpdateWordMutationFn = Apollo.MutationFunction<UpdateWordMutation, UpdateWordMutationVariables>;

/**
 * __useUpdateWordMutation__
 *
 * To run a mutation, you first call `useUpdateWordMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateWordMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateWordMutation, { data, loading, error }] = useUpdateWordMutation({
 *   variables: {
 *      wordId: // value for 'wordId'
 *      isLearned: // value for 'isLearned'
 *      isSkip: // value for 'isSkip'
 *   },
 * });
 */
export function useUpdateWordMutation(baseOptions?: Apollo.MutationHookOptions<UpdateWordMutation, UpdateWordMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<UpdateWordMutation, UpdateWordMutationVariables>(UpdateWordDocument, options);
      }
export type UpdateWordMutationHookResult = ReturnType<typeof useUpdateWordMutation>;
export type UpdateWordMutationResult = Apollo.MutationResult<UpdateWordMutation>;
export type UpdateWordMutationOptions = Apollo.BaseMutationOptions<UpdateWordMutation, UpdateWordMutationVariables>;
export const RandomWordDocument = gql`
    query RandomWord {
  randomWord {
    _id
    title
    isLearned
    skipCount
  }
}
    `;

/**
 * __useRandomWordQuery__
 *
 * To run a query within a React component, call `useRandomWordQuery` and pass it any options that fit your needs.
 * When your component renders, `useRandomWordQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useRandomWordQuery({
 *   variables: {
 *   },
 * });
 */
export function useRandomWordQuery(baseOptions?: Apollo.QueryHookOptions<RandomWordQuery, RandomWordQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<RandomWordQuery, RandomWordQueryVariables>(RandomWordDocument, options);
      }
export function useRandomWordLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<RandomWordQuery, RandomWordQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<RandomWordQuery, RandomWordQueryVariables>(RandomWordDocument, options);
        }
export type RandomWordQueryHookResult = ReturnType<typeof useRandomWordQuery>;
export type RandomWordLazyQueryHookResult = ReturnType<typeof useRandomWordLazyQuery>;
export type RandomWordQueryResult = Apollo.QueryResult<RandomWordQuery, RandomWordQueryVariables>;
export const WordsDocument = gql`
    query Words {
  words {
    _id
    title
    isLearned
    skipCount
    type
    translation
  }
}
    `;

/**
 * __useWordsQuery__
 *
 * To run a query within a React component, call `useWordsQuery` and pass it any options that fit your needs.
 * When your component renders, `useWordsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useWordsQuery({
 *   variables: {
 *   },
 * });
 */
export function useWordsQuery(baseOptions?: Apollo.QueryHookOptions<WordsQuery, WordsQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<WordsQuery, WordsQueryVariables>(WordsDocument, options);
      }
export function useWordsLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<WordsQuery, WordsQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<WordsQuery, WordsQueryVariables>(WordsDocument, options);
        }
export type WordsQueryHookResult = ReturnType<typeof useWordsQuery>;
export type WordsLazyQueryHookResult = ReturnType<typeof useWordsLazyQuery>;
export type WordsQueryResult = Apollo.QueryResult<WordsQuery, WordsQueryVariables>;