import React, { useCallback, useEffect, useState, useRef } from 'react';
import {
  ExpandedState,
  getCoreRowModel,
  getSortedRowModel,
  getExpandedRowModel,
  SortingState,
  useReactTable,
} from '@tanstack/react-table';
import {
  DndContext,
  KeyboardSensor,
  MouseSensor,
  TouchSensor,
  closestCenter,
  type DragEndEvent,
  type DragStartEvent,
  type UniqueIdentifier,
  useSensor,
  useSensors,
  Modifier,
} from '@dnd-kit/core';
import {
  restrictToVerticalAxis,
  restrictToHorizontalAxis,
} from '@dnd-kit/modifiers';
import { calculateTaskRank } from 'src/helpers/task';
import {
  SortableContext,
  arrayMove,
  horizontalListSortingStrategy,
  verticalListSortingStrategy,
} from '@dnd-kit/sortable';
import SelectedRowsActions from 'src/components/design-system/TableView/SelectedRowsActions/SelectedRowsActions';
import { useTask } from 'src/hooks/useTask';
import { TableTask } from 'src/pages/Project/components/Table/types';
import { taskColumns } from 'src/components/design-system/TableView/columns';
import { useVirtualizer } from '@tanstack/react-virtual';
import styled from 'styled-components';
import TableRow from './TableRow';
import TableHeader from './TableHeader';
import { unionBy } from 'lodash';
import { Skeleton } from 'src/components/design-system';

type TableViewProps = {
  columns: string[];
  tasks: any;
  isLoading: boolean;
  hasMore: boolean;
  fetchMore: any;
  isFetchingMore: boolean;
  query: any;
};

export const Container = styled.div`
  width: 100%;
  overflow: auto;
  position: relative;
  background: ${({ theme }) => theme.background.primary};
  overscrollbehavior: 'contain';
  scrollbehavior: 'smooth';
  &::-webkit-scrollbar {
    height: 0;
  }

  table {
    width: 100%;
    border-collapse: collapse;
    background: #fff;
  }

  thead {
    border-top: 1px solid ${({ theme }) => theme.border.color.primary};
    display: grid;
    position: sticky;
    top: 0;
    z-index: 9;
    th {
      border-bottom: none;
    }
  }

  thead,
  tbody {
    background: ${({ theme }) => theme.background.primary};
  }

  tr {
    border-bottom: 1px solid ${({ theme }) => theme.border.color.primary};
  }

  th,
  td {
    text-align: left;
    height: 50px;
    border-bottom: 1px solid ${({ theme }) => theme.border.color.primary};
  }

  th {
    padding: 0 10px;
  }

  td:last-child,
  th:last-child {
    border-right: none;
  }
`;

const TableView = ({
  columns,
  tasks,
  isLoading,
  fetchMore,
  hasMore,
  isFetchingMore,
  query,
}: TableViewProps) => {
  const [data, setData] = useState(tasks);
  const [height, setHeight] = useState(300);
  const [modifiers, setModifiers] = useState<Modifier[]>([]);
  const [expanded, setExpanded] = useState<ExpandedState>({});
  const [sorting] = useState<SortingState>([]);

  const { onUpdateTask } = useTask();

  const tableContainerRef = useRef<HTMLDivElement>(null);

  // Filter columns based on visibleColumns array
  const filteredColumns = taskColumns.filter(
    col => col.accessorKey && columns.includes(col.accessorKey),
  );

  const [columnOrder, setColumnOrder] = React.useState<string[]>(() =>
    filteredColumns.map(c => c.accessorKey!),
  );

  const table = useReactTable({
    data,
    columns: filteredColumns,
    getCoreRowModel: getCoreRowModel(),
    getSortedRowModel: getSortedRowModel(),
    getExpandedRowModel: getExpandedRowModel(),
    state: {
      sorting,
      columnOrder,
      expanded,
    },
    onExpandedChange: setExpanded,
    // @ts-ignore
    getSubRows: row => row.subtasks,
    onColumnOrderChange: setColumnOrder,
    getRowId: row => row._id, //required because row indexes will change
    enableColumnResizing: true,
    enableRowSelection: true,
    manualSorting: true,
    columnResizeMode: 'onChange',
    meta: {
      onUpdateTask,
      optimisticUpdate: (rowIndex, cellId, value) => {
        // Skip page index reset until after next rerender
        // skipAutoResetPageIndex();
        setData(prev =>
          prev.map((row, index) => {
            if (index === rowIndex) {
              return {
                ...prev[rowIndex],
                ...value,
              };
            }
            return row;
          }),
        );
      },
    },
    // for debugging
    // debugTable: true,
    // debugHeaders: true,
    // debugColumns: true,
  });

  useEffect(() => {
    if (!isLoading) {
      setData(tasks);
    }
  }, [isLoading, query]);

  useEffect(() => {
    const updateHeight = () => {
      if (tableContainerRef.current?.parentElement) {
        setHeight(tableContainerRef.current.parentElement.clientHeight);
      }
    };

    updateHeight();
    window.addEventListener('resize', updateHeight); // Update on resize
    return () => window.removeEventListener('resize', updateHeight);
  }, []);

  const dataIds = React.useMemo<UniqueIdentifier[]>(
    () => data?.map(({ _id }) => _id),
    [data],
  );

  const sensors = useSensors(
    useSensor(MouseSensor, {}),
    useSensor(TouchSensor, {}),
    useSensor(KeyboardSensor, {}),
  );

  // reorder rows after drag & drop
  function handleDragEnd(event: DragEndEvent) {
    const { active, over } = event;

    if (!active || !over || active.id === over.id) return;

    // COLUMN REORDER
    const isColumnOrderChange =
      columnOrder.includes(active.id as string) ||
      columnOrder.includes(over.id as string);

    if (isColumnOrderChange) {
      setColumnOrder(columnOrder => {
        const oldIndex = columnOrder.indexOf(active.id as string);
        const newIndex = columnOrder.indexOf(over.id as string);
        return arrayMove(columnOrder, oldIndex, newIndex);
      });
      return;
    }

    // ROW REORDER
    const oldIndex = dataIds.indexOf(active.id);
    const newIndex = dataIds.indexOf(over.id);
    const newColumns = arrayMove(data, oldIndex, newIndex) as TableTask[];

    const aboveTask = newColumns[newIndex + 1];
    const belowTask = newColumns[newIndex - 1];
    const rank = calculateTaskRank(aboveTask, belowTask);

    const updatedColumns = newColumns.map(column =>
      column._id === active.id ? { ...column, rank } : column,
    );

    setData(updatedColumns);

    onUpdateTask({
      taskId: active.id as string,
      rank,
    });
  }

  const handleDragStart = useCallback(
    (event: DragStartEvent) => {
      setExpanded({});
      setModifiers(() => {
        if (columnOrder.includes(event.active.id as any)) {
          return [restrictToHorizontalAxis];
        }
        return [restrictToVerticalAxis];
      });
    },
    [columnOrder],
  );

  //called on scroll and possibly on mount to fetch more data as the user scrolls and reaches bottom of table
  const fetchMoreOnBottomReached = React.useCallback(
    (containerRefElement?: HTMLDivElement | null) => {
      if (containerRefElement) {
        const { scrollHeight, scrollTop, clientHeight } = containerRefElement;
        //once the user has scrolled within 500px of the bottom of the table, fetch more data if we can
        if (
          scrollHeight - scrollTop - clientHeight < 500 &&
          !isLoading &&
          hasMore
        ) {
          console.log('fetching more data');
          fetchMore({
            variables: {
              pagination: {
                skip: data.length,
              },
            },
            updateQuery: (prev, { fetchMoreResult }) => {
              if (!fetchMoreResult) return prev;
              const items = unionBy(
                [...prev.tasks.items, ...fetchMoreResult.tasks.items],
                '_id',
              );
              return {
                tasks: {
                  ...fetchMoreResult.tasks,
                  items,
                },
              };
            },
          });
        }
      }
    },
    [hasMore, isLoading],
  );

  const { rows } = table.getRowModel();

  const rowVirtualizer = useVirtualizer({
    count: rows.length,
    estimateSize: React.useCallback(() => 50, []), //estimate row height for accurate scrollbar dragging
    getScrollElement: () => tableContainerRef.current,
    //measure dynamic row height, except in firefox because it measures table border height incorrectly
    measureElement:
      typeof window !== 'undefined' &&
      navigator.userAgent.indexOf('Firefox') === -1
        ? element => element?.getBoundingClientRect().height
        : undefined,
    overscan: 5,
  });

  const selectedRows = Object.keys(table.getState().rowSelection);

  const virtualRows = rowVirtualizer.getVirtualItems();

  const totalSize = rowVirtualizer.getTotalSize();

  const paddingTop = React.useMemo(
    () => (virtualRows.length > 0 ? virtualRows?.[0]?.start || 0 : 0),
    [virtualRows],
  );
  const paddingBottom = React.useMemo(
    () =>
      virtualRows.length > 0
        ? totalSize - (virtualRows?.[virtualRows.length - 1]?.end || 0)
        : 0,
    [virtualRows, totalSize],
  );

  return (
    <DndContext
      collisionDetection={closestCenter}
      modifiers={modifiers}
      onDragEnd={handleDragEnd}
      onDragStart={handleDragStart}
      sensors={sensors}
    >
      <Container
        onScroll={e => fetchMoreOnBottomReached(e.currentTarget)}
        ref={tableContainerRef}
        style={{ height: `${height}px` }}
      >
        {/*Even though we're still using sematic table tags, we must use CSS grid and flexbox for dynamic row heights */}
        <table style={{ display: 'grid' }}>
          <thead>
            {table.getHeaderGroups().map(headerGroup => (
              <tr
                key={headerGroup.id}
                style={{ display: 'flex', width: '100%' }}
              >
                <SortableContext
                  items={columnOrder}
                  strategy={horizontalListSortingStrategy}
                >
                  {headerGroup.headers.map(header => {
                    return <TableHeader key={header.id} header={header} />;
                  })}
                </SortableContext>
              </tr>
            ))}
          </thead>
          <tbody
            style={{
              display: 'grid',
              height: `${rowVirtualizer.getTotalSize()}px`, //tells scrollbar how big the table is
              position: 'relative', //needed for absolute positioning of rows
            }}
          >
            <SortableContext
              items={dataIds}
              strategy={verticalListSortingStrategy}
            >
              {paddingTop > 0 && (
                <tr>
                  <td style={{ height: `${paddingTop}px` }} />
                </tr>
              )}
              {virtualRows.map(virtualRow => {
                const row = rows[virtualRow.index];
                return (
                  <TableRow
                    key={row.id}
                    row={row}
                    virtualRow={virtualRow}
                    columnOrder={columnOrder}
                    table={table}
                  />
                );
              })}
              {paddingBottom > 0 && (
                <tr>
                  <td style={{ height: `${paddingBottom}px` }} />
                </tr>
              )}
            </SortableContext>
          </tbody>
        </table>
      </Container>
      {isFetchingMore && <Skeleton height={30} width="100%" />}
      {selectedRows.length > 0 && (
        <SelectedRowsActions selectedRows={selectedRows} table={table} />
      )}
    </DndContext>
  );
};

export default TableView;
