import styled, { css, keyframes } from 'styled-components';
import { useReactiveVar } from '@apollo/client';
import { currentThemeVar } from 'src/graphql/vars';
import { useMemo } from 'react';
import { THEME } from 'src/styles/theme/types';

const fadeIn = keyframes`
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
`;

type RayContainerProps = {
  $isLightTheme: boolean;
};

const RayContainer = styled.div<RayContainerProps>`
  --gradient-opacity: 0.85;
  --ray-gradient: radial-gradient(
    rgba(83, 196, 255, var(--gradient-opacity)) 0%,
    rgba(43, 166, 255, 0) 100%
  );
  transition: opacity 0.25s linear;
  position: fixed;
  inset: 0;
  left: 240px;
  pointer-events: none;
  user-select: none;
  animation: ${fadeIn} 0.25s linear;
  ${({ $isLightTheme }) =>
    $isLightTheme &&
    css`
      --gradient-opacity: 0.5;
    `}
`;

const LightRay = styled.div`
  position: absolute;
  border-radius: 100%;
  background: var(--ray-gradient);
`;

const RayOne = styled(LightRay)`
  width: 480px;
  height: 680px;
  transform: rotate(80deg);
  top: -540px;
  left: 250px;
  filter: blur(110px);
`;

const RayTwo = styled(LightRay)`
  width: 110px;
  height: 400px;
  transform: rotate(-20deg);
  top: -280px;
  left: 350px;
  mix-blend-mode: overlay;
  opacity: 0.6;
  filter: blur(60px);
`;

const RayThree = styled(LightRay)`
  width: 400px;
  height: 370px;
  top: -350px;
  left: 200px;
  mix-blend-mode: overlay;
  opacity: 0.6;
  filter: blur(21px);
`;

const RayFour = styled(LightRay)`
  width: 330px;
  height: 370px;
  top: -330px;
  left: 50px;
  mix-blend-mode: overlay;
  opacity: 0.5;
  filter: blur(21px);
`;

const RayFive = styled(LightRay)`
  width: 110px;
  height: 400px;
  transform: rotate(-40deg);
  top: -280px;
  left: -10px;
  mix-blend-mode: overlay;
  opacity: 0.8;
  filter: blur(60px);
`;

const LightRays = () => {
  const theme = useReactiveVar(currentThemeVar);

  const isLightTheme = useMemo(() => theme === THEME.LIGHT, [theme]);

  return (
    <RayContainer $isLightTheme={isLightTheme}>
      <RayOne />
      <RayTwo />
      <RayThree />
      <RayFour />
      <RayFive />
    </RayContainer>
  );
};

export default LightRays;
