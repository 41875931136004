import styled from 'styled-components';
import { Flex } from 'src/components/design-system';

export const Container = styled(Flex)`
  position: absolute;
  bottom: 100px;
  left: 50%;
  transform: translateX(-50%);
  z-index: 100;
  border-radius: 8px;
  border: 1px solid ${({ theme }) => theme.border.color.primary};
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
  background-color: ${({ theme }) => theme.background.dark};
  color: ${({ theme }) => theme.text.white};
  height: 48px;
  min-width: 240px;
  width: 100%;
  max-width: 640px;
}
`;
