import { useQueryParams } from './useQueryParams';
import { editingCommentVar } from 'src/graphql/vars';

import {
  useCommentsQuery,
  useDeleteCommentMutation,
  useUpdateCommentMutation,
  useCreateCommentMutation,
  useAddReactionMutation,
  useRemoveReactionMutation,
  CreateCommentMutationVariables,
} from 'src/generated';

export const useComments = () => {
  const query = useQueryParams();
  const taskId = query.get('taskId') as string;

  const { data, loading, error } = useCommentsQuery({
    variables: { taskId },
  });

  const [createComment] = useCreateCommentMutation();

  const [deleteComment] = useDeleteCommentMutation({
    update: (cache, { data }) => {
      if (!data?.deleteComment) return;
      cache.evict({ id: cache.identify(data.deleteComment) });
      cache.gc();
    },
  });

  const [updateComment] = useUpdateCommentMutation({
    update: (cache, { data }) => {
      if (!data?.updateComment) return;

      cache.modify({
        id: cache.identify(data.updateComment),
        fields: {
          text: () => data.updateComment.text,
        },
      });
      editingCommentVar(null);
    },
  });

  const [addReaction] = useAddReactionMutation();

  const [removeReaction] = useRemoveReactionMutation({
    update: (cache, { data }) => {
      if (!data?.removeReaction) return;

      const { removeReaction } = data;

      cache.modify({
        id: cache.identify(data.removeReaction),
        fields: {
          reactions: (existingReactions = []) => {
            return existingReactions.filter(
              (reactionRef: any) =>
                cache.identify(reactionRef) !== cache.identify(removeReaction),
            );
          },
        },
        broadcast: false,
      });
    },
  });

  return {
    loading,
    comments: data?.comments,
    error,
    onCreateComment: async ({
      text,
      type,
      sticker,
      taskId,
      projectId,
    }: CreateCommentMutationVariables) => {
      await createComment({
        variables: { text, type, taskId, sticker, projectId },
      });
    },
    onDeleteComment: async (commentId: string) => {
      await deleteComment({
        variables: { id: commentId },
      });
    },
    onUpdateComment: async ({ commentId, text }) => {
      await updateComment({
        variables: { commentId, text },
      });
    },
    onAddReaction: async ({ commentId, emoji }) => {
      await addReaction({
        variables: { commentId, emoji },
      });
    },
    onRemoveReaction: async ({ commentId, emoji }) => {
      await removeReaction({
        variables: { commentId, emoji },
        optimisticResponse: {
          __typename: 'Mutation',
          removeReaction: {
            __typename: 'Comment',
            _id: commentId,
            reactions: [
              {
                __typename: 'Reaction',
                id: 'optimisticResponse',
                emoji,
              },
            ],
          },
        },
      });
    },
  };
};
