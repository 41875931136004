import React, { CSSProperties, useCallback } from 'react';
import { useNavigate } from 'react-router-dom';

import { Icon, Image } from 'src/components/design-system';
import { useTheme } from 'styled-components';

import { useQueryParams } from 'src/hooks/useQueryParams';
import { useTask } from 'src/hooks/useTask';

import { useSortable } from '@dnd-kit/sortable';
import { CSS } from '@dnd-kit/utilities';
import { useIsTaskCompleted } from 'src/helpers/task';

import { Action } from 'src/generated';

import * as Styled from './styles';

type SubTasksProps = {
  task: Action;
};

const SubTask = ({ task }: SubTasksProps) => {
  const navigate = useNavigate();
  const query = useQueryParams();
  const theme = useTheme();

  const { _id, title, attachments, projectId } = task;
  const firstAttachment = attachments && attachments[0];
  const isTaskCompleted = useIsTaskCompleted(task as Action);

  const { completedStatusId, unstagedStatusId } = projectId || {};

  const {
    attributes,
    listeners,
    transform,
    transition,
    setNodeRef,
    isDragging,
  } = useSortable({
    id: _id,
  });

  const { onUpdateTask } = useTask();

  const handleUpdateTaskStatus = useCallback(
    e => {
      e.stopPropagation();
      onUpdateTask({
        taskId: _id,
        statusId: isTaskCompleted ? unstagedStatusId : completedStatusId,
      });
    },
    [_id, completedStatusId, isTaskCompleted, onUpdateTask, unstagedStatusId],
  );

  const onGoToSubtask = (subtaskId: string) => {
    query.set('taskId', subtaskId);
    navigate({
      search: query.toString(),
    });
  };

  const style: CSSProperties = {
    transform: CSS.Transform.toString(transform),
    transition: transition,
    opacity: isDragging ? 0.8 : 1,
    zIndex: isDragging ? 1 : 0,
    position: 'relative',
  };

  return (
    <Styled.Container
      $gap={10}
      $alignItems="center"
      $isDragging={isDragging}
      ref={setNodeRef}
      style={style}
    >
      <Styled.ReorderIcon {...attributes} {...listeners}>
        <Icon name="reorder" />
      </Styled.ReorderIcon>
      <Styled.Subtask
        $gap={10}
        $alignItems="center"
        onClick={() => onGoToSubtask(_id)}
      >
        <div onClick={handleUpdateTaskStatus}>
          <Icon
            name={isTaskCompleted ? 'circle-check-filled' : 'circle-check'}
            color={isTaskCompleted ? theme.color.success : ''}
          />
        </div>
        {firstAttachment && (
          <Styled.Attachment>
            <Image src={firstAttachment.urls?.thumbnail!} alt="" />
          </Styled.Attachment>
        )}
        <Styled.Title $isTaskCompleted={isTaskCompleted}>{title}</Styled.Title>
      </Styled.Subtask>
    </Styled.Container>
  );
};

export default SubTask;
