import { create } from 'zustand';

interface AppStore {
  isOpenTaskTemplateModal: boolean;
  updateIsOpenTaskTemplateModal: (isOpen: boolean) => void;
  logs: string[];
  addLog: (log: string) => void;
}

export const useAppStore = create<AppStore>(set => ({
  logs: [],
  isOpenTaskTemplateModal: false,
  updateIsOpenTaskTemplateModal: (isOpen: boolean) =>
    set({ isOpenTaskTemplateModal: isOpen }),
  addLog: (log: string) => set(state => ({ logs: [...state.logs, log] })),
}));

export const useAppLogs = () => useAppStore(state => state.logs);

export const useIsOpenTaskTemplateModal = () =>
  useAppStore(state => state.isOpenTaskTemplateModal);

export const useUpdateIsOpenTaskTemplateModal = () =>
  useAppStore(state => state.updateIsOpenTaskTemplateModal);
