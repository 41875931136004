import React, { useState, useRef, useCallback } from 'react';
import { useTranslation } from 'react-i18next';

import { useReactiveVar } from '@apollo/client';

import {
  RichText,
  Avatar,
  Button,
  MessageEditor,
  Icon,
} from 'src/components/design-system';

import useOnClickOutside from 'src/hooks/common/useOnClickOutside';
import { useComments } from 'src/hooks/useComments';

import { DateFormats, DateHelpers } from 'src/common/helpers';

import EmojiPicker from 'src/widgets/EmojiPicker/EmojiPicker';

import Sticker from '../Stickers/Sticker/Sticker';
import CommentReactions from './CommentReactions';
import { editingCommentVar } from 'src/graphql/vars';
import { useUser } from 'src/store';

import { CommentsQuery, CommentTypeEnum } from 'src/generated';

import * as Styled from './styles';

type CommentProps = CommentsQuery['comments'][0] & {
  onDeleteComment: (commentId: string) => void;
};

const Comment = ({
  _id,
  text,
  type,
  sticker,
  createdAt,
  user,
  isEdited,
  reactions,
  onDeleteComment,
}: CommentProps) => {
  const { t } = useTranslation();
  const currentUser = useUser();
  const { fullName } = user;
  const editingComment = useReactiveVar(editingCommentVar);
  const [showEmojiPicker, setShowEmojiPicker] = useState(false);
  const ref = useRef(null);
  const isCommentOwner = currentUser?._id === user._id;
  const isAllowedToEdit = isCommentOwner && CommentTypeEnum.Text === type;
  const isStickerType = [
    CommentTypeEnum.Sticker,
    CommentTypeEnum.AnimatedSticker,
  ].includes(type);
  const { onAddReaction, onRemoveReaction } = useComments();

  useOnClickOutside(ref, () => {
    setShowEmojiPicker(false);
  });

  const handleEditComment = (commentId: string) => {
    editingCommentVar(commentId);
  };

  const handleRemoveEmoji = useCallback(
    ({ emoji, users }) => {
      const isAddedByCurrentUser = users.some(
        ({ _id }) => _id === currentUser._id,
      );
      if (!isAddedByCurrentUser) return;

      onRemoveReaction({ commentId: _id, emoji });
    },
    [_id, currentUser._id, onRemoveReaction],
  );

  return (
    <>
      {editingComment && _id === editingComment ? (
        <MessageEditor
          taskId={''}
          projectId={''}
          messageId={_id}
          defaultText={text}
          placeholder={t('task.comments.addCommentPlaceholder')}
        />
      ) : (
        <Styled.CommentBlock>
          <Avatar user={user} radius={2} />
          <Styled.CommentBody>
            <div>
              <strong>{fullName}</strong>
              <Styled.Time>
                {DateHelpers.formatDate(createdAt, DateFormats.FullDate)}
                {isEdited && <>{` (${t('task.comments.isEdited')})`}</>}
              </Styled.Time>
            </div>
            <div>
              {type === CommentTypeEnum.Text && text && (
                <RichText content={text} />
              )}
              {sticker && isStickerType && (
                <Sticker
                  playInViewport
                  type={type}
                  url={sticker}
                  stickersName={sticker}
                  size={200}
                />
              )}
            </div>
            <CommentReactions
              reactions={reactions}
              handleRemoveEmoji={handleRemoveEmoji}
            />
          </Styled.CommentBody>

          <Styled.CommentActions visible={showEmojiPicker}>
            {isAllowedToEdit && (
              <Button size="small" onClick={() => handleEditComment(_id)}>
                <Icon name="edit" size={14} />
              </Button>
            )}
            <Button size="small" onClick={() => setShowEmojiPicker(true)}>
              <Icon name="like" />
            </Button>
            {isCommentOwner && (
              <Button size="small" onClick={() => onDeleteComment(_id)}>
                <Icon name="close" size={20} />
              </Button>
            )}
            {showEmojiPicker && (
              <Styled.EmojiPickerWrapper ref={ref}>
                <EmojiPicker
                  onEmojiSelect={(emoji: any) => {
                    const isEmojiAlreadyAdded = reactions.some(
                      ({ emoji: reactionEmoji, user }) =>
                        reactionEmoji === emoji.id &&
                        user._id === currentUser._id,
                    );
                    if (isEmojiAlreadyAdded) return;

                    onAddReaction({ commentId: _id, emoji: emoji.id });
                  }}
                />
              </Styled.EmojiPickerWrapper>
            )}
          </Styled.CommentActions>
        </Styled.CommentBlock>
      )}
    </>
  );
};

export default Comment;
