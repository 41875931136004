import styled, { css } from 'styled-components';
import { PRIORITY } from 'src/common/constants';

type TableRowProps = {
  $isCompleted?: boolean;
  $isSelected?: boolean;
  $priority?: PRIORITY;
};

export const TableRow = styled.tr<TableRowProps>`
  background-color: ${({ theme }) => theme.task.primary};
  td {
    background: transparent;
  }

  ${({ $priority }) => {
    if ($priority === PRIORITY.ARGENT) {
      return css`
        background-color: ${({ theme }) => theme.task.urgent};
        td {
          background-color: ${({ theme }) => theme.task.urgent};
        }
      `;
    }

    if ($priority === PRIORITY.HIGH) {
      return css`
        background-color: ${({ theme }) => theme.task.high};
        td {
          background-color: ${({ theme }) => theme.task.high};
        }
      `;
    }
  }}

  ${({ $isCompleted }) =>
    $isCompleted &&
    css`
      background-color: ${({ theme }) => theme.task.completed};
    `}

  ${({ $isSelected }) =>
    $isSelected &&
    css`
      background-color: ${({ theme }) => theme.task.selected};
      td {
        background-color: ${({ theme }) => theme.task.selected};
      }
    `}
`;
