/**
 *
 * App
 *
 * This component is the skeleton around the actual pages, and should only
 * contain code that should be seen on all pages. (e.g. navigation bar)
 */
import React, { lazy, useState } from 'react';

import { useQuery } from '@apollo/client';
import { Helmet } from 'react-helmet-async';
import { BrowserRouter, Route, Routes } from 'react-router-dom';
import { Snowfall } from 'src/components/design-system';
import { useUserStore } from 'src/store';

import Public from './Public';
import Root from './Root';
import { GET_PROJECTS } from './graphql/projects';
import { GET_CURRENT_USER } from './graphql/users';
import Books from './pages/Books';
import Forms from './pages/Forms/Forms';
import Home from './pages/Home';
import Mails from './pages/Mails/Mails';
import Miro from './pages/Miro/Miro';
import Project from './pages/Project';
import Projects from './pages/Projects';
import { DateHelpers } from 'src/common/helpers';

const MiroBoard = lazy(() => import('./pages/MiroBoard/MiroBoard'));
const CodeApp = lazy(() => import('./pages/CodeApp/CodeApp'));
const NotFoundPage = lazy(() => import('./pages/NotFoundPage'));
const Appearance = lazy(() => import('./pages/Settings/Appearance'));
const Notifications = lazy(() => import('./pages/Settings/Notifications'));
const Profile = lazy(() => import('./pages/Settings/Profile/Profile'));
const Settings = lazy(() => import('./pages/Settings/Settings'));
const TrashBin = lazy(() => import('./pages/Settings/TrashBin'));
const Logs = lazy(() => import('./pages/Settings/Logs'));
const Translate = lazy(() => import('src/pages/Translate/Translate'));

import { GlobalStyle } from './styles/global-styles';

export function PrivateApp() {
  // get the current user projects
  const { loading, error } = useQuery(GET_PROJECTS);

  if (error) return <p>{'error'}</p>;
  if (loading) return null;

  return (
    <>
      <Root />
      <Routes>
        <Route path="/" element={<Home />} />
        <Route path="/projects" element={<Projects />} />
        <Route path="/projects/:id" element={<Project />} />
        <Route path="/books" element={<Books />} />
        <Route path="/miro" element={<Miro />} />
        <Route path="/miro/:miroId" element={<MiroBoard />} />
        <Route path="/mails" element={<Mails />} />
        <Route path="/forms" element={<Forms />} />
        <Route path="/code" element={<CodeApp />} />
        <Route path="/translate" element={<Translate />} />
        <Route path="/settings" element={<Settings />}>
          <Route path="profile" element={<Profile />} />
          <Route path="appearance" element={<Appearance />} />
          <Route path="notifications" element={<Notifications />} />
          <Route path="trash-bin" element={<TrashBin />} />
          <Route path="logs" element={<Logs />} />
        </Route>
        <Route path="*" element={<NotFoundPage />} />
      </Routes>
    </>
  );
}

const Content = () => {
  const user = useUserStore(state => state.user);

  if (!user) {
    return <Public />;
  }

  return <PrivateApp />;
};

export function App() {
  const setUser = useUserStore(state => state.setUser);
  const [isLoad, setIsLoad] = useState(false);

  useQuery(GET_CURRENT_USER, {
    onCompleted({ me }) {
      setUser(me);
      setIsLoad(true);
    },
    onError() {
      setIsLoad(true);
    },
  });

  if (!isLoad) return null;

  return (
    <>
      <Helmet titleTemplate="%s: Manage app" defaultTitle="Manage app">
        <meta name="manage app" content="Manage App" />
      </Helmet>
      <BrowserRouter>
        <Content />
        <GlobalStyle />
      </BrowserRouter>
      {DateHelpers.isWinter() && <Snowfall />}
    </>
  );
}
