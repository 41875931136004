import { useNavigate, useSearchParams } from 'react-router-dom';

import { useApolloClient } from '@apollo/client';

import {
  useNotification,
  playConfettiAnimation,
} from 'src/components/design-system';

import { useProject } from '../helpers/apollo';
import { updateKanbanCache } from 'src/helpers/apollo';

import { useQueryParams } from './useQueryParams';
import omit from 'lodash/omit';
import { TASK_FRAGMENT } from 'src/graphql/actions';
import { useUserWorkspace } from 'src/store';
import { useProjectId } from 'src/hooks/custom';

import {
  useDeleteTasksMutation,
  useCreateTaskMutation,
  useDuplicateTaskMutation,
  useUpdateTaskMutation,
  useApplyTaskTemplateMutation,
  KanbanTasksDocument,
  UpdateTaskInput,
  TasksDocument,
  CreateTaskMutationVariables,
  LabelsDocument,
  ApplyTaskTemplateMutationVariables,
} from 'src/generated';
import { useCallback } from 'react';

type CreateTaskMutationProps = Omit<
  CreateTaskMutationVariables,
  'workspaceId' | 'projectId'
>;

export const useTask = () => {
  const projectId = useProjectId();
  const client = useApolloClient();
  const query = useQueryParams();
  const navigate = useNavigate();
  const workspaceId = useUserWorkspace();
  const [searchParams] = useSearchParams();
  const {
    project: { color },
  } = useProject();
  const viewType = searchParams.get('view');
  const notification = useNotification();

  const [updateTask] = useUpdateTaskMutation();
  const [deleteTask] = useDeleteTasksMutation();
  const [duplicateTask] = useDuplicateTaskMutation();
  const [createTask] = useCreateTaskMutation();
  const [applyTaskTemplate] = useApplyTaskTemplateMutation();

  const handleOpenTask = useCallback(
    ({ taskId }) => {
      query.set('taskId', taskId);
      navigate({
        search: query.toString(),
      });
    },
    [navigate, query],
  );

  const onDuplicateTask = async ({ taskId }) => {
    await duplicateTask({
      variables: { taskId },
      update(cache, { data }) {
        if (!data?.duplicateTask) return;
        const { duplicateTask } = data;
        updateKanbanCache(cache, duplicateTask);
      },
      onCompleted: ({ duplicateTask }) => {
        const { _id } = duplicateTask;
        notification.show({
          title: 'Task duplicated',
          message: 'Task was successfully duplicated',
          variant: 'success',
        });
        handleOpenTask({ taskId: _id });
      },
    });
  };

  const onDeleteTasks = async ({ taskIds }) => {
    await deleteTask({
      variables: { taskIds, projectId },
      optimisticResponse: {
        __typename: 'Mutation',
        deleteTasks: taskIds.map((id: string) => ({
          __typename: 'Action',
          _id: id,
        })),
      },
      update(cache, { data }) {
        if (!data?.deleteTasks) return;

        console.log('data', data);

        const { deleteTasks } = data;

        deleteTasks.forEach((deletedTask: any) => {
          cache.evict({ id: cache.identify(deletedTask) });
        });
        cache.gc();

        cache.updateQuery(
          {
            query: KanbanTasksDocument,
            variables: { projectId },
          },
          (cachedTasks: any) => {
            const { kanbanTasks = [] } = cachedTasks || {};

            return {
              kanbanTasks: kanbanTasks.map((column: any) => {
                const remainingActions = column.actions.filter(
                  (action: any) => !taskIds.includes(action._id),
                );

                return {
                  ...column,
                  tasksCount: remainingActions.length, // Update tasksCount after deletion
                  actions: remainingActions, // Ensure actions are updated too
                };
              }),
            };
          },
        );
      },
      onCompleted: () => {
        notification.show({
          title: 'Task(s) deleted',
          message: 'Task(s) was successfully deleted',
          variant: 'success',
        });
      },
    });
  };

  const onCreateTask = ({
    title,
    statusId,
    parentId,
    dueDate,
  }: CreateTaskMutationProps) => {
    createTask({
      variables: {
        // get workspaceId and projectId from the hook
        workspaceId,
        projectId,
        // pass the rest of the variables
        statusId,
        title,
        parentId,
        dueDate,
      },
      optimisticResponse: {
        __typename: 'Mutation',
        createTask: {
          __typename: 'Action',
          _id: 'temp-id',
          workspaceId: workspaceId || 'temp-workspace-id',
          projectId: {
            _id: projectId || 'temp-project-id',
            color,
            completedStatusId: '',
            unstagedStatusId: '',
          },
          parentId: {
            _id: parentId || '',
            title: 'temp-parent-title',
          },
          title,
          statusId: statusId || 'temp-status-id',
          labels: [],
          dueDate,
        },
      },
      update(cache, { data }) {
        if (!data?.createTask) return;
        const { _id, parentId } = data.createTask;
        const taskParent = parentId?._id;

        if (taskParent) {
          cache.updateQuery(
            {
              query: TasksDocument,
              variables: {
                filter: {
                  workspaceId,
                  parentId: taskParent,
                },
              },
            },
            subtasks => {
              const tasks = subtasks?.tasks.items || [];
              return {
                tasks: {
                  items: [...tasks, data.createTask],
                },
              };
            },
          );
          //  update parent totalSubtasksCount field
          cache.modify({
            id: cache.identify({
              _id,
              __typename: 'Action',
            }),
            fields: {
              totalSubtasksCount: (prev: number) => prev + 1,
            },
          });
        } else {
          // update task calendar view
          cache.updateQuery(
            {
              query: TasksDocument,
              variables: { projectId, workspaceId },
            },
            response => {
              if (!response) return;
              const tasks = response?.tasks.items || [];
              return {
                tasks: {
                  items: [...tasks, data.createTask],
                },
              };
            },
          );
          updateKanbanCache(cache, data.createTask);
        }
      },
    }).then(({ data }) => {
      if (viewType !== 'calendar') return;
      const taskId = data?.createTask._id;
      if (!taskId) return;
      handleOpenTask({ taskId });
    });
  };

  const onUpdateTask = (
    updatedTask: UpdateTaskInput & { shouldUpdateCache?: boolean },
  ) => {
    const { taskId, statusId, shouldUpdateCache = true } = updatedTask;

    const task = client.readFragment({
      id: `Action:${taskId}`, // Format: <Typename>:<id>
      fragment: TASK_FRAGMENT,
    }) as any;

    const projectLabels =
      client.readQuery({
        query: LabelsDocument,
        variables: { workspaceId, projectId },
      })?.labels || [];

    const taskLabels = task?.labels || [];
    const { addLabelIds = [], removeLabelIds = [] } = updatedTask;

    // Compute the new label set optimistically
    const newLabels = taskLabels
      .filter(({ _id }) => !removeLabelIds?.includes(_id))
      .concat(projectLabels.filter(({ _id }) => addLabelIds?.includes(_id)));

    const variables = omit(updatedTask, ['shouldUpdateCache']);

    updateTask({
      variables: {
        updateTaskInput: variables,
      },
      optimisticResponse: {
        __typename: 'Mutation',
        updateTask: {
          __typename: 'Action',
          _id: taskId,
          ...task,
          ...updatedTask,
          createdAt: new Date(),
          updatedAt: new Date(),
          labels: newLabels,
        },
      },
      update: (cache, { data }) => {
        if (!data?.updateTask) return;
        const { updateTask } = data;
        const { statusId } = updateTask;
        if (!shouldUpdateCache || task.statusId === statusId) return;
        updateKanbanCache(cache, updateTask, task.statusId);
      },
    });

    if (statusId && statusId === task?.projectId?.completedStatusId) {
      notification.show({
        title: 'Done',
        message: `Task "${task.title}" was successfully completed`,
        variant: 'success',
      });
      playConfettiAnimation(['left', 'right']);
    }
  };

  const onApplyTaskTemplate = async ({
    taskId,
    templateId,
  }: ApplyTaskTemplateMutationVariables) => {
    await applyTaskTemplate({
      variables: {
        taskId,
        templateId,
      },
      onCompleted: () => {
        notification.show({
          title: 'Task template applied',
          message: `Task template was successfully applied`,
          variant: 'success',
        });
      },
    });
  };

  const onCopyShareLink = () => {
    navigator.clipboard.writeText(window.location.href);
    notification.show({
      title: 'Link copied',
      message: 'Link was successfully copied to clipboard',
      variant: 'success',
    });
  };

  return {
    onCreateTask,
    onDeleteTasks,
    onDuplicateTask,
    onCopyShareLink,
    onUpdateTask,
    onTaskOpen: handleOpenTask,
    onApplyTaskTemplate,
  };
};
