import React from 'react';
import { useSearchParams } from 'react-router-dom';
import TaskDescriptionHistoryDrawer from 'src/widgets/Drawers/TaskDescriptionHistoryDrawer/TaskDescriptionHistoryDrawer';
import TaskAdvancedFieldsDrawer from 'src/widgets/Drawers/TaskAdvancedFieldsDrawer/TaskAdvancedFieldsDrawer';
import TaskTemplates from './components/Board/Action/TaskTemplates/TaskTemplates';
import BoardContainer from './components/Board/BoardContainer';
import Calendar from './components/Calendar/Calendar';
import Dashboard from './components/Dashboard/Dashboard';
import EditProject from './components/EditProject/EditProject';
import EditStatusModal from './components/EditStatusModal/EditStatusModal';
import Files from './components/Files/Files';
import Overview from './components/Overview/Overview';
import ProjectFilterContainer from 'src/widgets/Drawers/ProjectFilterDrawer/ProjectFilterContainer';
import Table from './components/Table/Table';
import CustomFieldsDrawer from 'src/widgets/Drawers/CustomFieldsDrawer/CustomFieldsDrawer';
import InviteProjectMembers from 'src/widgets/InviteProjectMemebers/InviteProjectMembers';
import UnsplashDrawer from 'src/widgets/Drawers/UnsplashDrawer/UnsplashDrawer';
import { ProjectViews } from './types';
import { useUserFilterQuery, useGetProjectQuery } from 'src/generated';
import { useUser } from 'src/store';
import { useProjectId } from 'src/hooks/custom';

const ProjectInfo = () => {
  const user = useUser();
  const projectId = useProjectId();
  const [searchParams] = useSearchParams();
  const view = searchParams.get('view')!;

  const { loading } = useGetProjectQuery({
    variables: { projectId },
  });

  const { loading: userFilterLoading } = useUserFilterQuery({
    variables: {
      workspaceId: user.workspaceId,
      projectId,
      userId: user._id,
      layout: view,
    },
  });

  if (loading || userFilterLoading) return null;

  const component = () => {
    switch (view) {
      case ProjectViews.Dashboard:
        return <Dashboard />;
      case ProjectViews.Table:
        return <Table />;
      case ProjectViews.Files:
        return <Files />;
      case ProjectViews.Calendar:
        return <Calendar />;
      case ProjectViews.Overview:
        return <Overview />;
      default:
        return <BoardContainer />;
    }
  };

  return (
    <>
      {component()}
      <EditProject />
      <TaskDescriptionHistoryDrawer />
      <TaskAdvancedFieldsDrawer />
      <ProjectFilterContainer />
      <EditStatusModal />
      <CustomFieldsDrawer />
      <InviteProjectMembers />
      <UnsplashDrawer />
      <TaskTemplates />
    </>
  );
};

export default ProjectInfo;
