import React, { useState } from 'react';

import { Icon, Image } from 'src/components/design-system';

import { useTask } from 'src/hooks/useTask';

import FileModal from '../../../FileModal/FileModal';
import { formatBytes } from 'src/helpers/files';

import * as Styled from './styles';

const Attachments = ({ id, attachments }) => {
  const [isOpenModal, setIsOpenModal] = useState(false);
  const [initialSlide, setInitialSlide] = useState(0);

  const { onUpdateTask } = useTask();

  const handleRemoveAttachment = (e, attachmentId) => {
    e.preventDefault();
    e.stopPropagation();
    onUpdateTask({
      taskId: id,
      removeAttachmentIds: [attachmentId],
    });
  };

  const handleOpenModal = index => {
    setInitialSlide(index);
    setIsOpenModal(true);
  };

  return (
    <>
      <Styled.AttachmentsList>
        {attachments.map(({ _id, urls, fileName, size, type }: any, index) => (
          <Styled.AttachmentItem
            key={index}
            onClick={() => handleOpenModal(index)}
          >
            <Styled.AttachmentItemImg>
              <Image src={urls.thumbnail} alt={fileName} />
            </Styled.AttachmentItemImg>
            <Styled.AttachmentItemBody>
              <Styled.AttachmentItemTitle>
                {fileName}
              </Styled.AttachmentItemTitle>
              <Styled.AttachmentItemInfo>
                <Styled.AttachmentItemInfoText>
                  <div>{formatBytes(size)}</div>
                  <div>{type}</div>
                </Styled.AttachmentItemInfoText>
                {/*<Progress style={{ width: progressInfo.percentage + '%' }} />*/}
              </Styled.AttachmentItemInfo>
            </Styled.AttachmentItemBody>
            <Styled.AttachmentItemCloseBtn
              size="small"
              type="button"
              onClick={e => handleRemoveAttachment(e, _id)}
            >
              <Icon name="close" />
            </Styled.AttachmentItemCloseBtn>
          </Styled.AttachmentItem>
        ))}
      </Styled.AttachmentsList>

      <FileModal
        isOpen={isOpenModal}
        onRequestClose={() => setIsOpenModal(false)}
        files={attachments}
        initialSlide={initialSlide}
        setInitialSlide={setInitialSlide}
      />
    </>
  );
};

export default Attachments;
