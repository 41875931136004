import React, { useState } from 'react';
import { Text, TableView, FullScreen } from 'src/components/design-system';
import { useUser, useUserWorkspace } from 'src/store';

import * as Styled from './styles';

const MyTasks = () => {
  const workspaceId = useUserWorkspace();
  const [columns] = useState<string[]>([
    'drag-handle',
    'count',
    'selection',
    'title',
    'storyPoints',
    'dueDate',
    'statusId',
    'assignee',
    'createdAt',
    'updatedAt',
    'completedAt',
  ]);
  const user = useUser();

  const query = {
    filter: {
      workspaceId,
      userIds: [user._id],
    },
  };

  return (
    <Styled.Container>
      <FullScreen
        customHeader={
          <Text as="h3" my={0}>
            My tasks
          </Text>
        }
      >
        <TableView query={query} columns={columns} />
      </FullScreen>
    </Styled.Container>
  );
};

export default MyTasks;
