import React from 'react';
import { Flex, Text, Switch } from 'src/components/design-system';

const CheckboxFilter = props => {
  const { label, value, onChange } = props;
  return (
    <Flex $gap={10} $alignItems="center">
      <Switch id="hideCompleted" onChange={onChange} isChecked={value} />
      <Text weight="bold">{label}</Text>
    </Flex>
  );
};

export default CheckboxFilter;
