import { useParams } from 'react-router-dom';
import { useQueryParams } from 'src/hooks/useQueryParams';
import { TASK_FRAGMENT } from 'src/graphql/actions';
import { useApolloClient } from '@apollo/client';
import { Action } from 'src/generated';

export const useProjectId = () => {
  const client = useApolloClient();
  const projectIdFromUrl = useParams()?.id;
  const query = useQueryParams();

  if (projectIdFromUrl) return projectIdFromUrl;

  const taskId = query.get('taskId') as string;

  const task = client.readFragment({
    id: `Action:${taskId}`,
    fragment: TASK_FRAGMENT,
  }) as Action;

  const projectId = projectIdFromUrl || task?.projectId?._id;

  // if (!projectId) {
  //   throw new Error('Project ID is missing');
  // }

  return projectId as string;
};
