import React, { ButtonHTMLAttributes, forwardRef } from 'react';

import { Container, Loading, Icon } from './styles';

export interface ButtonProps extends ButtonHTMLAttributes<HTMLButtonElement> {
  $active?: boolean;
  $danger?: boolean;
  disabled?: boolean;
  children?: React.ReactNode;
  onClick?: (e: any) => void;
  loading?: boolean;
  icon?: any;
  size?: 'little' | 'small' | 'medium' | 'large';
  $htmlType?: 'link' | 'submit' | 'primary' | 'text' | 'hover';
  $fullWidth?: boolean;
  $circle?: boolean;
  underline?: boolean;
  ref?: any;
  $padding?: string;
}

const Button = forwardRef(
  ({ children, loading, icon, disabled, ...props }: ButtonProps, ref) => {
    return (
      <Container {...props} disabled={loading || disabled} ref={ref}>
        {loading && (
          <Loading>
            <svg
              xmlns="http://www.w3.org/2000/svg"
              width="100%"
              height="30px"
              viewBox="0 0 100 100"
            >
              <rect y="25" width="10" height="50" rx="4" ry="4" fill="#fff">
                <animate
                  attributeName="x"
                  values="10;100"
                  dur="1.2s"
                  repeatCount="indefinite"
                />
                <animateTransform
                  attributeName="transform"
                  type="rotate"
                  from="0 10 70"
                  to="-60 100 70"
                  dur="1.2s"
                  repeatCount="indefinite"
                />
                <animate
                  attributeName="opacity"
                  values="0;1;0"
                  dur="1.2s"
                  repeatCount="indefinite"
                />
              </rect>
              <rect y="25" width="10" height="50" rx="4" ry="4" fill="#fff">
                <animate
                  attributeName="x"
                  values="10;100"
                  dur="1.2s"
                  begin="0.4s"
                  repeatCount="indefinite"
                />
                <animateTransform
                  attributeName="transform"
                  type="rotate"
                  from="0 10 70"
                  to="-60 100 70"
                  dur="1.2s"
                  begin="0.4s"
                  repeatCount="indefinite"
                />
                <animate
                  attributeName="opacity"
                  values="0;1;0"
                  dur="1.2s"
                  begin="0.4s"
                  repeatCount="indefinite"
                />
              </rect>
              <rect y="25" width="10" height="50" rx="4" ry="4" fill="#fff">
                <animate
                  attributeName="x"
                  values="10;100"
                  dur="1.2s"
                  begin="0.8s"
                  repeatCount="indefinite"
                />
                <animateTransform
                  attributeName="transform"
                  type="rotate"
                  from="0 10 70"
                  to="-60 100 70"
                  dur="1.2s"
                  begin="0.8s"
                  repeatCount="indefinite"
                />
                <animate
                  attributeName="opacity"
                  values="0;1;0"
                  dur="1.2s"
                  begin="0.8s"
                  repeatCount="indefinite"
                />
              </rect>
            </svg>
          </Loading>
        )}
        {icon && !loading && <Icon $mr={!!children}>{icon}</Icon>}
        {children && <span>{children}</span>}
      </Container>
    );
  },
);

Button.displayName = 'Button';

export default Button;
