import React from 'react';
import { Text, Select } from 'src/components/design-system';

const SelectFilter = props => {
  const { label, options, value, onChange } = props;
  return (
    <div>
      <Text weight="bold">{label}</Text>
      <Select
        isMulti
        closeMenuOnSelect={false}
        isSearchable={false}
        options={options}
        value={value}
        onChange={columns => {
          const ids = columns.map(({ value }) => value);
          onChange(ids);
        }}
      />
    </div>
  );
};

export default SelectFilter;
