import { css } from 'styled-components';

export const ReactModal = css`
  .ReactModal__Body--open {
    overflow: hidden;
  }

  .modal-overlay-blur {
    backdrop-filter: blur(1.5px);
    background-color: rgba(0, 0, 0, 0.8) !important;
    .modal-body-transparent {
      background: transparent !important;
    }
  }
  // Modal animation
  .ReactModal__Overlay:not(.no-animation) {
    opacity: 0;
    transition: opacity 300ms ease-in-out;
  }

  .ReactModal__Overlay--after-open:not(.no-animation) {
    opacity: 1;
  }

  .ReactModal__Overlay--before-close:not(.no-animation) {
    opacity: 0;
  }

  .ReactModal__Content:not(.no-animation) {
    opacity: 0;
    transform: scale(0.9);
    transition: transform 300ms ease-in-out;
  }

  .ReactModal__Content--after-open:not(.no-animation) {
    opacity: 1;
    transform: scale(1);
  }

  .ReactModal__Content--before-close:not(.no-animation) {
    opacity: 0;
    transform: scale(0.9);
  }
`;
