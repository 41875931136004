import React from 'react';

import { Flex, Skeleton } from 'src/components/design-system';

import SubTasks from './SubTasks';
import { useUserWorkspace } from 'src/store';

import { useTasksQuery } from 'src/generated';

const SubTasksContainer = ({ action }) => {
  const { _id } = action;
  const workspaceId = useUserWorkspace();

  const { data, loading } = useTasksQuery({
    variables: {
      filter: {
        workspaceId,
        parentId: _id,
      },
    },
  });

  if (loading) {
    return (
      <Flex $gap={10} direction="column">
        <Skeleton width="100%" height={30} />
        <Skeleton width="100%" height={30} />
        <Skeleton width="100%" height={30} />
      </Flex>
    );
  }

  return <SubTasks action={action} data={data?.tasks.items} />;
};

export default SubTasksContainer;
