import React, { useEffect, useMemo, useState } from 'react';
import ReactDatePicker from 'react-datepicker';
import 'react-datepicker/dist/react-datepicker.css';
import { useTranslation } from 'react-i18next';

import { Button, Flex, Icon } from 'src/components/design-system';

import { useTask } from 'src/hooks/useTask';

import { DateHelpers, DateFormats } from 'src/common/helpers';

import DaysRange from './DaysRange';
import isValid from 'date-fns/isValid';
import isWithinInterval from 'date-fns/isWithinInterval';
import Dropdown from 'rc-dropdown';
import { Action } from 'src/generated';

import { Container, Body, SelectDate, Divider, DaysHeaderDay } from './styles';

type DueDateProps = {
  task: Action;
  onChange?: ({ startDate, dueDate }: any) => void;
};

const DueDate = ({ task, onChange }: DueDateProps) => {
  const { t } = useTranslation();
  const { _id, dueDate, startDate: serverStartDate } = task;
  const { onUpdateTask } = useTask();
  const [isDateRange, setIsDateRange] = useState(!!serverStartDate);
  const [isDatePickerOpen, setIsDatePickerOpen] = useState(false);
  const [startDate, setStartDate] = useState<Date | null>(null);
  const [endDate, setEndDate] = useState<Date | null>(null);

  useEffect(() => {
    serverStartDate && setStartDate(new Date(serverStartDate));
    dueDate && setEndDate(new Date(dueDate));
  }, [dueDate, serverStartDate]);
  const handleAddStartDate = () => {
    setIsDateRange(true);
  };
  const setRangeDates = dates => {
    const [start, end] = dates;
    setStartDate(start);
    setEndDate(end);

    if (start && end) {
      onUpdateTask({
        taskId: _id,
        startDate: DateHelpers.toUTCISOString(start),
        dueDate: DateHelpers.toUTCISOString(end),
      });
      onChange?.({ startDate: start, dueDate: end });
      setIsDatePickerOpen(false);
    }
  };

  const setDueDate = (date: Date) => {
    onUpdateTask({
      taskId: _id,
      dueDate: DateHelpers.toUTCISOString(date),
    });
    onChange?.({ dueDate: date });
    setIsDatePickerOpen(false);
  };

  const handleClearDate = () => {
    onUpdateTask({
      taskId: _id,
      dueDate: null,
      startDate: null,
    });
    setIsDateRange(false);
    setIsDatePickerOpen(false);
    setStartDate(null);
    setEndDate(null);
  };

  const isTodayBetweenDays = useMemo(() => {
    if (startDate && endDate && isValid(startDate) && isValid(endDate)) {
      return isWithinInterval(new Date(), {
        start: new Date(startDate),
        end: new Date(endDate),
      });
    }

    return false;
  }, [endDate, startDate]);

  return (
    <Dropdown
      visible={isDatePickerOpen}
      onVisibleChange={visible => setIsDatePickerOpen(visible)}
      trigger={['click']}
      overlay={() => (
        <Container>
          <Flex $justifyContent="center">
            {startDate && (
              <div>
                {t('task.dueDate.fromDate')}:
                <DaysHeaderDay>
                  {DateHelpers.formatDate(
                    startDate,
                    DateFormats.DayLongMonthYear,
                  )}
                </DaysHeaderDay>
                <Divider>-</Divider>
              </div>
            )}
            {endDate && (
              <div>
                {t('task.dueDate.toDate')}:
                <DaysHeaderDay>
                  {DateHelpers.formatDate(
                    endDate,
                    DateFormats.DayLongMonthYear,
                  )}
                </DaysHeaderDay>
              </div>
            )}
          </Flex>

          <Body>
            {isDateRange ? (
              <ReactDatePicker
                open
                selected={startDate}
                showMonthDropdown
                showYearDropdown
                selectsRange
                onChange={setRangeDates}
                startDate={startDate || endDate}
                endDate={endDate}
                locale="en-gb"
              />
            ) : (
              <ReactDatePicker
                open
                selected={endDate}
                showMonthDropdown
                showYearDropdown
                locale="en-gb"
                onChange={setDueDate}
              />
            )}
          </Body>
          <Flex $padding={[30, 15, 15, 15]} $gap={10} direction="column">
            {!isDateRange && (
              <Button onClick={handleAddStartDate}>
                {t('task.dueDate.addStartDate')}
              </Button>
            )}
            <Button onClick={handleClearDate}>
              {t('task.dueDate.clearDate')}
            </Button>
          </Flex>
        </Container>
      )}
    >
      <div>
        <SelectDate onClick={() => setIsDatePickerOpen(true)}>
          <Icon name="calendar" size={14} />
          {!startDate && !endDate ? (
            <span>{t('task.dueDate.addDates')}</span>
          ) : (
            <>
              {startDate && (
                <span>
                  {DateHelpers.formatDate(startDate)}

                  <Divider>-</Divider>
                </span>
              )}
              <span>{DateHelpers.formatDate(endDate)}</span>
            </>
          )}
        </SelectDate>

        {isTodayBetweenDays && endDate && (
          <DaysRange startDate={startDate} endDate={endDate} />
        )}
      </div>
    </Dropdown>
  );
};

export default DueDate;
