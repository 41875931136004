import { Row } from '@tanstack/react-table';
import React, { memo } from 'react';
import { Flex, Icon } from 'src/components/design-system';
import { useSortable } from '@dnd-kit/sortable';
import { TableTask } from 'src/components/design-system/TableView/columns';

type RowDragHandleCellProps = {
  row: Row<TableTask>;
};

const RowDragHandleCell = ({ row }: RowDragHandleCellProps) => {
  const rowId = row.id;
  const { attributes, listeners } = useSortable({
    id: rowId,
  });

  return (
    <Flex $alignItems="center" $justifyContent="center" $height="100%">
      <div {...attributes} {...listeners} style={{ cursor: 'pointer' }}>
        <Icon name="drag-vertical" />
      </div>
    </Flex>
  );
};

export default memo(RowDragHandleCell);
