import React from 'react';
import { Flex, Button } from 'src/components/design-system';
import { useTask } from 'src/hooks/useTask';

import * as Styled from './styles';

const SelectedRowsActions = ({ selectedRows, table }) => {
  const { onDeleteTasks } = useTask();

  return (
    <Styled.Container
      $gap={10}
      $padding={[10, 20]}
      $alignItems="center"
      $justifyContent="space-between"
    >
      <div>
        {`${selectedRows.length} ${selectedRows.length > 1 ? 'Tasks selected' : 'Task selected'}`}
      </div>

      <Flex $gap={10}>
        <Button $htmlType="hover" size="medium" disabled>
          Status
        </Button>

        <Button
          $htmlType="hover"
          size="medium"
          disabled
          onClick={() => {
            onDeleteTasks({ taskIds: selectedRows });
            table.toggleAllRowsSelected(false);
          }}
        >
          Assignee
        </Button>

        <Button
          $htmlType="hover"
          size="medium"
          disabled
          onClick={() => {
            onDeleteTasks({ taskIds: selectedRows });
            table.toggleAllRowsSelected(false);
          }}
        >
          Labels
        </Button>

        <Button
          $htmlType="hover"
          size="medium"
          onClick={() => {
            onDeleteTasks({ taskIds: selectedRows });
            table.toggleAllRowsSelected(false);
          }}
        >
          Delete
        </Button>
      </Flex>
    </Styled.Container>
  );
};

export default SelectedRowsActions;
