import React, {
  forwardRef,
  InputHTMLAttributes,
  ReactNode,
  useState,
  useCallback,
  CSSProperties,
  useEffect,
  useRef,
} from 'react';

import { Icon, Button } from 'src/components/design-system';

import * as Styled from './styles';

interface InputProps
  extends Omit<InputHTMLAttributes<HTMLInputElement>, 'prefix'> {
  prefix?: ReactNode;
  suffix?: ReactNode;
  clearable?: boolean;
  style?: CSSProperties;
  autoFocus?: boolean;
}

const Input = forwardRef<HTMLInputElement, InputProps>(
  (
    { prefix, suffix, onChange, clearable = true, autoFocus, style, ...props },
    ref,
  ) => {
    const [value, setValue] = useState('');
    const inputRef = useRef<HTMLInputElement>(null);

    // Use effect to handle autoFocus
    useEffect(() => {
      if (autoFocus) {
        const targetRef =
          (ref as React.RefObject<HTMLInputElement>)?.current ||
          inputRef.current;
        targetRef?.focus();
      }
    }, [autoFocus, ref]);

    // Handle input value change
    const handleChange = useCallback(
      (e: React.ChangeEvent<HTMLInputElement>) => {
        setValue(e.target.value); // Update internal value
        if (onChange) {
          onChange(e); // Notify the parent of the change
        }
      },
      [onChange],
    );

    // Handle clear action
    const handleClear = useCallback(() => {
      setValue(''); // Clear the internal state
      const targetRef =
        (ref as React.RefObject<HTMLInputElement>)?.current || inputRef.current;
      if (targetRef) {
        targetRef.value = ''; // Clear the actual input field
      }
      // Trigger the parent's onChange with an empty event, simulating the clear action
      if (onChange) {
        const event = new Event('input', {
          bubbles: true,
        }) as unknown as React.ChangeEvent<HTMLInputElement>;
        Object.defineProperty(event, 'target', {
          value: { value: '' },
          writable: false,
        });
        onChange(event); // Notify parent of the clear action
      }
    }, [onChange, ref]);

    return (
      <Styled.Container $gap={4} $alignItems="center" style={style}>
        {prefix && <span>{prefix}</span>}
        <input
          ref={ref || inputRef}
          value={value}
          onChange={handleChange}
          {...props}
        />
        {suffix && suffix}
        {clearable && value && (
          <Button $htmlType="text" size="little" onClick={handleClear}>
            <Icon name="close" />
          </Button>
        )}
      </Styled.Container>
    );
  },
);

Input.displayName = 'Input';

export default Input;
